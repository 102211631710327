import React, { type FC } from 'react';
import { ProfileEditPageFormTextarea } from '../ProfileEditPageFormTextarea/ProfileEditPageFormTextarea';
import type { ProfileInfo } from '../../ProfileEditPage';
import { ProfileEditPageFormInput } from '../ProfileEditPageFormInput/ProfileEditPageFormInput';
import { useLanguageContext } from '../../../../../../../components/LanguageContext/LanguageContext';

interface IProps {
  profileInfo: ProfileInfo;
  setProfileInfo: (info: ProfileInfo) => void;
}

export const ProfileEditPageFormAbout: FC<IProps> = ({ profileInfo, setProfileInfo }) => {
  // hooks
  const { t } = useLanguageContext();

  return (
    <div className="user-profile__edit-page__form">
      <div className="user-profile__edit-page__form__header">
        <h2>{t('About')}</h2>
        <p>{t('Describe your competencies and experience')}</p>
      </div>
      <div className="user-profile__edit-page__form__content">
        <ProfileEditPageFormTextarea
          label={t('Profession')}
          value={profileInfo.profession}
          onChange={(e) => {
            setProfileInfo({
              ...profileInfo,
              profession: e.target.value,
            });
          }}
          maxCharacters={80}
        />

        <ProfileEditPageFormTextarea
          label={t('About')}
          value={profileInfo.about}
          onChange={(e) => {
            setProfileInfo({
              ...profileInfo,
              about: e.target.value,
            });
          }}
          maxCharacters={500}
        />

        <ProfileEditPageFormInput
          label={t('Languages')}
          value={profileInfo.languages?.join(', ')}
          onChange={(e) => {
            setProfileInfo({
              ...profileInfo,
              languages: e.target.value.split(','),
            });
          }}
        />

        <ProfileEditPageFormInput
          label={t('Location')}
          value={profileInfo.location}
          onChange={(e) => {
            setProfileInfo({
              ...profileInfo,
              location: e.target.value,
            });
          }}
        />

        <ProfileEditPageFormInput
          label={t('Skills')}
          value={profileInfo.skills?.join(', ')}
          onChange={(e) => {
            setProfileInfo({
              ...profileInfo,
              skills: e.target.value.split(','),
            });
          }}
        />
      </div>
    </div>
  );
};
