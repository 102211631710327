import React, { type FC, type ReactElement } from 'react';
import { useLanguageContext } from '../LanguageContext/LanguageContext';
import './PageInfoHeader.scss';
import GoBackIcon from '../../assets/icons/go-back-icon.png';
import { useNavigate } from 'react-router-dom';

interface IProps {
  page: string;
  additionalText: string[];
  additionalButtons?: ReactElement;
}

export const PageInfoHeader: FC<IProps> = ({ page, additionalText, additionalButtons }) => {
  // hooks
  const { t } = useLanguageContext();
  const navigate = useNavigate();

  return (
    <nav className="pageInfo__header">
      <div className="pageInfo__header__navigationWrapper">
        <div className="pageInfo__header__gobackWrapper">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={GoBackIcon} alt="" />
          </button>
        </div>
        <span className="pageInfo__header__navigationWrapper__item">{t(page)}</span>
        {additionalText?.map((text, index) => (
          <>
            <span className="pageInfo__header__navigationWrapper__separator" key={index}>
              {t(text) && '/'}
            </span>
            <span className="pageInfo__header__navigationWrapper__item" key={index}>
              {t(text)}
            </span>
          </>
        ))}
      </div>
      <div
        className="pageInfo__header__buttonsWrapper"
        style={{
          display: !additionalButtons && 'none',
        }}
      >
        {additionalButtons}
      </div>
    </nav>
  );
};
