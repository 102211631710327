import React, { type FC } from 'react';

interface IProps {
  amount: number;
}

export const ProfileServicesPageHeader: FC<IProps> = ({ amount }) => {
  return (
    <div className="user-profile__services__header">
      <div className="user-profile__services__header__title">
        <span>Services</span>
        <div></div>
        <h3>{amount}</h3>
      </div>
    </div>
  );
};
