import React, { type FC } from 'react';
import Popup from '../../../../../../../components/Popup/Popup';
import { useDispatch } from 'react-redux';
import {
  setCurrentActivityStep,
  setCurrentOpenedChatPopup,
} from '../../../../../../../redux/messenger/reducer';
import { useSelector } from '../../../../../../../redux/rootReducer';

const MessengerOrderInfoConfirmPopup: FC = () => {
  const dispatch = useDispatch();
  const currentOpenedChatPopup = useSelector((state) => state.messenger.currentOpenedChatPopup);

  const onClose = (): void => {
    dispatch(setCurrentOpenedChatPopup(null));
  };

  const onSubmit = (): void => {
    onClose();
    dispatch(setCurrentActivityStep(2));
    // TODO: прописать логику согласия с terms
  };

  const onCancel = (): void => {
    onClose();
    // TODO: прописать логику отказа от terms
  };

  return (
    <Popup
      isOpen={currentOpenedChatPopup === 'Confirm terms'}
      onClose={onClose}
      btns={{
        right: { onClick: onSubmit, label: 'Confirm' },
        left: { onClick: onCancel, label: 'Cancel' },
      }}
      centered
      title={{ label: 'Confirm terms' }}
      description={{ label: 'Are you sure you wanna confirm terms?' }}
    />
  );
};

export default MessengerOrderInfoConfirmPopup;
