import React from 'react';
import { BrowseHeaderTab } from './BrowseHeaderTab/BrowseHeaderTab';
import ServicesIcon from '../../../../../assets/icons/services-group-icon.png';
import PeopleIcon from '../../../../../assets/icons/people-group-icon.png';
import ProjectsIcon from '../../../../../assets/icons/projects-group-icon.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';

interface TabData {
  icon: string;
  text: string;
}

export const BrowseHeader: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { category } = useParams();
  const { t } = useLanguageContext();

  const tabs: TabData[] = [
    {
      icon: ServicesIcon as string,
      text: 'Services',
    },
    {
      icon: PeopleIcon as string,
      text: 'People',
    },
    {
      icon: ProjectsIcon as string,
      text: 'Projects',
    },
  ];

  return (
    <>
      <h1 className="browse__header">{t('Browse')}</h1>
      <nav className="browse__tab-container">
        {tabs.map((tab, index) => (
          <BrowseHeaderTab
            key={index}
            icon={tab.icon}
            text={tab.text}
            isActive={category === tab.text.toLowerCase()}
            onClick={() => {
              navigate(`/browse/${tab.text.toLowerCase()}`);
            }}
          />
        ))}
      </nav>
    </>
  );
};
