import React, { type FC } from 'react';
import ArrowRightIcon from '../../../../../assets/icons/arrow-right-icon.png';
import StarIcon from '../../../../../assets/icons/profile-reviews-star-icon.png';
import { ProfileReviewsInfoCard } from './ProfileReviewsInfoCard/ProfileReviewsInfoCard';
import { useNavigate } from 'react-router-dom';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';

interface IProps {
  reviews: any[];
  rating: number;
}

export const ProfileReviewsInfo: FC<IProps> = ({ reviews, rating }) => {
  // hooks
  const navigate = useNavigate();
  const { t } = useLanguageContext();

  return (
    <section className="user-profile__content-section__reviews">
      <div className="user-profile__content-section__reviews__heading">
        <div className="user-profile__content-section__reviews__heading__title-wrapper">
          <img src={StarIcon} alt="" />
          <span>{rating}</span>
          <div></div>
          <h3>{reviews.length}</h3>
        </div>
        <button
          onClick={() => {
            const currentPath = window.location.pathname;
            navigate(`${currentPath}/reviews`);
          }}
        >
          {t('See all')}
          <img src={ArrowRightIcon as string} alt="" />
        </button>
      </div>
      <div className="user-profile__content-section__reviews__card__container">
        {reviews.slice(0, 3).map((review) => (
          <ProfileReviewsInfoCard
            key={review.id}
            logoSrc={review.stars}
            reviewerName={review.name}
            reviewDate={review.date}
            reviewContent={review.content}
          />
        ))}
      </div>
    </section>
  );
};
