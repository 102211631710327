import React, { type FC, useEffect, useState } from 'react';
import './Browse.scss';
import { useSelector } from '../../../../redux/rootReducer';
import { useDispatch } from 'react-redux';
import { BrowseSearchCard } from './BrowseSearchCard/BrowseSearchCard';
import { apiProfile } from '../../../../services/api/legacy/profile';
import { setProfile } from '../../../../redux/profile/reducer';
import { type IProfile } from '../../../../interfaces/api/profile';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import GButton, { ButtonColors, ButtonTypes } from '../../../../components/GButton/GButton';
import { apiHashTag } from '../../../../services/api/legacy/hashtag';
import type { IHashTag } from '../../../../interfaces/api/hashtag';
import { useLanguageContext } from '../../../../components/LanguageContext/LanguageContext';
import { LoadingScreen } from '../../../../components/LoadingScreen/LoadingScreen';
import { apiAuth } from '../../../../services/api/auth';
import { BrowseSearch } from './BrowseSearch/BrowseSearch';
import { BrowseProfileSearchCard } from './BrowseProfileSearchCard/BrowseProfileSearchCard';
import { Profile } from '../Profile/Profile';
import { BrowseHeader } from './BrowseHeader/BrowseHeader';
import BrowseIcon from '../../../../assets/icons/browse_icon.png';
import { BrowseServiceSearchCard } from './BrowseServiceSearchCard/BrowseServiceSearchCard';
import ServiceMockImage from '../../../../assets/icons/browse-mock-service-image.png';
import { avatarUrl } from '../Chats/Chats';
import { ModalFilter } from '../../../../components/Modals/ModalFilter/ModalFilter';

export interface IFilterOptions {
  rating: number;
  tags: string[];
  location: string;
  languages: string[];
}

export const Browse: FC = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useLanguageContext();

  // redux
  const profileState = useSelector((state) => state.profile);

  // const myId = authState.user.user_id;

  // states
  const [inputValue] = useState('');
  const [profiles, setProfiles] = useState([]);
  const [availableHashTags, setAvailableHashTags] = useState([]);
  const [chosenHashTags, setChosenHashTags] = useState([]);
  const [allowRender, setAllowRender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userProfiles, setUserProfiles] = useState(null);
  const [services] = useState([
    {
      id: '0',
      serviceDescription:
        'Short description text about person with areas of expertise and preferred work type',
      servicePrice: '120',
      serviceImage: ServiceMockImage as string,
      userName: 'John Brown',
      userId: '123',
      userRating: 4.75,
      userRatingCount: 28,
    },
    {
      id: '1',
      serviceDescription: 'I will draw custom, graphic automotive tshirt design',
      servicePrice: '190',
      serviceImage: ServiceMockImage as string,
      userName: 'Christopher Brown',
      userId: '123',
      userRating: 4.9,
      userRatingCount: 73,
    },
    {
      id: '2',
      serviceDescription:
        'Short description text about person with areas of expertise and preferred work type',
      servicePrice: '120',
      serviceImage: ServiceMockImage as string,
      userName: 'John Brown',
      userId: '123',
      userRating: 4.75,
      userRatingCount: 28,
    },
    {
      id: '3',
      serviceDescription: 'I will draw custom, graphic automotive tshirt design',
      servicePrice: '190',
      serviceImage: ServiceMockImage as string,
      userName: 'Christopher Brown',
      userId: '123',
      userRating: 4.9,
      userRatingCount: 73,
    },
  ]);
  const [modalFilterOpen, setModalFilterOpen] = useState(false);
  const [filterBy, setFilterBy] = useState<IFilterOptions>({
    rating: 0,
    tags: [],
    location: '',
    languages: [],
  });
  const [sortBy, setSortBy] = useState('recommended');

  // search category and user login from params
  const { category, login, page } = useParams();
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('search');

  // Search by name when input value changes
  useEffect(() => {
    if (category === 'People') {
      const controller = new AbortController();

      if (inputValue !== '') {
        setLoading(true);
        apiProfile.searchProfileByName(inputValue, controller.signal).then((profile) => {
          setProfiles(profile?.profiles ?? []);
          setLoading(false);
        });
      }

      inputValue === '' && setProfiles([]);

      return () => {
        controller.abort();
      };
    }
  }, [inputValue]);

  // Reset found profiles when category changes
  useEffect(() => {
    setUserProfiles([]);
  }, [category]);

  // Fetch available hashtags
  useEffect(() => {
    apiHashTag.getHashTags().then((r) => {
      r && setAvailableHashTags(r.hashtags);
      setAllowRender(true);
    });
  }, []);

  // Reset found profiles when no hashtags are chosen
  useEffect(() => {
    chosenHashTags.length === 0 && setProfiles([]);
  }, [chosenHashTags]);

  // Css variable for mobile version
  useEffect(() => {
    document.documentElement.style.setProperty('--dialog-opened', 'flex');
  }, []);

  if (!allowRender || !profileState.myProfile) {
    return (
      <>
        <LoadingScreen />
      </>
    );
  }

  if (category === 'people') {
    if (login) {
      return (
        <Profile
          isMyProfile={false}
          headerPath={[
            'People',
            `@${profileState.profile?.login}`,
            page && page[0].toUpperCase() + page.slice(1, page.length - 1),
          ]}
          headerPage={'Browse'}
          headerIcon={BrowseIcon as string}
        />
      );
    }
  }

  return (
    <div className="browse">
      <ModalFilter
        isOpen={modalFilterOpen}
        setIsOpen={(value) => {
          setModalFilterOpen(value);
        }}
        filterBy={filterBy}
        setFilterBy={(value) => {
          setFilterBy(value);
        }}
        onConfirm={() => {
          console.log(filterBy);
          setModalFilterOpen(false);
        }}
      />

      {/* Header */}
      <BrowseHeader />

      {/* Search with category selector */}
      <BrowseSearch
        setFilterIsOpen={() => {
          setModalFilterOpen(true);
        }}
        sortBy={sortBy}
        setSortBy={(value: string) => {
          setSortBy(value);
        }}
        searchValue={searchText || ''}
        setSearchValue={(value) => {
          value ? navigate(`/browse/${category}?search=${value}`) : navigate(`/browse/${category}`);
        }}
        setFoundProfiles={(profiles) => {
          setUserProfiles(profiles);
        }}
        setLoadingState={(state) => {
          setLoading(state);
        }}
      />

      {/* Found services */}
      <div
        style={{
          display: category === 'services' && searchText ? 'flex' : 'none',
        }}
        className="browse__service-search-card__container"
      >
        {services.map((service) => (
          <BrowseServiceSearchCard
            key={service.id}
            imageUrl={service.serviceImage}
            price={service.servicePrice}
            description={service.serviceDescription}
            userName={service.userName}
            avatarUrl={avatarUrl + service.userId}
            rating={service.userRating}
            ratingCount={service.userRatingCount}
          />
        ))}
      </div>

      {/* Found user profiles */}
      <div
        style={{
          display: category === 'people' && searchText ? 'flex' : 'none',
        }}
        className="browse__profile-search-card__container"
      >
        {loading ? (
          <LoadingScreen />
        ) : (
          userProfiles?.map((profile: IProfile) => (
            <BrowseProfileSearchCard
              userId={profile.user_id}
              key={profile.user_id}
              userName={profile.name}
              rating={profile.rank ? parseFloat(profile.rank.toFixed(2)) : null}
              reviewCount={profile.reviewsCount ? profile.reviewsCount : null}
              price={0}
              description={profile.description}
              skills={profile.hashtags ? profile.hashtags : []}
            />
          ))
        )}
      </div>

      <div
        className="browse__hashtags-search"
        style={{
          display: (inputValue !== '' || availableHashTags.length === 0) && 'none',
        }}
      >
        <h1>{t('Search by hashtags')}</h1>
        <div className="browse__hashtags-search__tags">
          {availableHashTags.map((tag: IHashTag, index) => (
            <div key={index}>
              <GButton
                type={ButtonTypes.BUTTON}
                title={tag.hashtag}
                color={
                  chosenHashTags.includes(tag.hashtag) ? ButtonColors.VIOLET : ButtonColors.GRAY
                }
                bordered={false}
                filled={true}
                onClick={() => {
                  if (chosenHashTags.includes(tag.hashtag)) {
                    setChosenHashTags(chosenHashTags.filter((el) => el !== tag.hashtag));
                  } else {
                    setChosenHashTags([...chosenHashTags, tag.hashtag]);
                  }
                }}
              />
            </div>
          ))}
        </div>
        <GButton
          type={ButtonTypes.BUTTON}
          title={t('Search')}
          color={ButtonColors.GREEN}
          bordered={false}
          filled={true}
          disabled={!chosenHashTags.length}
          onClick={() => {
            setProfiles([]);
            apiProfile.searchByHashTags(chosenHashTags).then((r) => {
              setProfiles(r.profiles);
            });
          }}
        />
      </div>
      <div className="browse__body">
        <div
          className="browse__body-title"
          style={{
            display: inputValue === '' && 'none',
          }}
        >
          {profiles.length > 0 ? t('Results for') : t('No results for')}
          <h3 style={{ marginLeft: '1%' }}>{inputValue}</h3>
        </div>
        <div className="browse__body__content">
          {loading ? (
            <LoadingScreen />
          ) : (
            profiles.map((prof: IProfile) => (
              <div className="browse__body__content-el" key={prof.user_id}>
                <BrowseSearchCard
                  user_id={prof.user_id}
                  name={prof.name}
                  login={prof.login ?? ''}
                  hashtags={prof.hashtags ?? []}
                  skills={prof.skills ?? ''}
                  chars={prof.characteristics ?? ''}
                  preferences={prof.preferences ?? ''}
                  onViewProfile={() => {
                    dispatch(setProfile(prof));
                    apiAuth.getUserLogin(prof.user_id).then((login: string) => {
                      navigate(`/profile/${login}`);
                    });
                  }}
                />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
