import React, { type FC, useRef, useState } from 'react';
import { AuthInput } from '../AuthInput/AuthInput';
import { AuthButton } from '../AuthButton/AuthButton';
import { AuthDivider } from '../AuthDivider/AuthDivider';
import type { ILoginRequest } from '../../../../interfaces/api/auth';
import { apiAuth } from '../../../../services/api/auth';
import { setUser } from '../../../../redux/auth/reducer';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import EyeClosedIcon from '../../../../assets/icons/login-input-eye-closed-icon.png';
import EyeOpenedIcon from '../../../../assets/icons/login-input-eye-opened-icon.png';
import { Components, type IAuthFormProps } from '../../Auth';
import WebsiteLogo from '../../../../assets/icons/00x-logo.png';
import ReCAPTCHA from 'react-google-recaptcha';

const recaptchaKey = '6LeffiwqAAAAAFZNmZss05iud1BAvGNDJbc58YTf';

export const AuthLogin: FC<IAuthFormProps> = ({ setComponent }) => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // states
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showInputError, setShowInputError] = useState(false);

  const recaptchaToken = useRef(null);

  // Submit function
  const handleSubmit = async (): Promise<void> => {
    try {
      if (!recaptchaToken.current) {
        // throw new Error('no token provided');
      }

      const data: ILoginRequest = {
        login,
        password,
        // recaptcha_token: recaptchaToken.current,
      };

      const user = await apiAuth.login(data);

      if (user) {
        dispatch(setUser(user));
        navigate('/browse/people');
      } else {
        setShowInputError(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <main className="auth-login">
      <div className="auth-login__logo">
        <img src={WebsiteLogo} alt="" />
        Club
      </div>
      <h1 className="auth-login__title">Log in</h1>
      <form
        action="#"
        method="POST"
        className="auth-login__form"
        onSubmit={(event) => {
          event.preventDefault();

          void (async () => {
            await handleSubmit();
          })();
        }}
      >
        <AuthInput
          type="text"
          placeholder="Enter email or username"
          aria-label="login"
          value={login}
          onChange={(e) => {
            showInputError && setShowInputError(false);
            setLogin(e.target.value);
          }}
          className="auth-login__input"
        />
        <span
          className="auth-login__input__error-msg"
          style={{
            display: !showInputError && 'none',
          }}
        >
          Wrong username or password
        </span>
        <div className="auth-login__password-container">
          <AuthInput
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            aria-label="Password"
            value={password}
            onChange={(e) => {
              showInputError && setShowInputError(false);
              setPassword(e.target.value);
            }}
            className="auth-login__input auth-login__input--password"
          />
          <button
            type="button"
            className="auth-login__visibility-toggle"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            aria-label={showPassword ? 'Hide password' : 'Show password'}
          >
            <img
              src={showPassword ? (EyeOpenedIcon as string) : (EyeClosedIcon as string)}
              alt=""
              className="auth-login__visibility-icon"
            />
          </button>
        </div>
        <div className="auth-login__captcha-container">
          <ReCAPTCHA
            sitekey={recaptchaKey}
            ref={recaptchaToken}
            onChange={(token: string) => {
              recaptchaToken.current = token;
            }}
            onExpire={() => {
              recaptchaToken.current = undefined;
            }}
          />
        </div>
        <a
          href="javascript:void(0)"
          className="auth-login__forgot-password"
          onClick={() => {
            setComponent(Components.RESET);
          }}
        >
          Forgot password?
        </a>
        <div className="auth-login__button-container">
          <AuthButton type="submit">Continue</AuthButton>
          <AuthDivider />
          <AuthButton
            variant="secondary"
            onClick={() => {
              setComponent(Components.REGISTRATION);
            }}
          >
            Sign up
          </AuthButton>
        </div>
      </form>
    </main>
  );
};
