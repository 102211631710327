import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { IChatState } from './types';
import type { IChatsList, IOpenedChat, IUser } from '../../interfaces/api/chats';
import type { IWebsocketNewChatMessage } from '../../interfaces/api/websocket';

const initialState: IChatState = {
  chats: {
    active_chat: [],
    general_chat: [],
    history_orders_chat: [],
  },
  users: [],
  openedChat: null,
  lastMessage: null,
};

const chatReducer = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    setChats: (state, action: PayloadAction<IChatsList>) => {
      state.chats = action.payload;
    },
    setUsers: (state, action: PayloadAction<IUser[]>) => {
      state.users = action.payload;
    },
    setDeleteChats: (state, action: PayloadAction<{ chatId: string }>) => {
      // state.chats = state.chats.filter((chat) => chat.chat_id !== action.payload.chatId);
    },
    setOpenedChat: (state, action: PayloadAction<IOpenedChat>) => {
      state.openedChat = action.payload;
    },
    setMessagesRead: (state, action: PayloadAction<{ chat_type: string; index: number }>) => {
      state.chats[action.payload.chat_type][action.payload.index].unread_count = 0;
    },
    setLastMessage: (state, action: PayloadAction<IWebsocketNewChatMessage>) => {
      state.lastMessage = action.payload;
    },
  },
});

export const {
  setChats,
  setUsers,
  setDeleteChats,
  setOpenedChat,
  setMessagesRead,
  setLastMessage,
} = chatReducer.actions;

export default chatReducer.reducer;
