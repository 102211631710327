import { ApiServiceBase } from '../../apiServiceBase';
import {
  type IEditProfileRequestData,
  type IProfile,
  type ISearchProfileResponse,
} from '../../../interfaces/api/profile';

class ApiProfile extends ApiServiceBase {
  protected baseUrl = 'v2/profile/';

  public getProfile = (login: string): Promise<IProfile | null> => {
    return this.get<IProfile | null>('get/' + login);
  };

  public editProfile = async (profile: IEditProfileRequestData): Promise<boolean> => {
    try {
      const response = await this.post('edit_profile', { ...profile });
      return response.status === 200;
    } catch (e) {
      e && console.log(e);
      return false;
    }
  };

  public updateOnline = (): Promise<boolean> => {
    return this.post('online') as unknown as Promise<boolean>;
  };

  public searchProfileByName = async (
    name: string,
    signal?: any,
  ): Promise<ISearchProfileResponse> => {
    try {
      const response = await this.get<ISearchProfileResponse>('search_profile', {
        query: {
          name,
        },
        signal,
      });
      return response ?? { profiles: [] };
    } catch (err) {
      return { profiles: [] };
    }
  };

  public searchByHashTags = async (tags: string[]): Promise<ISearchProfileResponse> => {
    try {
      const response = await this.post<{ hashtags: string[] }, ISearchProfileResponse>(
        'search_with_hashtags',
        {
          hashtags: tags,
        },
      );

      return response ?? { profiles: [] };
    } catch (e) {
      e && console.log(e);
      return { profiles: [] };
    }
  };

  public addAvatar = async (file: File): Promise<boolean> => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await this.rawPost('v2/profile/upload_avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.status === 201;
    } catch (e) {
      e && console.log(e);
      return false;
    }
  };

  public deleteAvatar = async (): Promise<boolean> => {
    try {
      const response = await this.rawPost('v2/profile/delete_avatar');
      return response.status === 200;
    } catch (e) {
      e && console.log(e);
      return false;
    }
  };

  public addHashTags = async (tags: string[]): Promise<boolean> => {
    try {
      const response = await this.post('add_hashtags', {
        hashtags: tags,
      });
      return response.status === 200;
    } catch (e) {
      e && console.log(e);
      return false;
    }
  };

  public deleteHashTags = async (tags: string[]): Promise<boolean> => {
    try {
      const response = await this.post('del_hashtags', {
        hashtags: tags,
      });
      return response.status === 200;
    } catch (e) {
      e && console.error(e);
      return false;
    }
  };
}

export const apiProfile = new ApiProfile();
