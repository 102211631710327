import { type FC } from 'react';
import AddServiceIcon from '../../../../../assets/icons/orders-add-service-icon.png';
import { OrdersHeaderTab } from './OrdersHeaderTab/OrdersHeaderTab';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';

interface IProps {
  chosenRole: string;
  setChosenRole: (role: string) => void;
}

// navigation tabs
const tabs = ['Freelancer', 'Customer'];

export const OrdersHeader: FC<IProps> = ({ chosenRole, setChosenRole }) => {
  const { t } = useLanguageContext();
  return (
    <div className="orders__header">
      <div className="orders__header__title">
        <h1>{t('Orders')}</h1>
        <button>
          <span>{t('Create service')}</span>
          <img src={AddServiceIcon} alt="" />
        </button>
      </div>
      <div className="orders__header__buttons">
        {tabs.map((text, index) => (
          <OrdersHeaderTab
            key={index}
            text={text}
            isActive={chosenRole === text}
            onClick={() => {
              setChosenRole(text);
            }}
          />
        ))}
      </div>
    </div>
  );
};
