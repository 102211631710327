import React, { type FC } from 'react';
// @ts-expect-error
import s from './NewMessageToast.module.scss';

interface INewMessageToast {
  chatId: string;
  chatName: string;
  img: string;
  message: string;
}

const NewMessageToast: FC<INewMessageToast> = ({ chatId, chatName, img, message }) => {
  return (
    <div className={s.newMessageToast}>
      <img src={img} alt="" />
      <div className={s.content}>
        <div className={s.chatName}>{chatName}</div>
        <div className={s.message}>{message}</div>
      </div>
    </div>
  );
};

export default NewMessageToast;
