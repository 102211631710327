import { type FC, useState } from 'react';
import { PageInfoHeader } from '../../../../../components/PageInfoHeader/PageInfoHeader';
import { useSelector } from '../../../../../redux/rootReducer';
import { ProfileEditPageHeaderButtons } from './ProfileEditPageHeaderButtons/ProfileEditPageHeaderButtons';
import { ProfileEditPageForm } from './ProfileEditPageForm/ProfileEditPageForm';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { apiProfile } from '../../../../../services/api/legacy/profile';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';

export interface ProfileInfo {
  firstName: string;
  lastName: string;
  userName: string;
  profession?: string;
  about?: string;
  languages?: string[];
  location?: string;
  skills?: string[];
}

export const ProfileEditPage: FC = () => {
  // hooks
  const navigate = useNavigate();
  const { t } = useLanguageContext();

  // redux
  const profileState = useSelector((state) => state.profile);

  // states
  const [profileInfo, setProfileInfo] = useState<ProfileInfo>({
    firstName: profileState.myProfile.name.split(' ')[0],
    lastName: profileState.myProfile.name.split(' ')[1] ?? '',
    userName: profileState.myProfile.login,
  });

  // Discard and Save buttons for the page header
  const headerButtons = (
    <ProfileEditPageHeaderButtons
      onDiscard={() => {
        navigate(-1);
      }}
      onSave={() => {
        // If any changes were made, try to update the profile
        if (
          !(
            profileInfo.firstName === profileState.myProfile.name.split(' ')[0] &&
            profileInfo.lastName === profileState.myProfile.name.split(' ')[1] &&
            profileInfo.userName === profileState.myProfile.login
          )
        ) {
          apiProfile
            .editProfile({
              name: profileInfo.firstName + ' ' + profileInfo.lastName,
              username: profileInfo.userName,
            })
            .then((r) => {
              if (r) {
                message.success(t('Success'));
                navigate(`/profile`);
              } else {
                message.error(t('Something went wrong. Please try again'));
              }
            });
        }
      }}
    />
  );

  return (
    <>
      <PageInfoHeader
        page={'Profile'}
        additionalText={['Edit profile']}
        additionalButtons={headerButtons}
      />

      <div className="user-profile__edit-page">
        <ProfileEditPageForm
          profileInfo={profileInfo}
          setProfileInfo={(info) => {
            setProfileInfo(info);
          }}
        />
      </div>
    </>
  );
};
