import { t } from 'i18next';
import type { IDateDesiredFormat } from '../../types/services/parseDate';

class ParseDate {
  public toDesiredDate(date: Date, format: IDateDesiredFormat): string {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const day = date.getDate().toString().padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    const formatMap: Record<string, string> = {
      MM: t(month),
      MMShort: t(month).slice(0, 3),
      DD: day,
      YYYY: year.toString(),
      HH: hours,
      mm: minutes,
    };

    // Разбиваем формат на составляющие и подставляем соответствующие значения
    const formattedDate = format.replace(
      /(MMShort|MM|DD|YYYY|HH|mm)/g,
      (match) => formatMap[match] || match,
    );

    return formattedDate;
  }

  public toTimeAgo(date: Date): string {
    const now = new Date();
    const differenceInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    const units: Record<string, number> = {
      year: 60 * 60 * 24 * 365,
      month: 60 * 60 * 24 * 30,
      week: 60 * 60 * 24 * 7,
      day: 60 * 60 * 24,
      hour: 60 * 60,
      minute: 60,
      second: 1,
    };

    for (const [unit, value] of Object.entries(units)) {
      const elapsed = Math.floor(differenceInSeconds / value);
      if (elapsed >= 1) {
        return `${t(unit, { count: elapsed })} ${t('ago')}`;
      }
    }

    return t('just now');
  }

  public timeForChat(date: Date): string {
    const now = new Date();
    const diffMs = now.getTime() - date.getTime();
    const diffHours = diffMs / (1000 * 60 * 60);

    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    // Если разница меньше 24 часов
    if (diffHours < 24) {
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    }

    // Если дата в пределах последних 5 дней
    const diffDays = diffHours / 24;
    if (diffDays <= 5) {
      return t(daysOfWeek[date.getDay()]).slice(0, 3);
    }

    // Если прошло больше 5 дней
    return `${t(months[date.getMonth()]).slice(0, 3)} ${date.getDate()}`;
  }
}

export const parseDate = new ParseDate();
