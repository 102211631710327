import React, { useState, type ChangeEvent, type KeyboardEvent } from 'react';
import './TagInput.scss';

interface TagInputProps {
  label: string;
  placeholder: string;
  maxTags?: number;
  onTagsChange: (tags: string[]) => void;
}

export const TagInput: React.FC<TagInputProps> = ({
  label,
  placeholder,
  maxTags,
  onTagsChange,
}) => {
  const [tags, setTags] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>('');

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter' && inputValue.trim() && tags.length < maxTags) {
      const newTags = [...tags, inputValue.trim()];
      setTags(newTags);
      setInputValue('');

      onTagsChange?.(newTags);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);
  };

  const removeTag = (indexToRemove: number): void => {
    const newTags = tags.filter((_, index) => index !== indexToRemove);
    setTags(newTags);

    onTagsChange?.(newTags);
  };

  return (
    <div className="tag-input">
      {label && <label className="tag-input__label">{label}</label>}
      <div className="tag-input__wrapper">
        <input
          type="text"
          className="tag-input__input"
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        <div className="tag-input__tags">
          {tags.map((tag, index) => (
            <div className="tag-input__tag" key={index}>
              {tag}
              <span
                className="tag-input__remove"
                onClick={() => {
                  removeTag(index);
                }}
              >
                &times;
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
