import { type FC } from 'react';
import { ProfileServicesPageHeader } from './ProfileServicesPageHeader/ProfileServicesPageHeader';
import { ProfileServicesInfoCard } from '../ProfileServicesInfo/ProfileServicesInfoCard/ProfileServicesInfoCard';
import ServiceImg from '../../../../../assets/icons/default-service.jpeg';

interface IProps {
  services: any[];
}

export const ProfileServicesPage: FC<IProps> = ({ services }) => {
  return (
    <div className="user-profile__services">
      <ProfileServicesPageHeader amount={services.length} />

      <div className="user-profile__services__card-container">
        {services.map((service) => (
          <ProfileServicesInfoCard
            key={service.name}
            imageSrc={ServiceImg as string}
            title={service.description}
            price={service.price}
          />
        ))}
      </div>
    </div>
  );
};
