import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './redux/rootReducer';
import { QueryClient, QueryClientProvider } from 'react-query';
import './services/i18next';
import { LanguageContextProvider } from './components/LanguageContext/LanguageContext';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <LanguageContextProvider>
        <App />
      </LanguageContextProvider>
    </QueryClientProvider>
  </Provider>,
);
