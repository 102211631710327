import { type FC } from 'react';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';
import SearchIcon from '../../../../../components/SvgIcons/SearchIcon';

interface IProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
}

export const OrdersSearch: FC<IProps> = ({ setSearchValue, searchValue }) => {
  const { t } = useLanguageContext();
  return (
    <div className="orders__search">
      <div className="orders__search__wrap">
        <input
          className="orders__search__wrap__input"
          placeholder={t('Search orders')}
          type="text"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
        <SearchIcon className={'orders__search__wrap__icon'} />
      </div>
    </div>
  );
};
