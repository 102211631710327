import React, { type FC, useEffect, useState } from 'react';
import './Chats.scss';
import { type IChatsList } from '../../../../interfaces/api/chats';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../../../redux/rootReducer';
import { setChats, setOpenedChat } from '../../../../redux/chats/reducer';
import { Messenger } from '../Messenger/Messenger';
import { setChatPopupPosition } from '../../../../redux/messenger/reducer';
import { useSearchParams } from 'react-router-dom';
import { LoadingScreen } from '../../../../components/LoadingScreen/LoadingScreen';
import { PageInfoHeader } from '../../../../components/PageInfoHeader/PageInfoHeader';
import { ChatsList } from './ChatsList/ChatsList';
import { apiMessenger } from '../../../../services/api/messenger';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';
import { useLanguageContext } from '../../../../components/LanguageContext/LanguageContext';

// avatar url
export const avatarUrl = 'https://storage.yandexcloud.net/00x-avatars/';

export const Chats: FC = () => {
  // hooks
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { isMobile } = useWindowWidth();
  const { t } = useLanguageContext();
  // const navigate = useNavigate();

  // redux
  const chatsState = useSelector((state) => state.chats);

  // states
  const [allowRender, setAllowRender] = useState(false);

  // get chat id from params
  const id = searchParams.get('id');

  // get username from params
  const userName = searchParams.get('name');

  // Check if string is system message
  // const checkIfSystemMessage = (str: string): boolean => {
  //   const regex = /^System message\n\d$/;
  //   return regex.test(str);
  // };

  // Fetches user's chats
  const getMyChats = (signal?: AbortSignal): void => {
    apiMessenger.getChats().then((chats: IChatsList) => {
      if (chats) {
        // chats.forEach((chat) => {
        //   if (checkIfSystemMessage(chat.last_message)) {
        //     chat.last_message = t('System Message');
        //   }
        // });
        dispatch(setChats(chats));
        if (id) {
          const openedChat = Object.entries(chats)
            .flatMap(([key, items]) =>
              items.map((item) => ({
                ...item,
                chat_type: key,
              })),
            )
            .find((item) => item.id === id);
          dispatch(setOpenedChat(openedChat));
        }
        if (!allowRender) {
          setAllowRender(true);
        }
      } else {
        setTimeout(getMyChats, 1000);
      }
    });

    setAllowRender(true);
  };

  // Fetch chats when component mounts
  useEffect(() => {
    const controller = new AbortController();
    getMyChats(controller.signal);

    return () => {
      controller.abort();
    };
  }, [id, userName]);

  if (!allowRender) {
    return <LoadingScreen />;
  }

  return (
    <div
      className="chats"
      onClick={() => {
        dispatch(setChatPopupPosition(null));
      }}
    >
      {/* PageInfo header */}
      {!isMobile && (
        <PageInfoHeader
          page={'Messages'}
          additionalText={((id || userName) && [chatsState.openedChat?.name]) ?? null}
        />
      )}
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'start',
          height: isMobile ? '' : 'calc(100vh - 100px)',
        }}
      >
        {/* Nav menu with chats and search */}
        <ChatsList
          serviceChats={chatsState.chats.active_chat}
          generalChats={chatsState.chats.general_chat}
          historyOrdersChat={chatsState.chats.history_orders_chat}
        />

        {/* Chat window if opened */}
        {(id || userName) && chatsState.openedChat ? (
          <Messenger />
        ) : (
          <div className="chats__emptyMessegesScreen">
            <span>{t('Select who you would like to write to')}</span>
          </div>
        )}
      </div>
    </div>
  );
};
