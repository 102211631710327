import React, { type FC } from 'react';
import { BrowseServiceSearchCardUserInfo } from './BrowseServiceSearchCardUserInfo/BrowseServiceSearchCardUserInfo';

interface BrowseServiceSearchCardProps {
  imageUrl: string;
  userName: string;
  avatarUrl: string;
  rating: number;
  ratingCount: number;
  price: string;
  description: string;
}

export const BrowseServiceSearchCard: FC<BrowseServiceSearchCardProps> = ({
  imageUrl,
  userName,
  avatarUrl,
  rating,
  ratingCount,
  price,
  description,
}) => {
  return (
    <article className="browse__service-search-card">
      <img loading="lazy" src={imageUrl} alt="" className="browse__service-search-card__image" />
      <div className="browse__service-search-card__details">
        <BrowseServiceSearchCardUserInfo
          userName={userName}
          avatarUrl={avatarUrl}
          rating={rating}
          ratingCount={ratingCount}
          price={price}
        />
        <p className="browse__service-search-card__description">{description}</p>
      </div>
    </article>
  );
};
