import { type RouteObject } from 'react-router-dom';
import React from 'react';
import ProtectedLayout from '../layouts/ProtectedLayout';
import { Auth } from '../pages/Auth/Auth';
import { Home } from '../pages/Home/Home';

interface RouteConfig {
  path: string;
  selectedComponent: number;
}

const homeRoutes: RouteConfig[] = [
  { path: 'browse/:category', selectedComponent: 0 },
  { path: 'browse/:category/:login', selectedComponent: 0 },
  { path: 'browse/:category/:login/:page', selectedComponent: 0 },
  { path: 'messages', selectedComponent: 1 },
  { path: 'orders', selectedComponent: 2 },
  { path: 'finances', selectedComponent: 3 },
  { path: 'finances/:action', selectedComponent: 3 },
  { path: 'settings', selectedComponent: 4 },
  { path: 'profile', selectedComponent: 5 },
  { path: 'profile/settings', selectedComponent: 6 },
];

const generateHomeRoutes = (routes: RouteConfig[]): RouteObject[] =>
  routes.map(({ path, selectedComponent }) => ({
    path,
    element: <Home selectedComponent={selectedComponent} />,
  }));

const Routes: Array<RouteObject> = [
  {
    path: '/auth',
    element: <Auth />,
  },
  {
    path: '/',
    element: <ProtectedLayout />,
    children: generateHomeRoutes(homeRoutes),
  },
];

export default Routes;
