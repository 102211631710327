import { configureStore } from '@reduxjs/toolkit';
import { type TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';
import authReducer from './auth/reducer';
import chatsReducer from './chats/reducer';
import messengerReducer from './messenger/reducer';
import profileReducer from './profile/reducer';
import settingsReducer from './settings/reducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    chats: chatsReducer,
    messenger: messengerReducer,
    profile: profileReducer,
    settings: settingsReducer,
  },
});

export type IRootState = ReturnType<typeof store.getState>;
export type IAppDispatch = typeof store.dispatch;
export const useSelector: TypedUseSelectorHook<IRootState> = useReduxSelector;
