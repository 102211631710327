import React, { type FC, useEffect, useState } from 'react';
import './Home.scss';
import { Chats } from './components/Chats/Chats';
import { Browse } from './components/Browse/Browse';
import { apiProfile } from '../../services/api/legacy/profile';
import { Orders } from './components/Orders/Orders';
import { useDispatch } from 'react-redux';
import { Profile } from './components/Profile/Profile';
import { apiAuth } from '../../services/api/auth';
import { setUserName } from '../../redux/auth/reducer';
import { apiBalance } from '../../services/api/legacy/balance';
import { setMyProfile } from '../../redux/profile/reducer';
import { webSocketService } from '../../services/websocket';
import { Finances } from './components/Finances/Finances';
import { Settings } from './components/Settings/Settings';
import { setLanguage } from '../../redux/settings/reducer';
import { useLanguageContext } from '../../components/LanguageContext/LanguageContext';
import { useSelector } from '../../redux/rootReducer';
import { HomeNavBar } from './HomeNavBar/HomeNavBar';
import { LoadingScreen } from '../../components/LoadingScreen/LoadingScreen';
import BrowseIcon from '../../assets/icons/browse_icon.png';
import MessagesIcon from '../../assets/icons/messages_icon.png';
import OrderIcon from '../../assets/icons/orders_icon.png';
import FinancesIcon from '../../assets/icons/finances_icon.png';
import SettingsIcon from '../../assets/icons/settings_icon.png';
import HelpIcon from '../../assets/icons/help_icon.png';
import { ProfileEditPage } from './components/Profile/ProfileEditPage/ProfileEditPage';
import type { IWebsocketNewChatMessage } from '../../interfaces/api/websocket';
import { setChats, setLastMessage } from '../../redux/chats/reducer';
import { toast, ToastContainer } from 'react-toastify';
import NewMessageToast from '../../components/NewMessageToast/NewMessageToast';
import avatar from '../../assets/icons/user-default-avatar.png';
import { useLocation, useNavigate } from 'react-router-dom';

export interface IHomeProps {
  selectedComponent: number;
}

export const Home: FC<IHomeProps> = (props) => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  // const navigate = useNavigate();
  const { changeLanguage } = useLanguageContext();

  // redux
  const settingsState = useSelector((state) => state.settings);
  const chatsState = useSelector((state) => state.chats);
  const user = useSelector((state) => state.auth.user);

  // states
  const [allowRender, setAllowRender] = useState(false);
  const [myBalance, setMyBalance] = useState(null);

  // Navigation bar elements
  const NAVIGATION_ELEMENTS = [
    {
      icon: BrowseIcon as string,
      label: 'Browse',
      element: <Browse />,
    },
    {
      icon: MessagesIcon as string,
      label: 'Messages',
      element: <Chats />,
    },
    {
      icon: OrderIcon as string,
      label: 'Orders',
      element: <Orders />,
    },
    {
      icon: FinancesIcon as string,
      label: 'Finances',
      count: myBalance,
      element: <Finances />,
    },
    {
      icon: SettingsIcon as string,
      label: 'Settings',
      element: <Settings />,
    },
    {
      icon: '',
      label: 'Profile',
      element: <Profile isMyProfile={props.selectedComponent === 5} />,
    },
    {
      icon: HelpIcon as string,
      label: 'Log out',
      element: <></>,
    },
  ];

  // Fetch user info
  useEffect(() => {
    const getMyUserInfo = (): void => {
      apiAuth.getUser().then((r) => {
        // apiAuth.getUserLogin(r?.user_id).then((login: string) => dispatch(setUserName(login)));

        dispatch(setUserName(r.login));
        apiProfile.getProfile(r?.login).then((profile) => {
          if (profile) {
            apiBalance.getBalance().then((balance) => {
              balance &&
                dispatch(
                  setMyProfile({
                    ...profile,
                    balance: balance.amount - balance.locked,
                  }),
                );
              setMyBalance(((balance.amount - balance.locked) / 100).toString() + '$');
              balance && setAllowRender(true);
              !balance && setTimeout(getMyUserInfo, 1000);
            });
          } else {
            setTimeout(getMyUserInfo, 1000);
          }
        });
      });
    };
    getMyUserInfo();

    apiProfile.updateOnline();

    !localStorage.getItem('language') && localStorage.setItem('language', 'en');
    localStorage.getItem('language')
      ? dispatch(setLanguage(localStorage.getItem('language')))
      : dispatch(setLanguage('en'));
  }, []);

  // Set online status every 5 minutes
  useEffect(() => {
    const interval = setInterval(
      () => {
        apiProfile.updateOnline();
      },
      5 * 60 * 1000,
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Change language when language in settings changes
  useEffect(() => {
    changeLanguage(settingsState.language);
  }, [settingsState.language]);

  // Open WebSocket connection
  useEffect(() => {
    webSocketService.open();

    return () => {
      webSocketService.close();
    };
  }, []);

  // subscribe on chat ws
  useEffect(() => {
    webSocketService.onMessageReceived((data) => {
      switch (data.e) {
        case 'new_message': {
          const { chat_id: chatId, ...message } = data.d as IWebsocketNewChatMessage;
          const allChats = Object.entries(chatsState.chats).flatMap(([key, items]) =>
            items.map((item) => ({
              ...item,
              chat_type: key,
            })),
          );
          const currentWSChat = allChats.find((e) => e.id === chatId);
          if (currentWSChat) {
            dispatch(setLastMessage({ ...message, chat_id: chatId }));
            dispatch(
              setChats({
                ...chatsState.chats,
                [currentWSChat.chat_type]: chatsState.chats[currentWSChat.chat_type].map((chat) => {
                  if (chat.id === chatId) {
                    return {
                      ...chat,
                      unread_count:
                        user?.user_id !== message.author_id
                          ? (chat.unread_count as number) + 1
                          : chat.unread_count,
                      last_message: message.content,
                    };
                  }
                  return chat;
                }),
              }),
            );
            user?.user_id !== message.author_id &&
              location.pathname !== '/messages' &&
              toast(
                <NewMessageToast
                  message={message.content}
                  img={avatar}
                  chatId={chatId}
                  chatName={currentWSChat.name}
                />,
                {
                  onClick: () => {
                    navigate(`/messages?id=${chatId}`);
                  },
                },
              );
          }
          break;
        }
      }
    });
  }, [chatsState.chats, location.pathname, user?.user_id]);

  if (!allowRender) {
    return <LoadingScreen />;
  }

  return (
    <div className="home">
      {/* Navigation sidebar */}
      <HomeNavBar
        selectedPage={props.selectedComponent === 7 ? 3 : props.selectedComponent}
        navBarElements={NAVIGATION_ELEMENTS}
      />
      {/* Main content (depends on route) */}
      <div className="home__content">
        {props.selectedComponent === 6 ? (
          <ProfileEditPage />
        ) : (
          NAVIGATION_ELEMENTS[props.selectedComponent].element
        )}
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={false}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="light"
      />
    </div>
  );
};
