import React, { type FC } from 'react';
import OpenListIcon from '../../../../../assets/icons/open-list-icon.png';

interface ChatSectionProps {
  title: string;
  onChangeVisibility: () => void;
  opened: boolean;
}

export const ChatsSection: FC<ChatSectionProps> = ({ title, onChangeVisibility, opened }) => {
  return (
    <div
      className="chats__section-header"
      onClick={onChangeVisibility}
      style={{
        cursor: 'pointer',
        userSelect: 'none',
      }}
    >
      <h2 className="chats__section-title">{title}</h2>
      <img
        src={OpenListIcon}
        alt=""
        className="chats__section-icon"
        style={{
          transform: !opened && 'rotate(-90deg)',
          transition: 'all 0.2s ease',
        }}
      />
    </div>
  );
};
