import React, { type FC } from 'react';

interface ButtonProps {
  variant: 'primary' | 'secondary';
  children: React.ReactNode;
  onClick?: () => void;
}

export const Button: FC<ButtonProps> = ({ variant, children, onClick }) => {
  return (
    <button
      className={`user-profile__edit-page__button user-profile__edit-page__button--${variant}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
