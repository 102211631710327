import React, { type FC } from 'react';
import { FinancesHistoryTableMobileItem } from './FinancesHistoryTableMobileItem/FinancesHistoryTableMobileItem';
import type { FinancesTransaction } from '../../FinancesHistory';
import { format, isToday, isThisYear } from 'date-fns';

interface IProps {
  transactions: FinancesTransaction[];
}

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  if (isToday(date)) {
    return 'Today';
  }
  if (isThisYear(date)) {
    return format(date, 'MMM dd');
  }
  return format(date, 'MMM dd yyyy');
};

const groupTransactionsByDate = (
  transactions: FinancesTransaction[],
): Record<string, FinancesTransaction[]> => {
  return transactions.reduce<Record<string, FinancesTransaction[]>>((acc, transaction) => {
    const dateKey = format(new Date(transaction.date), 'yyyy-MM-dd');
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(transaction);
    return acc;
  }, {});
};

export const FinancesHistoryTableMobile: FC<IProps> = ({ transactions }) => {
  const groupedTransactions = groupTransactionsByDate(transactions);
  return (
    <section className="finances__operations-section">
      <ul className="finances__operations-list">
        {Object.entries(groupedTransactions).map(([date, transactions]) => (
          <li key={date} className="finances__operations-date-group">
            <h5 className="finances__operations-date">{formatDate(date)}</h5>
            {transactions.map((transaction) => (
              <FinancesHistoryTableMobileItem
                key={transaction.id}
                type={transaction.type}
                amount={transaction.amount}
                method={transaction.method}
                status={transaction.status}
                isPositive={transaction.type === 'Payment' || transaction.type === 'Deposit'}
              />
            ))}
          </li>
        ))}
      </ul>
    </section>
  );
};
