import React, { type FC } from 'react';
import { Table, type TableProps } from 'antd';
import { Space } from 'antd';
import MessageIcon from '../../../../../assets/icons/messages_icon.png';
import { OrdersGroupHeader } from '../OrdersGroupHeader/OrdersGroupHeader';
import { useNavigate } from 'react-router-dom';
import { type IJob } from '../../../../../interfaces/api/packageProcess';
import { OrdersListMobileTable } from './OrdersListMobileTable/OrdersListMobileTable';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';
import ServicesIcon from '../../../../../components/SvgIcons/ServicesIcon';
import CalendarIcon from '../../../../../components/SvgIcons/CalendarIcon';
import PeopleIcon from '../../../../../components/SvgIcons/PeopleIcon';
import StatusIcon from '../../../../../components/SvgIcons/StatusIcon';
import ThunderIcon from '../../../../../components/SvgIcons/ThunderIcon';
import { orderStatusConfig } from '../../../../../configs/translation/orderConfigs/orderStatusConfig';
import MockImage from '../../../../../assets/icons/browse-mock-service-image.png';

interface IProps {
  headerTitle: string;
  orders: any[];
  role: string;
}

export const OrdersList: FC<IProps> = ({ orders, role, headerTitle }) => {
  // hooks
  const navigate = useNavigate();
  const { t } = useLanguageContext();

  // columns of the table
  const columns: TableProps['columns'] = [
    {
      title: (
        <div className="orders__list__desktop-table__header">
          <ServicesIcon />
          <span className="orders__list__desktop-table__header__title">{t('Services')}</span>
        </div>
      ),
      dataIndex: 'package_name',
      key: 'service',
      render: (_, record: IJob) => (
        <div className="orders__list__package_name">
          <img src={MockImage as string} alt="" />
          <span className="orders__list__title">{record.package_name}</span>
        </div>
      ),
    },
    {
      title: (
        <div className="orders__list__desktop-table__header">
          <CalendarIcon />
          <span className="orders__list__desktop-table__header__title">{t('Date')}</span>
        </div>
      ),
      dataIndex: 'date',
      key: 'date',
      render: () => (
        <div>
          <span className="orders__list__title">ABC 0 - ABC 1</span>
        </div>
      ),
    },
    {
      title: (
        <div className="orders__list__desktop-table__header">
          <PeopleIcon />
          <span className="orders__list__desktop-table__header__title">
            {role === 'Customer' ? t('ExecutorWithoutDots') : t('Customer')}
          </span>
        </div>
      ),
      dataIndex: role === 'Customer' ? 'executor_login' : 'client_login',
      key: 'person',
      render: (_, record: IJob) => (
        <div className="orders__list__person">
          <img src={MockImage as string} alt="" />
          <span className="orders__list__person__title">
            {role === 'Customer' ? record.executor_login : record.client_login}
          </span>
        </div>
      ),
    },
    {
      title: (
        <div className="orders__list__desktop-table__header">
          <StatusIcon />
          <span className="orders__list__desktop-table__header__title">{t('Status')}</span>
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      render: (_, record: IJob) => {
        const randomIndex = Math.floor(Math.random() * 5);
        return (
          <div className="orders__list__status">
            <span
              style={{
                backgroundColor: orderStatusConfig[randomIndex]?.bgColor,
                color: orderStatusConfig[randomIndex]?.color,
              }}
            >
              {t(orderStatusConfig[randomIndex]?.label)}
            </span>
          </div>
        );
      },
    },
    {
      title: (
        <div className="orders__list__desktop-table__header-last">
          <ThunderIcon />
          <span className="orders__list__desktop-table__header__title">{t('Actions')}</span>
        </div>
      ),
      key: 'action',
      render: (_, record: IJob) => (
        <Space size="small" style={{ display: 'flex', justifyContent: 'end' }}>
          <button
            className="orders__list__buttons__message"
            onClick={() => {
              navigate(`/messages?id=${record.chat_id}`);
            }}
          >
            <img src={MessageIcon as string} alt="" />
            <span>{t('Message')}</span>
          </button>
        </Space>
      ),
    },
  ];

  return (
    <div className="orders__list">
      <OrdersGroupHeader title={headerTitle} count={orders.length} />
      {orders.length > 0 && (
        <div className="orders__list__desktop-table ">
          <Table
            style={{ width: '100%' }}
            columns={columns}
            dataSource={orders}
            className="orders__list__custom_table"
            pagination={{ rootClassName: 'table__paginator' }}
            tableLayout="fixed"
          />
        </div>
      )}
      <OrdersListMobileTable orders={orders} />
    </div>
  );
};
