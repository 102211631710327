import React, { type FC, useEffect, useRef, useState } from 'react';
import type { IMessage } from '../../../../../interfaces/api/messenger';
import { useSelector } from '../../../../../redux/rootReducer';
import MessageBubble from '../MessageBubble/MessageBubble';
import { Loader } from '../../../../../components/Loader/Loader';

interface IMessengerList {
  messages: IMessage[];
  getUserMessages: (limit: number) => Promise<void>;
  isLoadingMore: boolean;
}

export const MessengerList: FC<IMessengerList> = ({ messages, getUserMessages, isLoadingMore }) => {
  // users id
  const usersId = useSelector((state) => state.auth.user.user_id);

  // state
  const [canScrollToBottom, setCanScrollToBottom] = useState(true);

  // refs for messages list
  const ref = useRef(null);

  const changeTimeFormat = (sentDate: string): string => {
    const date = new Date(sentDate);

    const formatter = new Intl.DateTimeFormat('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    return formatter.format(date);
  };

  useEffect(() => {
    if (ref.current && messages.length > 0 && canScrollToBottom) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [messages]);

  const loadMore = async (container: any): Promise<void> => {
    if (!isLoadingMore) {
      // Сохраняем текущую высоту скролла до загрузки новых сообщений
      const previousScrollHeight = container.scrollHeight;
      const previousScrollTop = container.scrollTop;

      // Загружаем сообщения
      await getUserMessages(messages.length);

      // Если пользователь скроллит вверх (т.е. контейнер scrollTop === 0), восстанавливаем позицию
      if (previousScrollTop === 0) {
        const newScrollHeight = container.scrollHeight;
        container.scrollTop = newScrollHeight - previousScrollHeight;
      }
    }
  };

  const scrollHandler = async (container: any): Promise<void> => {
    if (container.scrollTop === 0) {
      await loadMore(container);
    }

    const isScrollBottom =
      Number(container.scrollTop) + Number(container.clientHeight) >= container.scrollHeight - 1;

    if (isScrollBottom) {
      setCanScrollToBottom(true);
    } else {
      setCanScrollToBottom(false);
    }
  };

  useEffect(() => {
    if (ref) scrollHandler(ref.current);
  }, [ref]);

  return (
    <div
      className="messenger__list"
      ref={ref}
      onScroll={(e) => {
        scrollHandler(e.target);
      }}
    >
      {isLoadingMore && (
        <div className="messenger__list__loader">
          <Loader size={30} />
        </div>
      )}
      {messages.map((message: IMessage) => (
        <div className="messenger__list__elem" key={message.id}>
          <MessageBubble
            toMe={usersId !== message.author_id}
            content={message.content}
            time={changeTimeFormat(message.created_at)}
            isRead={message.is_read}
            files={message.files}
            images={message.images}
          />
        </div>
      ))}
    </div>
  );
};
