import React, { type FC } from 'react';
import WithdrawIcon from '../../../../../../../../assets/icons/finances-history-withdraw-icon.png';
import DepositIcon from '../../../../../../../../assets/icons/finances-history-deposit-icon.png';
import TransferIcon from '../../../../../../../../assets/icons/finances-history-transfer-icon.png';
import PaymentIcon from '../../../../../../../../assets/icons/finances-history-payment-icon.png';
import { useLanguageContext } from '../../../../../../../../components/LanguageContext/LanguageContext';

interface OperationItemProps {
  method: string;
  type: string;
  amount: string;
  isPositive: boolean;
  status: string;
}

const setTypeImage = (text: string): string => {
  switch (text) {
    case 'Withdraw':
      return WithdrawIcon as string;
    case 'Deposit':
      return DepositIcon as string;
    case 'Transfer':
      return TransferIcon as string;
    case 'Payment':
      return PaymentIcon as string;
    default:
      return '';
  }
};

export const FinancesHistoryTableMobileItem: FC<OperationItemProps> = ({
  method,
  type,
  amount,
  isPositive,
  status,
}) => {
  const { t } = useLanguageContext();
  return (
    <li className="finances__operation-item">
      <div
        style={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <div className="finances__operation-icon-wrapper">
          <img loading="lazy" src={setTypeImage(type)} alt="" />
        </div>
        <div className="finances__operation-details">
          <h3 className="finances__operation-title">{t(type)}</h3>
          <p className="finances__operation-type">{method.slice(0, 12) + '...'}</p>
        </div>
      </div>
      <div className="finances__operation-status">
        <p
          className={`finances__operation-amount ${isPositive ? 'finances__operation-amount--positive' : 'finances__operation-amount--negative'}`}
        >
          {isPositive ? `+${amount}` : `-${amount}`}
        </p>
        <span>{t(status)}</span>
      </div>
    </li>
  );
};
