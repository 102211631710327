import { ApiServiceBase } from '../apiServiceBase';
import { type IMessage, type ISendMessageRequest } from '../../interfaces/api/messenger';

class ApiMessenger extends ApiServiceBase {
  protected baseUrl = 'v2/messenger/';

  public getChats = (): Promise<unknown> => {
    return this.get('chats/');
  };

  public getPersonalChat = (userId: string): Promise<unknown> => {
    return this.post('chats/personal/' + userId);
  };

  public deleteChat = async (chatId: string): Promise<boolean> => {
    return !!(await this.delete('chats/' + chatId));
  };

  // messages

  public getMessages = (chatId: string, offset: number): Promise<IMessage[] | null> => {
    return this.get<IMessage[]>('messages/get', {
      query: {
        chat_id: chatId,
        offset: offset || 0,
      },
    });
  };

  //
  public sendMessage = (
    chatId: string,
    content: string,
    images?: File[],
    files?: File[],
  ): Promise<IMessage> => {
    return this.post<ISendMessageRequest, IMessage>(
      'messages/send',
      {
        chat_id: chatId,
        content,
        images,
        files,
      },
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );
  };

  public readMessages = (chatId: string): Promise<boolean> => {
    return this.post('messages/read/' + chatId) as unknown as Promise<boolean>;
  };

  public deleteMessage = async (chatId: string, messageId: string): Promise<boolean> => {
    try {
      const response = await this.post('del_msg', {
        message_id: messageId,
        chat_id: chatId,
      });

      return response.status === 200;
    } catch (e) {
      e && console.log(e);
      return false;
    }
  };
}

export const apiMessenger = new ApiMessenger();
