import React, { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../redux/rootReducer';
import {
  setCurrentActivityStep,
  setCurrentOpenedChatPopup,
} from '../../../../../../../redux/messenger/reducer';
import Popup from '../../../../../../../components/Popup/Popup';

const MessengerOrderInfoCloseDealPopup: FC = () => {
  const dispatch = useDispatch();
  const currentOpenedChatPopup = useSelector((state) => state.messenger.currentOpenedChatPopup);

  const onClose = (): void => {
    dispatch(setCurrentOpenedChatPopup(null));
  };

  const onSubmit = (): void => {
    dispatch(setCurrentOpenedChatPopup('Order completed'));
    dispatch(setCurrentActivityStep(5));
    // TODO: прописать логику onSubmit
  };

  const onCancel = (): void => {
    onClose();
  };

  return (
    <Popup
      isOpen={currentOpenedChatPopup === 'Close the deal'}
      onClose={onClose}
      btns={{
        right: { onClick: onSubmit, label: 'Confirm' },
        left: { onClick: onCancel, label: 'Cancel' },
      }}
      centered
      title={{ label: 'Close the deal' }}
      description={{ label: 'Are you sure you wanna close the deal?' }}
    />
  );
};

export default MessengerOrderInfoCloseDealPopup;
