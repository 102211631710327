import React, { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../redux/rootReducer';
import { setCurrentOpenedChatPopup } from '../../../../../../../redux/messenger/reducer';
import Popup from '../../../../../../../components/Popup/Popup';
import MessengerOrderInfoField from '../../MessengerOrderInfoField/MessengerOrderInfoField';

const MessengerOrderInfoOrderCompPopup: FC = () => {
  // hooks
  const dispatch = useDispatch();

  // redux
  const currentOpenedChatPopup = useSelector((state) => state.messenger.currentOpenedChatPopup);
  const orderInfo = useSelector((state) => state.messenger.orderInfo);

  const onClose = (): void => {
    dispatch(setCurrentOpenedChatPopup(null));
  };

  const onSubmit = (): void => {
    dispatch(setCurrentOpenedChatPopup('Selects stars to rate'));
    // TODO: прописать логику onSubmit
  };

  return (
    <Popup
      isOpen={currentOpenedChatPopup === 'Order completed'}
      onClose={onClose}
      btns={{
        right: { onClick: onSubmit, label: 'Provide feedback' },
      }}
      centered
      title={{
        label: 'Order completed',
        style: { fontFamily: 'Inter-Bold, sans-serif', fontSize: 27 },
      }}
    >
      <div className="modal-messenger">
        <div className="modal-messenger__order-completed">
          <MessengerOrderInfoField title={'Timeline'} value={orderInfo.name} />
          <MessengerOrderInfoField
            title={'Project fee'}
            value={orderInfo.price.toString() + orderInfo.currency + '/' + orderInfo.price_for}
          />
          <MessengerOrderInfoField
            title={'Unfront payment'}
            value={orderInfo.currency + orderInfo.unFront_payment.toString()}
          />
        </div>
      </div>
    </Popup>
  );
};

export default MessengerOrderInfoOrderCompPopup;
