import { type FC } from 'react';
import { HomeNavBarItem, type NavItem } from './HomeNavBarItem/HomeNavBarItem';
import { useNavigate } from 'react-router-dom';
import WebsiteLogo from '../../../assets/icons/00x-logo.png';

interface IProps {
  selectedPage: number;
  navBarElements: NavItem[];
}

export const HomeNavBar: FC<IProps> = ({ selectedPage, navBarElements }) => {
  // hooks
  const navigate = useNavigate();

  // Pages urls for redirect
  const pagesUrls = {
    Browse: '/browse/people',
    Messages: '/messages',
    Orders: '/orders',
    Profile: `/profile`,
    Finances: '/finances',
    Settings: '/settings',
  };

  return (
    <nav className="home__nav">
      <header className="home__nav__header">
        <img src={WebsiteLogo as string} alt="" />
        <h1 className="home__nav__title">Club</h1>
      </header>
      <ul className="home__nav__list">
        {navBarElements.map((item, index) => (
          <li key={index} className="home__nav__list__el">
            <HomeNavBarItem
              label={item.label}
              icon={item.icon}
              count={item.count}
              isSelected={selectedPage === index}
              onClick={() => {
                if (index === 6) {
                  localStorage.removeItem('user');
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                } else {
                  navigate(pagesUrls[item.label]);
                }
              }}
            />
          </li>
        ))}
      </ul>
    </nav>
  );
};
