import { ApiServiceBase } from '../../apiServiceBase';
import {
  type IAddServiceRequestData,
  type IEditPackageRequestData,
  type IPackage,
} from '../../../interfaces/api/packages';

class ApiPackage extends ApiServiceBase {
  protected baseUrl = 'users_packages/';

  public addPackage = async (jsonData: IAddServiceRequestData, file: File): Promise<boolean> => {
    const formData = new FormData();
    file && formData.append('file', file);

    const jsonBlob = new Blob([JSON.stringify(jsonData)], {
      type: 'application/json',
    });
    formData.append('json', jsonBlob);

    try {
      const response = await this.post('add_package', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.status === 200;
    } catch (err) {
      err && console.log(err);
      return false;
    }
  };

  public getPackages = async (id: string): Promise<{ packages: IPackage[] }> => {
    try {
      const response = await this.get<{ packages: IPackage[] }>('get_packages', {
        query: {
          user_id: id,
        },
      });
      return response ?? { packages: [] };
    } catch (err) {
      err && console.log(err);
      return { packages: [] };
    }
  };

  public deletePackage = async (name: string): Promise<boolean> => {
    try {
      const response = await this.post('del_package', {
        name,
      });
      return response.status === 200;
    } catch (err) {
      err && console.log(err);
      return false;
    }
  };

  public editPackage = async (newData: IEditPackageRequestData, file?: File): Promise<boolean> => {
    const formData = new FormData();
    file && formData.append('file', file);

    const jsonBlob = new Blob([JSON.stringify(newData)], {
      type: 'application/json',
    });
    formData.append('json', jsonBlob);

    try {
      const response = await this.post('edit_package', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.status === 200;
    } catch (err) {
      err && console.log(err);
      return false;
    }
  };
}

export const apiPackage = new ApiPackage();
