import React, { useState, type FC } from 'react';
import InfoIcon from '../../../../../../assets/icons/finances-info-icon.png';
import DepositIcon from '../../../../../../assets/icons/deposit-icon.png';
import WithdrawIcon from '../../../../../../assets/icons/withdraw-icon.png';
import TrendUpIcon from '../../../../../../assets/icons/finances-trend-up-icon.png';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';
import { Tooltip } from 'antd';
// import { parseDate } from '../../../../../../services/dateServices/parseDate';

interface BalanceCardProps {
  title: string;
  amount: string;
  percentageChange?: number;
  isLocked: boolean;
  unlockDate?: Date;
  openDepositModal?: () => void;
  openWithdrawModal?: () => void;
}

export const FinancesAccountsBalanceCard: FC<BalanceCardProps> = ({
  title,
  amount,
  percentageChange,
  isLocked,
  unlockDate,
  openDepositModal,
  openWithdrawModal,
}) => {
  // hooks
  const { t, i18n } = useLanguageContext();

  const [isHovered] = useState(false);

  const currentLanguage = i18n.language;

  const amountNumber = parseFloat(amount);

  return (
    <article className="finances__content__accounts__balance-card">
      <header className="finances__content__accounts__balance-card__header">
        <h2 className="finances__content__accounts__balance-card__title">{title}</h2>
        <button
          className="finances__content__accounts__balance-card__info-button"
          aria-label="More information"
        >
          <img
            src={InfoIcon}
            alt=""
            className="finances__content__accounts__balance-card__info-icon"
          />
        </button>
      </header>
      <p className="finances__content__accounts__balance-card__amount">{amount}</p>
      {!isLocked && (
        <>
          <p
            className={`finances__content__accounts__balance-card__percentage-change finances__content__accounts__balance-card__percentage-change-${currentLanguage === 'ru' ? 'ru' : 'en'}`}
          >
            <img
              src={TrendUpIcon}
              alt=""
              className="finances__content__accounts__balance-card__trend-icon"
            />
            <span className="finances__content__accounts__balance-card__change-value">
              {percentageChange}%
            </span>
            <Tooltip title={currentLanguage === 'ru' ? t('vs last month') : t('vs last month')}>
              <span
                className="finances__content__accounts__balance-card__change-period"
                style={{
                  cursor: 'pointer',
                  display: 'inline-block',
                  maxWidth: '100%',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {isHovered && currentLanguage === 'ru'
                  ? t('vs last month')
                  : currentLanguage === 'ru'
                    ? (t('vs last month').slice(0, 25) as string) + '...'
                    : t('vs last month')}
              </span>
            </Tooltip>
          </p>
          <div className="finances__content__accounts__balance-card__action-buttons">
            <button
              className="finances__content__accounts__balance-card__action-button"
              onClick={openDepositModal}
            >
              <span>{t('Deposit')}</span>
              <img
                src={DepositIcon}
                alt=""
                className="finances__content__accounts__balance-card__button-icon"
              />
            </button>
            <button
              className={`finances__content__accounts__balance-card__action-button ${amountNumber === 0 ? 'disabled' : ''}`}
              // onClick={amountNumber === 0 ? undefined : openWithdrawModal}
              onClick={openWithdrawModal}
              // disabled={amountNumber === 0}
            >
              <span>{t('Withdraw')}</span>
              <img
                src={WithdrawIcon}
                alt=""
                className="finances__content__accounts__balance-card__button-icon"
              />
            </button>
          </div>
        </>
      )}
      {isLocked && (
        <p className="finances__content__accounts__balance-card__unlock-info">
          {/* eslint-disable-next-line prettier/prettier */}
          {t('Use of Fund')}
        </p>
      )}
    </article>
  );
};
