import type { FC } from 'react';
import type { TypesSvgIcon } from './TypesSvgIcon';

const StatusIcon: FC<TypesSvgIcon> = ({ color, className }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill={'none'}>
      <path
        d="M6.00032 0.595581C5.72064 0.595581 5.49365 0.822568 5.49365 1.10225C5.49365 1.38193 5.72064 1.60891 6.00032 1.60891C8.42151 1.60891 10.3914 3.57883 10.3914 6.00003C10.3914 8.42122 8.42151 10.3911 6.00032 10.3911C5.72064 10.3911 5.49365 10.6181 5.49365 10.8978C5.49365 11.1775 5.72064 11.4045 6.00032 11.4045C8.98019 11.4045 11.4048 8.9799 11.4048 6.00003C11.4048 3.02015 8.98019 0.595581 6.00032 0.595581Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M8.59622 4.45161C8.76714 4.23003 8.7266 3.91184 8.50502 3.74092C8.28479 3.57068 7.96525 3.61054 7.79434 3.83212L5.2941 7.06736L4.18079 5.82974C3.99366 5.62167 3.67345 5.60546 3.4647 5.79191C3.25663 5.97904 3.23974 6.29925 3.42687 6.508L4.94687 8.19689C5.04347 8.30363 5.17994 8.36443 5.32383 8.36443C5.32991 8.36443 5.33667 8.36443 5.34275 8.36443C5.49272 8.35902 5.63256 8.28674 5.72443 8.16784L8.59555 4.45228L8.59622 4.45161Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M2.17873 9.10487C1.98079 9.30281 1.98079 9.6237 2.17873 9.82164C2.37666 10.0196 2.69755 10.0196 2.89549 9.82164C3.09343 9.6237 3.09343 9.30281 2.89549 9.10487C2.69755 8.90694 2.37666 8.90694 2.17873 9.10487Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M1.10237 6.50674C1.38219 6.50674 1.60904 6.2799 1.60904 6.00007C1.60904 5.72025 1.38219 5.49341 1.10237 5.49341C0.822545 5.49341 0.595703 5.72025 0.595703 6.00007C0.595703 6.2799 0.822545 6.50674 1.10237 6.50674Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M2.17873 2.17836C1.98079 2.3763 1.98079 2.69719 2.17873 2.89512C2.37666 3.09306 2.69755 3.09306 2.89549 2.89512C3.09343 2.69719 3.09343 2.3763 2.89549 2.17836C2.69755 1.98042 2.37666 1.98042 2.17873 2.17836Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M4.32004 10.0567C4.0613 9.94927 3.76541 10.0722 3.658 10.331C3.55058 10.5897 3.67354 10.8856 3.93227 10.993C4.19101 11.1004 4.4869 10.9775 4.59432 10.7187C4.70173 10.46 4.57878 10.1634 4.32004 10.0567Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M1.2816 7.40649C1.02286 7.51391 0.900586 7.8098 1.00732 8.06854C1.11474 8.32728 1.41063 8.44955 1.66937 8.34281C1.92811 8.2354 2.05038 7.93951 1.94364 7.68077C1.83691 7.42203 1.54034 7.29976 1.2816 7.40649Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M1.66916 3.65788C1.41043 3.55046 1.11453 3.67341 1.00712 3.93215C0.899705 4.19089 1.02266 4.48678 1.28139 4.5942C1.54013 4.70161 1.83603 4.57866 1.94344 4.31992C2.05085 4.06118 1.9279 3.76529 1.66916 3.65788Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M4.32025 1.94331C4.57898 1.83657 4.70126 1.54 4.59452 1.28126C4.48711 1.0232 4.19122 0.90025 3.93248 1.00699C3.67374 1.11373 3.55146 1.41029 3.6582 1.66903C3.76494 1.92777 4.06151 2.05005 4.32025 1.94331Z"
        fill={color || '#A0A0A0'}
      />
    </svg>
  );
};

export default StatusIcon;
