import type { FC } from 'react';
import type { TypesSvgIcon } from './TypesSvgIcon';

const PeopleIcon: FC<TypesSvgIcon> = ({ color, className }) => {
  return (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" className={className}>
      <path
        d="M4.9997 5.32447C6.30555 5.32447 7.36414 4.26587 7.36414 2.96003C7.36414 1.65418 6.30555 0.595581 4.9997 0.595581C3.69385 0.595581 2.63525 1.65418 2.63525 2.96003C2.63525 4.26587 3.69385 5.32447 4.9997 5.32447Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M4.99975 6C3.13252 6 1.43687 7.10521 0.679574 8.81504C0.505956 9.20686 0.507983 9.65611 0.684978 10.0479C0.866027 10.4472 1.20988 10.7525 1.63008 10.8849C2.72313 11.2295 3.85671 11.4044 4.99908 11.4044C6.14144 11.4044 7.27502 11.2295 8.36807 10.8849C8.78827 10.7525 9.1328 10.4472 9.31317 10.0479C9.49017 9.65611 9.4922 9.20686 9.31858 8.81504C8.56128 7.10521 6.86564 6 4.9984 6H4.99975Z"
        fill={color || '#A0A0A0'}
      />
    </svg>
  );
};

export default PeopleIcon;
