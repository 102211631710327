import React, { type FC } from 'react';
import { avatarUrl } from '../../../Chats/Chats';
import DefaultAvatar from '../../../../../../assets/icons/user-default-avatar.png';
import MessageIcon from '../../../../../../assets/icons/messages_white_icon.png';
import MenuIcon from '../../../../../../assets/icons/menu-icon.png';
import EditProfileIcon from '../../../../../../assets/icons/profile-edit-icon.png';
import { useNavigate } from 'react-router-dom';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';

interface ProfileHeaderProps {
  name: string;
  username: string;
  userId: string;
  isMyProfile: boolean;
}

export const ProfileMainInfoHeader: FC<ProfileHeaderProps> = ({
  name,
  username,
  userId,
  isMyProfile,
}) => {
  // hooks
  const navigate = useNavigate();
  const { t } = useLanguageContext();

  const userOnline = true;

  return (
    <header className="user-profile__header">
      <div className="user-profile__avatar-container">
        <object className="user-profile__avatar" data={avatarUrl + userId} type="image/png">
          <img className="user-profile__avatar" src={DefaultAvatar as string} alt="" />
        </object>
        <div
          className="user-profile__header__status-indicator"
          aria-hidden="true"
          style={{
            backgroundColor: userOnline ? '#34c759' : 'gray',
          }}
        />
      </div>
      <div className="user-profile__user-info">
        <h1 className="user-profile__name">{name}</h1>
        <p className="user-profile__username">@{username}</p>
      </div>
      <div className="user-profile__header__buttons">
        {isMyProfile ? (
          <button
            className="user-profile__header__buttons__edit-btn"
            onClick={() => {
              navigate('/profile/settings');
            }}
          >
            <img
              src={EditProfileIcon as string}
              alt=""
              className="user-profile__header__buttons__edit-btn__icon"
            />
            <span>{t('Edit profile')}</span>
          </button>
        ) : (
          <button className="user-profile__header__buttons__message-btn">
            <img
              src={MessageIcon as string}
              alt=""
              className="user-profile__header__buttons__message-btn__icon"
            />
            <span>{t('Message')}</span>
          </button>
        )}

        <button className="user-profile__header__buttons__menu-btn">
          <img
            src={MenuIcon as string}
            alt=""
            className="user-profile__header__buttons__menu-btn__icon"
          />
        </button>
      </div>
    </header>
  );
};
