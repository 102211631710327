import React, { type FC } from 'react';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';

interface ProfileBioProps {
  description: string;
  aboutMe: string;
}

export const ProfileMainInfoBio: FC<ProfileBioProps> = ({ description, aboutMe }) => {
  // hooks
  const { t } = useLanguageContext();

  return (
    <section className="user-profile__bio">
      <h2 className="user-profile__bio-title">{t('About')}</h2>
      <p className="user-profile__bio-headline">{description}</p>
      <p className="user-profile__bio-description">{aboutMe}</p>
      {!description && !aboutMe && (
        <p className="user-profile__bio-description">{t('No info yet')}</p>
      )}
    </section>
  );
};
