import { type ProfileState } from './types';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type IProfile } from '../../interfaces/api/profile';

const initialState: ProfileState = {
  profile: null,
  myProfile: null,
};

const profileReducer = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<IProfile>) => {
      state.profile = action.payload;
    },
    setMyProfile: (state, action: PayloadAction<IProfile>) => {
      state.myProfile = action.payload;
    },
  },
});

export const { setProfile, setMyProfile } = profileReducer.actions;

export default profileReducer.reducer;
