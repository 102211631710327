import React, { type FC } from 'react';

export const AuthDivider: FC = () => {
  return (
    <div className="auth-login__divider">
      <div className="auth-login__divider-line" />
      <div className="auth-login__divider-text">or</div>
      <div className="auth-login__divider-line" />
    </div>
  );
};
