import React, { type FC } from 'react';
import DefaultAvatar from '../../../../../../assets/icons/user-default-avatar.png';

interface UserInfoProps {
  userName: string;
  avatarUrl: string;
  rating: number;
  ratingCount: number;
  price: string;
}

export const BrowseServiceSearchCardUserInfo: FC<UserInfoProps> = ({
  userName,
  avatarUrl,
  rating,
  ratingCount,
  price,
}) => {
  return (
    <div className="browse__service-search-card__user-info">
      <div className="browse__service-search-card__avatar-wrapper">
        <object
          className="browse__service-search-card__avatar-image"
          data={avatarUrl}
          type="image/png"
        >
          <img className="browse__service-search-card__avatar-image" src={DefaultAvatar} alt="" />
        </object>
        <div className="browse__service-search-card__status-indicator" />
      </div>
      <div className="browse__service-search-card__user-details">
        <div className="browse__service-search-card__user-name">{userName}</div>
        <div className="browse__service-search-card__rating-wrapper">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6e637e227947d91c07f2c24602c92ca5946ee358c22d67efceb74a8db89022aa?placeholderIfAbsent=true&apiKey=4806e4dcc8d34e9a84cfbb788e512e8d"
            alt=""
            className="browse__service-search-card__rating-icon"
          />
          <span className="browse__service-search-card__rating-score">{rating.toFixed(1)}</span>
          <span className="browse__service-search-card__rating-count">{ratingCount}</span>
        </div>
      </div>
      <div className="browse__service-search-card__price">${price}</div>
    </div>
  );
};
