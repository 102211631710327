import { type FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../Profile.scss';
import { LoadingScreen } from '../../../../../components/LoadingScreen/LoadingScreen';
import { apiReviews } from '../../../../../services/api/legacy/reviews';
import { apiAuth } from '../../../../../services/api/auth';
import { ProfileReviewsPageHeader } from './ProfileReviewsPageHeader/ProfileReviewsPageHeader';
import { ProfileReviewsInfoCard } from '../ProfileReviewsInfo/ProfileReviewsInfoCard/ProfileReviewsInfoCard';
import StarsIcon from '../../../../../assets/icons/five-stars-icon.png';

export const ProfileReviewsPage: FC = () => {
  // hooks
  // states
  const [allowRender, setAllowRender] = useState(false);
  const [reviews, setReviews] = useState(null);
  const [rank, setRank] = useState('');
  const [reviewsCount, setReviewsCount] = useState('');
  // const [userLogin, setUserLogin] = useState('');
  const [id, setId] = useState('');
  const [filterButton, setFilterButton] = useState(0);

  // Users id
  const { login } = useParams();
  if (!id) {
    apiAuth.getUserId(login).then((r) => {
      setId(r?.[0]);
    });
  }

  console.log(reviews);

  // Fetch reviews and average rating by user id
  useEffect(() => {
    if (id) {
      apiReviews.getReviews(id).then((r) => {
        if (r) {
          setReviews([...r, ...r, ...r, ...r]);
        }
        apiReviews.getRating(id).then((r) => {
          setRank(r?.rank.toString());
          setReviewsCount(r?.count.toString());
          apiAuth.getUserLogin(id).then((login) => {
            // login && setUserLogin(login);
            setAllowRender(true);
          });
        });
      });
    }
  }, [id]);

  if (!allowRender) {
    return <LoadingScreen />;
  }

  return (
    <div className="user-profile__reviews">
      <ProfileReviewsPageHeader
        rating={parseFloat(rank).toFixed(2)}
        amount={reviewsCount}
        chosenFilterButton={filterButton}
        setChosenFilterButton={(index) => {
          setFilterButton(index);
        }}
      />

      <div className="user-profile__reviews__card-container">
        {reviews
          .filter((review) => {
            switch (filterButton) {
              case 1:
                return review.rank >= 4;
              case 2:
                return review.rank < 4;
            }
            return true;
          })
          .map((review, index) => (
            <ProfileReviewsInfoCard
              key={index}
              logoSrc={StarsIcon as string}
              reviewerName={'User name'}
              reviewDate={new Date()}
              reviewContent={review.description}
            />
          ))}
      </div>
    </div>
  );
};
