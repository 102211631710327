import React, { type FC } from 'react';
import { ProfileEditPageFormInput } from '../ProfileEditPageFormInput/ProfileEditPageFormInput';
import { type ProfileInfo } from '../../ProfileEditPage';
import { useLanguageContext } from '../../../../../../../components/LanguageContext/LanguageContext';

interface IProps {
  profileInfo: ProfileInfo;
  setProfileInfo: (info: ProfileInfo) => void;
}

export const ProfileEditPageFormPublic: FC<IProps> = ({ profileInfo, setProfileInfo }) => {
  // hooks
  const { t } = useLanguageContext();

  return (
    <div className="user-profile__edit-page__form">
      <div className="user-profile__edit-page__form__header">
        <h2>{t('Public profile')}</h2>
        <p>{t('This will be displayed on your profile')}</p>
      </div>
      <div className="user-profile__edit-page__form__content">
        <ProfileEditPageFormInput
          label={`${String(t('First name'))} *`}
          value={profileInfo.firstName}
          onChange={(e) => {
            setProfileInfo({
              ...profileInfo,
              firstName: e.target.value,
            });
          }}
        />
        <ProfileEditPageFormInput
          label={`${String(t('Last name'))} *`}
          value={profileInfo.lastName}
          onChange={(e) => {
            setProfileInfo({
              ...profileInfo,
              lastName: e.target.value,
            });
          }}
        />
        <ProfileEditPageFormInput
          label={`${String(t('Username'))} *`}
          value={profileInfo.userName}
          onChange={(e) => {
            setProfileInfo({
              ...profileInfo,
              userName: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};
