import React, { type FC } from 'react';
import { ProfileServicesInfoCard } from './ProfileServicesInfoCard/ProfileServicesInfoCard';
import DefaultServiceImage from '../../../../../assets/icons/default-service.jpeg';
import ArrowRightIcon from '../../../../../assets/icons/arrow-right-icon.png';
import { useNavigate } from 'react-router-dom';
import { type IJob } from '../../../../../interfaces/api/packageProcess';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';

interface IProps {
  packages: any[];
  openOrderRequestModal: (service: IJob) => void;
}

export const ProfileServicesInfo: FC<IProps> = ({ packages, openOrderRequestModal }) => {
  // hooks
  const navigate = useNavigate();
  const { t } = useLanguageContext();

  return (
    <section className="user-profile__content-section__services-n-portfolio">
      <div className="user-profile__content-section__services-n-portfolio__heading">
        <div className="user-profile__content-section__services-n-portfolio__heading__title-wrapper">
          <span>{t('Services')}</span>
          <div></div>
          <h3>{packages.length}</h3>
        </div>
        <button
          onClick={() => {
            const currentPath = window.location.pathname;
            navigate(`${currentPath}/services`);
          }}
        >
          {t('See all')}
          <img src={ArrowRightIcon as string} alt="" />
        </button>
      </div>
      <div className="user-profile__content-section__services-n-portfolio__card-container">
        {packages.slice(0, 3).map((service, index) => (
          <ProfileServicesInfoCard
            key={index}
            imageSrc={DefaultServiceImage as string}
            title={service.description}
            price={parseInt(service.price) / 100}
            onClick={() => {
              openOrderRequestModal(service);
            }}
          />
        ))}
      </div>
    </section>
  );
};
