import React, { type FC } from 'react';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';

interface IProps {
  title: string;
  count: number;
}

export const OrdersGroupHeader: FC<IProps> = ({ title, count }) => {
  // hooks
  const { t } = useLanguageContext();

  return (
    <div className="orders__group-header">
      <span>{t(title)}</span>
      <div></div>
      <h3>{count}</h3>
    </div>
  );
};
