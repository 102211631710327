import { ApiServiceBase } from '../../apiServiceBase';
import { type IGetBalanceResponse } from '../../../interfaces/api/balance';

class ApiBalance extends ApiServiceBase {
  protected baseUrl = 'balance/';

  public getBalance = (): Promise<IGetBalanceResponse> => {
    // try {
    //   const response = await this.get<IGetBalanceResponse>('get_balance');
    //   if (response.status === 200) {
    //     return response.data;
    //   }
    //   return {
    //     amount: 0,
    //     locked: 0,
    //   };
    // } catch (err) {
    //   err && console.log(err);
    //   return null;
    // }
    return Promise.resolve({
      amount: 0,
      locked: 0,
    });
  };
}

export const apiBalance = new ApiBalance();
