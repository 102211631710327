import React, { type FC } from 'react';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';

interface ProfileSkillsProps {
  skills: string[];
}

export const ProfileMainInfoSkills: FC<ProfileSkillsProps> = ({ skills }) => {
  // hooks
  const { t } = useLanguageContext();

  return (
    <section className="user-profile__skills">
      <h2 className="user-profile__skills-title">{t('Skills')}</h2>
      <div className="user-profile__skills-list">
        {skills?.map((skill, index) => (
          <span key={index} className="user-profile__skills-tag">
            {skill}
          </span>
        ))}
      </div>
    </section>
  );
};
