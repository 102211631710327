import React, { type FC } from 'react';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';

interface TabProps {
  icon: string;
  text: string;
  isActive: boolean;
  onClick: () => void;
}

export const BrowseHeaderTab: FC<TabProps> = ({ icon, text, isActive, onClick }) => {
  // hooks
  const { t } = useLanguageContext();

  return (
    <div className={`browse__tab ${isActive ? 'browse__tab--active' : 'browse__tab--inactive'}`}>
      <button
        className="browse__tab__button"
        onClick={onClick}
        disabled={text === 'Projects'}
        style={{
          cursor: text === 'Projects' && 'not-allowed',
        }}
      >
        <img loading="lazy" src={icon} alt="" className="browse__tab__icon" />
        <span className="browse__tab__text">{t(text)}</span>
      </button>
    </div>
  );
};
