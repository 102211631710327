import React, { type FC } from 'react';
import { Button } from './Button/Button';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';

interface ProfileEditPageHeaderButtonsProps {
  onDiscard: () => void;
  onSave: () => void;
}

export const ProfileEditPageHeaderButtons: FC<ProfileEditPageHeaderButtonsProps> = ({
  onDiscard,
  onSave,
}) => {
  // hooks
  const { t } = useLanguageContext();

  return (
    <div className="user-profile__edit-page__buttons">
      <Button variant="secondary" onClick={onDiscard}>
        {t('Cancel')}
      </Button>
      <Button variant="primary" onClick={onSave}>
        {t('Save')}
      </Button>
    </div>
  );
};
