import React, { type FC } from 'react';
import type { TypesSvgIcon } from './TypesSvgIcon';

const CloseIcon: FC<TypesSvgIcon> = ({ color, className }) => {
  return (
    <svg width="50" height="51" viewBox="0 0 50 51" fill="none" className={className || ''}>
      <path
        d="M49.4336 42.0443L32.3503 24.8958L49.4727 7.96875C50.1758 7.26563 50.1758 6.11979 49.4727 5.41667L44.6029 0.520833C44.2643 0.182292 43.8086 0 43.3268 0C42.8451 0 42.3893 0.195312 42.0508 0.520833L25.0065 17.3958L7.9362 0.533855C7.59766 0.195313 7.14193 0.0130216 6.66016 0.0130216C6.17838 0.0130216 5.72266 0.208334 5.38411 0.533855L0.527344 5.42969C-0.175781 6.13281 -0.175781 7.27865 0.527344 7.98177L17.6497 24.9089L0.579427 42.0443C0.240886 42.3828 0.0455733 42.8385 0.0455733 43.3203C0.0455733 43.8021 0.227865 44.2578 0.579427 44.5964L5.44922 49.4922C5.80078 49.8437 6.25651 50.026 6.72526 50.026C7.18099 50.026 7.64974 49.8568 8.0013 49.4922L25.0065 32.4089L42.0247 49.4792C42.3763 49.8307 42.832 50.013 43.3008 50.013C43.7565 50.013 44.2253 49.8437 44.5768 49.4792L49.4466 44.5833C49.7852 44.2448 49.9805 43.7891 49.9805 43.3073C49.9674 42.8385 49.7721 42.3828 49.4336 42.0443Z"
        fill={color || 'black'}
      />
    </svg>
  );
};

export default CloseIcon;
