import { ApiServiceBase } from '../../apiServiceBase';
import { type IGetHashTagsResponse } from '../../../interfaces/api/hashtag';

class ApiHashTag extends ApiServiceBase {
  protected baseUrl = 'hashtag/';

  public getHashTags = (): Promise<IGetHashTagsResponse> => {
    // const response = await this.get<IGetHashTagsResponse>('search_hashtag', {
    //   query: {
    //     hashtag: '',
    //   },
    // });
    // if (response.status === 200) {
    //   return response.data;
    // }
    return Promise.resolve({ hashtags: [] });
  };
}

export const apiHashTag = new ApiHashTag();
