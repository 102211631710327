import React, { type FC } from 'react';

const StepsIcon: FC = () => {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.87806 5.60153L6.62609 0.971087L6.6262 0.970946C6.84891 0.69619 7.25221 0.651932 7.52878 0.876201L2.87806 5.60153ZM2.87806 5.60153L1.37621 3.72444C1.37619 3.72442 1.37618 3.7244 1.37616 3.72438L1.37607 3.72427L2.87806 5.60153ZM3.37362 7.02865L3.37332 7.02903C3.25087 7.17974 3.06665 7.26654 2.87385 7.26654C2.67912 7.26654 2.49503 7.17753 2.37405 7.02586L2.37412 7.02595L2.58235 6.85937L2.37378 7.02552L3.37362 7.02865ZM3.37362 7.02865L7.62352 1.77878M3.37362 7.02865L7.62352 1.77878M7.62352 1.77878C7.84699 1.50308 7.80388 1.09926 7.52885 0.87626L7.62352 1.77878Z"
        fill="white"
        stroke="white"
        strokeWidth="0.533333"
      />
    </svg>
  );
};

export default StepsIcon;
