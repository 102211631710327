import React, { type FC } from 'react';
import { type IPopupContent } from '../types';
// @ts-expect-error
import s from '../Popup.module.scss';
import { useLanguageContext } from '../../LanguageContext/LanguageContext';

const PopupContent: FC<IPopupContent> = ({ title, description, children, btns }) => {
  const { t } = useLanguageContext();

  return (
    <div className={s.modalContent}>
      {title && (
        <div className={title.className || s.title} style={title?.style}>
          {t(title.label)}
        </div>
      )}
      {description && (
        <div className={description.className || s.description} style={title?.style}>
          {t(description.label)}
        </div>
      )}
      {children}
      {btns && (
        <div className={s.footer}>
          <div className={s.btns}>
            {btns.left && (
              <div className={s.cancelBtn} onClick={btns.left.onClick} style={btns.left.style}>
                {t(btns.left.label)}
              </div>
            )}
            {btns.right && (
              <div className={s.submitBtn} onClick={btns.right.onClick} style={btns.right.style}>
                {t(btns.right.label)}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupContent;
