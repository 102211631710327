import { type FC, useEffect, useState } from 'react';
import './Orders.scss';
import { apiPackageProcess } from '../../../../services/api/legacy/packageProcess';
import { LoadingScreen } from '../../../../components/LoadingScreen/LoadingScreen';
import { apiAuth } from '../../../../services/api/auth';
import { type IJob } from '../../../../interfaces/api/packageProcess';
import { OrdersHeader } from './OrdersHeader/OrdersHeader';
import { OrdersSearch } from './OrdersSearch/OrdersSearch';
import { OrdersList } from './OrdersList/OrdersList';
import { useSelector } from '../../../../redux/rootReducer';
import { useLanguageContext } from '../../../../components/LanguageContext/LanguageContext';

export const Orders: FC = () => {
  // states
  const [activeOrders, setActiveOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [allowRender, setAllowRender] = useState(false);
  const [chosenRole, setChosenRole] = useState('Freelancer');
  const [searchValue, setSearchValue] = useState('');

  // User id
  const myId = useSelector((state) => state.auth.user.user_id);

  // Filter orders list depending on role
  const filterListByRole = (list: IJob[]): IJob[] => {
    return list.filter((order: IJob) => {
      if (chosenRole === 'Freelancer') {
        return order.executor_id === myId;
      }
      return order.client_id === myId;
    });
  };

  // Filter orders list by search value
  const filterListBySearchValue = (list: IJob[]): IJob[] => {
    return !searchValue
      ? list
      : list.filter((order: IJob) =>
          order.package_name.toLowerCase().includes(searchValue.toLowerCase()),
        );
  };

  // hooks
  const { t } = useLanguageContext();

  // Fetch user orders and sort them by status
  useEffect(() => {
    const getAllOrders = (): void => {
      apiPackageProcess.getOrders().then((r) => {
        if (!r) {
          // TODO
          setAllowRender(true);
          return;
        }
        if (!r.jobs) {
          setTimeout(getAllOrders, 1000);
        } else {
          if (r.jobs.length > 0) {
            const getLogins = new Promise<IJob[]>((resolve) => {
              r.jobs.forEach((value, index, array) => {
                apiAuth.getUserLogin(value.executor_id).then((login: string) => {
                  value.executor_login = login;
                  apiAuth.getUserLogin(value.client_id).then((login: string) => {
                    value.client_login = login;
                    index === array.length - 1 && resolve(r.jobs);
                  });
                });
              });
            });
            getLogins.then((r) => {
              r && setActiveOrders(r.filter((job) => job.status !== 'Completed'));
              r && setCompletedOrders(r.filter((job) => job.status === 'Completed'));
              setAllowRender(true);
            });
          } else {
            setActiveOrders([]);
            setCompletedOrders([]);
            setAllowRender(true);
          }
        }
      });
    };
    getAllOrders();
  }, []);

  if (!allowRender) {
    return (
      <>
        <LoadingScreen />
      </>
    );
  }

  return (
    <div className="orders">
      {/* Header */}
      <OrdersHeader
        chosenRole={chosenRole}
        setChosenRole={(role) => {
          setChosenRole(role);
        }}
      />
      <div className="orders__content">
        {/* Search field */}
        <OrdersSearch
          searchValue={searchValue}
          setSearchValue={(value) => {
            setSearchValue(value);
          }}
        />

        {/* List of active orders */}
        <OrdersList
          orders={filterListBySearchValue(filterListByRole(activeOrders))}
          role={chosenRole}
          headerTitle={t('Active')}
        />

        {/* List of completed orders */}
        <div className="orders__content__list-second">
          <OrdersList
            orders={filterListBySearchValue(filterListByRole(completedOrders))}
            role={chosenRole}
            headerTitle={t('History')}
          />
        </div>
      </div>
    </div>
  );
};
