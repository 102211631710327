import React, { type FC } from 'react';
import { Steps } from 'antd';
import StepsIcon from '../../../../../../components/StepsComponents/StepsIcon';
import { useOrderActivitySteps } from '../../../../../../configs/orderInfo/orderInfoActivity';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';

const MessengerOrderInfoActivity: FC = () => {
  // hooks
  const { orderActivitySteps, currentStep } = useOrderActivitySteps();
  const { t } = useLanguageContext();

  return (
    <Steps
      direction="vertical"
      current={currentStep}
      items={orderActivitySteps.map((step, index) => ({
        title: t(step.title),
        description: index === currentStep ? step.description : null,
        icon: currentStep > index ? <StepsIcon /> : null,
      }))}
      rootClassName="messenger__order__info__wrap__activitysteps"
    />
  );
};

export default MessengerOrderInfoActivity;
