import React, { type FC, useCallback, useState } from 'react';
import { type IChat } from '../../../../../interfaces/api/chats';
import ChatListWrap from './ChatListWrap/ChatListWrap';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';
import { ChatsSearch } from '../ChatsSearch/ChatsSearch';

interface IProps {
  generalChats: IChat[];
  serviceChats: IChat[];
  historyOrdersChat: IChat[];
}

export const ChatsList: FC<IProps> = ({ generalChats, serviceChats, historyOrdersChat }) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const resetSearchValue = useCallback(() => {
    setSearchValue('');
  }, []);

  const filterChat = (chat: IChat): boolean => {
    return (
      !searchValue || chat.name.includes(searchValue) || chat.last_message.includes(searchValue)
    );
  };

  const { t } = useLanguageContext();

  return (
    <nav className="chats__list">
      <div className="chats__title-mobile">{t('Messages')}</div>

      <ChatsSearch searchValue={searchValue} setSearchValue={setSearchValue} />

      {serviceChats.length > 0 && (
        <section className="chats__section">
          <ChatListWrap
            resetSearchValue={resetSearchValue}
            title={'Active orders'}
            items={searchValue ? serviceChats.filter(filterChat) : serviceChats}
            chatType={'active_chat'}
          />
        </section>
      )}
      <section className="chats__section">
        <ChatListWrap
          resetSearchValue={resetSearchValue}
          title={'General'}
          items={searchValue ? generalChats.filter(filterChat) : generalChats}
          chatType={'general_chat'}
        />
      </section>
      {historyOrdersChat.length > 0 && (
        <section className="chats__section">
          <ChatListWrap
            resetSearchValue={resetSearchValue}
            title={'Order history'}
            items={searchValue ? historyOrdersChat.filter(filterChat) : historyOrdersChat}
            chatType={'history_orders_chat'}
          />
        </section>
      )}
    </nav>
  );
};
