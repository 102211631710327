import React, { type FC } from 'react';
import MessageIcon from '../../../../../../../assets/icons/messages_icon.png';
import { Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { orderStatusConfig } from '../../../../../../../configs/translation/orderConfigs/orderStatusConfig';
import { useLanguageContext } from '../../../../../../../components/LanguageContext/LanguageContext';

interface IProps {
  img: string;
  title: string;
  status: string;
  chatId: string;
}

export const OrdersListMobileTableItem: FC<IProps> = ({ img, title, status, chatId }) => {
  // hooks
  const navigate = useNavigate();
  const { t } = useLanguageContext();

  const randomIndex = Math.floor(Math.random() * 5);

  return (
    <div className="orders__list__mobile-table__item">
      <div className="orders__list__mobile-table__item__img">
        <img src={img} alt="" />
      </div>
      <div className="orders__list__mobile-table__item__info">
        <span>{title}</span>
        <p
          style={{
            color: orderStatusConfig[randomIndex]?.color,
          }}
        >
          {t(orderStatusConfig[randomIndex]?.label)}
        </p>
      </div>
      <div className="orders__list__mobile-table__item__menu">
        <Space size="small" style={{ display: 'flex', justifyContent: 'end' }}>
          <button
            className="orders__list__buttons__message"
            onClick={() => {
              navigate(`/messages?id=${chatId}`);
            }}
          >
            <img src={MessageIcon as string} alt="" />
          </button>
        </Space>
      </div>
    </div>
  );
};
