import React, { type FC, useCallback, useState } from 'react';
import { ChatsSection } from '../../ChatsSection/ChatsSection';
import ChatsListItem from '../ChatsListItem/ChatsListItem';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';
import type { IChat } from '../../../../../../interfaces/api/chats';
import { useSearchParams } from 'react-router-dom';

interface ChatListProps {
  title: string;
  items: IChat[];
  resetSearchValue: () => void;
  chatType: string;
}

const ChatListWrap: FC<ChatListProps> = ({ title, items, resetSearchValue, chatType }) => {
  const { t } = useLanguageContext();
  const [searchParams] = useSearchParams();

  const [opened, setOpened] = useState(true);

  const id = searchParams.get('id');

  const onChangeVisibility = useCallback((): void => {
    setOpened((prev) => !prev);
  }, []);

  return (
    <>
      <ChatsSection title={t(title)} onChangeVisibility={onChangeVisibility} opened={opened} />
      {opened &&
        items.map((chat) => (
          <ChatsListItem
            resetSearchValue={resetSearchValue}
            key={chat.id}
            chat={chat}
            chatType={chatType}
            isActive={chat.id === id}
          />
        ))}
    </>
  );
};

export default ChatListWrap;
