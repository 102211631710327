import React, { type FC } from 'react';
import CopyIcon from '../../../../../../assets/icons/finances-deposit-copy-icon.png';
import { message } from 'antd';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';

interface AddressSectionProps {
  address: string;
}

export const FinancesDepositPageAddressSection: FC<AddressSectionProps> = ({ address }) => {
  // hooks
  const { t } = useLanguageContext();

  const fallbackCopyToClipboard = (text: string): void => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    message.info(t('Address copied (fallback)'));
  };

  const copyToClipboard = async (text: string): Promise<void> => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(text);
        message.info(t('Address copied'));
      } catch (err) {
        console.error('Не удалось скопировать: ', err);
      }
    } else {
      fallbackCopyToClipboard(text);
    }
  };

  return (
    <section className="finances__deposit-address">
      <div className="finances__deposit-address-info">
        <div className="finances__deposit-address-label">{t('Address')}</div>

        <div className="finances__deposit-address-wrapper">
          <div className="finances__deposit-address-value">
            <span>{address}</span>
          </div>
          <button
            className="finances__deposit-address-copy-button"
            onClick={() => {
              copyToClipboard(address);
            }}
          >
            <img
              loading="lazy"
              src={CopyIcon as string}
              alt=""
              className="finances__deposit-address-copy-icon"
            />
            <span className="finances__deposit-address-copy-text">{t('Copy')}</span>
          </button>
        </div>
      </div>
    </section>
  );
};
