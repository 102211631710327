import '../Browse.scss';
import { type FC } from 'react';
import GButton, { ButtonColors, ButtonTypes } from '../../../../../components/GButton/GButton';
import DefaultAvatar from '../../../../../assets/icons/user-default-avatar.png';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';
import { avatarUrl } from '../../Chats/Chats';

interface IProps {
  user_id: string;
  name?: string;
  login?: string;
  skills?: string;
  chars?: string;
  preferences?: string;
  onViewProfile?: () => void;
  hashtags?: string[];
}

export const BrowseSearchCard: FC<IProps> = (props: IProps) => {
  const { t } = useLanguageContext();

  return (
    <div className="browse__search-card">
      <div className="browse__search-card__header">
        <div className="browse__search-card__header-avatar">
          <div className="browse__search-card__header-avatar-img">
            <object className="avatar" data={avatarUrl + props.user_id} type="image/png">
              <img className="avatar" src={DefaultAvatar} alt="" />
            </object>
          </div>
        </div>
        <div className="browse__search-card__header-info">
          <div className="browse__search-card__header-info-name">
            <span id="info-login">{props.name ?? `@${props.login}`}</span>
          </div>
          <div className="browse__search-card__header-info-tags">
            {props.hashtags.map((tag, index) => (
              <div key={index}>
                <span>#{tag}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="browse__search-card__body">
        <div className="browse__search-card__body-skills">
          <span>{t('Skills')}</span>
          <div>{props.skills ?? t('No information')}</div>
        </div>
        <div className="browse__search-card__body-chars">
          <span>{t('Characteristics')}</span>
          <div>{props.chars ?? t('No information')}</div>
        </div>
        <div className="browse__search-card__body-prefs">
          <span>{t('Preferences')}</span>
          <div>{props.preferences ?? t('No information')}</div>
        </div>
      </div>
      <div className="browse__search-card__footer">
        <GButton
          title={t('View profile')}
          type={ButtonTypes.BUTTON}
          color={ButtonColors.BLUE}
          bordered={true}
          filled={true}
          tiny={true}
          height={30}
          onClick={() => {
            props.onViewProfile();
          }}
        ></GButton>
      </div>
    </div>
  );
};
