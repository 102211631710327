import { type IMessengerState, type TCurrentOpenedChatPopup } from './types';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type IMessage } from '../../interfaces/api/messenger';
import { type IOrderInfo } from '../../interfaces/api/packageProcess';

const initialState: IMessengerState = {
  messages: [],
  messagePopupPosition: null,
  chatPopupPositionL: null,
  currentOpenedChatPopup: null,
  currentActivityStep: 2,
  orderInfo: null,
};

const messengerReducer = createSlice({
  name: 'messenger',
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<IMessage[]>) => {
      state.messages = action.payload;
    },
    setMessagePopupPosition: (state, action: PayloadAction<{ top: number; left: number }>) => {
      state.messagePopupPosition = action.payload;
    },
    setChatPopupPosition: (state, action: PayloadAction<{ top: number; left: number }>) => {
      state.chatPopupPositionL = action.payload;
    },
    setCurrentOpenedChatPopup: (state, action: PayloadAction<TCurrentOpenedChatPopup>) => {
      state.currentOpenedChatPopup = action.payload;
    },
    setCurrentActivityStep: (state, action: PayloadAction<number>) => {
      state.currentActivityStep = action.payload;
    },
    setOrderInfo: (state, action: PayloadAction<IOrderInfo>) => {
      state.orderInfo = action.payload;
    },
    setDeleteMessage: (state, action: PayloadAction<{ messageId: string }>) => {
      state.messages = state.messages.filter((message) => message.id !== action.payload.messageId);
    },
    setAddMessage: (state, action: PayloadAction<{ message: IMessage }>) => {
      state.messages.push(action.payload.message);
    },
  },
});

export const {
  setMessages,
  setMessagePopupPosition,
  setChatPopupPosition,
  setDeleteMessage,
  setAddMessage,
  setCurrentOpenedChatPopup,
  setCurrentActivityStep,
  setOrderInfo,
} = messengerReducer.actions;

export default messengerReducer.reducer;
