import type { FC } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useSelector } from '../redux/rootReducer';
import { setUser } from '../redux/auth/reducer';
import { apiAuth } from '../services/api/auth';

const ProtectedLayout: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authStore = useSelector((state) => state.auth);
  useQuery({
    queryKey: ['auth'],
    queryFn: apiAuth.getUser,
    onSuccess: (user) => {
      const { user_id: userId } = JSON.parse(localStorage.getItem('user') ?? '{}') as {
        user_id: string;
      };

      if (user?.user_id !== userId) {
        dispatch(setUser(user));
      }

      if (window.location.pathname === '/') navigate('/browse/services');
    },
    onError: (err) => {
      // @ts-expect-error
      if (err.response.status === 500) {
        return <Outlet />;
      }
      dispatch(setUser(null));
      localStorage.removeItem('user');
      navigate('/auth');
    },
    staleTime: 120 * 60 * 1000,
    cacheTime: 120 * 60 * 1000,
    retry: false,
  });

  if (!authStore.user) {
    return <Navigate to="/auth" />;
  }

  return <Outlet />;
};

export default ProtectedLayout;
