import React, { type FC } from 'react';
import { Select } from 'antd';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';
import { useMediaSize } from '../../../../../../hooks/useMediaSize';
import ArrowDownIconSelect from '../../../../../../components/SvgIcons/ArrowDownIconSelect';

interface IProps {
  filters: string[];
  filterBy: number;
  setFilterBy: (filter: number) => void;
  sortBy: string;
  setSortBy: (sort: string) => void;
}

export const FinancesHistoryFilter: FC<IProps> = ({
  filters,
  filterBy,
  setFilterBy,
  sortBy,
  setSortBy,
}) => {
  // hooks
  const { t } = useLanguageContext();
  const [isMobile] = useMediaSize();
  return (
    <div className="finances__content__history__filter">
      <div className="finances__content__history__filter__chip-group">
        {filters.map((option, index) => (
          <button
            key={option}
            className={`finances__content__history__filter__chip ${
              index === filterBy ? 'finances__content__history__filter__chip--active' : ''
            }`}
            onClick={() => {
              setFilterBy(index);
            }}
          >
            {t(option)}
          </button>
        ))}
      </div>
      {isMobile ? (
        <></>
      ) : (
        <Select
          placeholder="Borderless"
          variant="borderless"
          options={[
            { label: t('Last month'), value: 'month' },
            { label: t('Last week'), value: 'week' },
            { label: t('Today'), value: 'today' },
          ]}
          style={{ height: 'unset' }}
          dropdownStyle={{ width: '10%' }}
          defaultValue={sortBy}
          onChange={(value) => {
            setSortBy(value);
          }}
          suffixIcon={<ArrowDownIconSelect />}
        ></Select>
      )}
    </div>
  );
};
