import React, { type FC, useState } from 'react';
import { avatarUrl } from '../../Chats/Chats';
import DefaultAvatar from '../../../../../assets/icons/user-default-avatar.png';
import { apiAuth } from '../../../../../services/api/auth';
import { useNavigate } from 'react-router-dom';
import MessageIcon from '../../../../../assets/icons/messages_icon.png';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';

interface BrowseProfileSearchCardProps {
  userId: string;
  userName: string;
  userLevel?: string;
  rating: number;
  reviewCount: number;
  price: number;
  description: string;
  skills: string[];
}

export const BrowseProfileSearchCard: FC<BrowseProfileSearchCardProps> = ({ userId, userName }) => {
  // hooks
  const navigate = useNavigate();
  const { t } = useLanguageContext();

  // states
  const [isOnline] = useState(true);

  return (
    <article
      className="browse__profile-search-card"
      onClick={() => {
        apiAuth.getUserLogin(userId).then((login: string) => {
          navigate(`/browse/people/${login}`);
        });
      }}
    >
      <header className="browse__profile-search-card__header">
        <div className="browse__profile-search-card__header__top">
          <object
            className="browse__profile-search-card__avatar"
            data={avatarUrl + userId}
            type="image/png"
          >
            <img className="browse__profile-search-card__avatar" src={DefaultAvatar} alt="" />
          </object>
          <div
            className="browse__profile-search-card__avatar__status-indicator"
            aria-hidden="true"
            style={{
              backgroundColor: isOnline ? '#34c759' : 'gray',
            }}
          />
          <button
            className="browse__profile-search-card__header__top__message-btn"
            onClick={(event) => {
              event.stopPropagation();
              navigate(`/messages?name=${userName}`);
            }}
          >
            <img
              src={MessageIcon as string}
              alt=""
              className="browse__profile-search-card__header__top__message-btn__icon"
            />
            <span>{t('Message')}</span>
          </button>
        </div>
        <div className="browse__profile-search-card__header__bottom">
          <h3>{userName}</h3>
          <h4>@{userName}</h4>
        </div>
      </header>

      <div className="browse__profile-search-card__work">
        <span>{t('User has not added work yet')}</span>
      </div>
    </article>
  );
};
