import React, { type FC } from 'react';
import { type TypesSvgIcon } from './TypesSvgIcon';

const BackIcon: FC<TypesSvgIcon> = ({ color, className }) => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" className={className || ''}>
      <path
        d="M10.1998 4H1.7998"
        stroke={color || '#222222'}
        strokeWidth="1.68"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.7998 1L1.7998 4L4.7998 7"
        stroke={color || '#222222'}
        strokeWidth="1.68"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BackIcon;
