import { type FC } from 'react';
import { OrdersListMobileTableItem } from './OrdersListMobileTableItem/OrdersListMobileTableItem';
import MockImage from '../../../../../../assets/icons/browse-mock-service-image.png';

interface IProps {
  orders: any[];
}

export const OrdersListMobileTable: FC<IProps> = ({ orders }) => {
  return (
    <div className="orders__list__mobile-table">
      {orders.map((order, index) => (
        <OrdersListMobileTableItem
          img={MockImage as string}
          title={order.package_name}
          status={'In progress'}
          key={index}
          chatId={order.chat_id}
        />
      ))}
    </div>
  );
};
