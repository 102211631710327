import { apiWebsocket } from './api/websocket';
import { type IWebsocketEvent } from '../interfaces/api/websocket';

class WebSocketService {
  private readonly url = `wss://00x.club/api/v2/websocket/connect?token=`;
  private ws: WebSocket;
  private active = false;
  private messageCallback: ((data: any) => void) | null = null;
  private readonly reconnectionTimeout = 3 * 1000; // TODO: change to 3 * 1000 for prod version

  public async open(): Promise<void> {
    this.active = true;

    const token = await apiWebsocket.getToken();

    this.ws = new WebSocket(this.url + token);
    this.ws.onopen = this.onOpen.bind(this);
    this.ws.onmessage = this.onMessage.bind(this);
    this.ws.onclose = this.onClose.bind(this);
    this.ws.onerror = (err) => {
      console.log('error', err);
    };
  }

  public close(): void {
    this.active = false;
    this.ws.close();
  }

  public onOpen(): void {
    console.log('Opened connection');
  }

  public onMessage(event: MessageEvent): void {
    if (this.messageCallback) {
      this.messageCallback(event.data);
    }
  }

  public onClose(...args: unknown[]): void {
    console.log('conn closed', ...args);
    setTimeout(() => {
      this.active && this.open();
    }, this.reconnectionTimeout);
  }

  public onMessageReceived(callback: (data: IWebsocketEvent<unknown>) => void): void {
    this.messageCallback = (data: string) => {
      try {
        console.log('data', data);
        callback(JSON.parse(data));
      } catch (e) {}
    };
  }
}

export const webSocketService = new WebSocketService();
