import React, { type FC } from 'react';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';

interface TabProps {
  text: string;
  isActive: boolean;
  onClick: () => void;
}

export const OrdersHeaderTab: FC<TabProps> = ({ text, isActive, onClick }) => {
  // hooks
  const { t } = useLanguageContext();

  return (
    <div
      className={`orders__header__buttons__tab ${isActive ? 'orders__header__buttons__tab--active' : 'orders__header__buttons__tab--inactive'}`}
    >
      <button className="orders__header__buttons__tab__button" onClick={onClick}>
        <span className="orders__header__buttons__tab__text">{t(text)}</span>
      </button>
    </div>
  );
};
