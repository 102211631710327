import { useDispatch } from 'react-redux';
import { useSelector } from '../../redux/rootReducer';
import { setCurrentActivityStep, setCurrentOpenedChatPopup } from '../../redux/messenger/reducer';
import MessengerOrderInfoActivityDescriptionPattern from '../../pages/Home/components/Messenger/MessengerOrderInfo/MessengerOrderInfoActivity/MessengerOrderInfoActivityDescriptionPattern';
import { useLanguageContext } from '../../components/LanguageContext/LanguageContext';

interface IorderActivityStep {
  title: string;
  description: React.ReactNode;
}

interface IuseOrderActivitySteps {
  orderActivitySteps: IorderActivityStep[];
  currentStep: number;
  currentItem: IorderActivityStep;
}

export const useOrderActivitySteps = (): IuseOrderActivitySteps => {
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.messenger.currentActivityStep);
  const orderInfo = useSelector((state) => state.messenger.orderInfo);
  const { t } = useLanguageContext();

  const nextStep = (): void => {
    dispatch(setCurrentActivityStep(currentStep + 1));
  };

  const confirmTerms = (): void => {
    dispatch(setCurrentOpenedChatPopup('Confirm terms'));
  };

  const editRequest = (): void => {
    dispatch(setCurrentOpenedChatPopup('Edit request'));
  };

  const finish = (): void => {
    dispatch(setCurrentOpenedChatPopup('Close the deal'));
  };

  const appealRequest = (): void => {
    // TODO: продумать логику appealRequest
  };

  const appeal = (): void => {
    // TODO: продумать логику appeal
  };

  const returnToWork = (): void => {
    dispatch(setCurrentActivityStep(currentStep - 1));
  };

  const confirm = (): void => {
    nextStep();
  };

  const orderActivitySteps = [
    {
      title: 'Order request',
      description: <MessengerOrderInfoActivityDescriptionPattern />,
    },
    {
      title: 'Discuss project details',
      description: !orderInfo.is_executor && (
        <MessengerOrderInfoActivityDescriptionPattern
          description={t(
            'Describe in detail the task and services to be obtained. After a successful discussion, complete the stage',
          )}
          nextBtn={{
            onClick: confirmTerms,
            label: 'Сonfirm terms',
          }}
        />
      ),
    },
    {
      title: !orderInfo.is_executor ? 'Request confirmation' : 'Confirm request',
      description: (
        <MessengerOrderInfoActivityDescriptionPattern
          description={
            !orderInfo.is_executor
              ? t("Please wait for the contractor's confirmation of the terms")
              : t("Review the task and when you are ready to begin, click 'Confirm'")
          }
          nextBtn={{
            onClick: !orderInfo.is_executor ? editRequest : confirm,
            label: !orderInfo.is_executor ? 'Edit request' : 'Confirm',
          }}
          cancelBtn={
            !orderInfo.is_executor && {
              onClick: appealRequest,
              label: 'Cancel',
            }
          }
        />
      ),
    },
    {
      title: 'Task completition',
      description: (
        <MessengerOrderInfoActivityDescriptionPattern
          description={
            !orderInfo.is_executor ? t('Work in progress, please wait for task completion') : ''
          }
          nextBtn={
            orderInfo.is_executor && {
              onClick: nextStep,
              label: 'Finish',
            }
          }
        />
      ),
    },
    {
      title: 'Close the deal',
      description: (
        <MessengerOrderInfoActivityDescriptionPattern
          description={
            !orderInfo.is_executor &&
            t(
              'Verify the work execution according to the given specifications. If successfully completed, finalize the deal. If dissatisfied with the result, press Appeal',
            )
          }
          nextBtn={
            !orderInfo.is_executor
              ? {
                  onClick: finish,
                  label: 'Finish',
                }
              : {
                  onClick: returnToWork,
                  label: 'Return to work',
                }
          }
          cancelBtn={
            !orderInfo.is_executor && {
              onClick: appeal,
              label: 'Appeal',
            }
          }
          descriptionStyle={{ fontFamily: 'Inter-Regular, sans-serif', fontSize: 12 }}
        />
      ),
    },
  ];

  const currentItem = currentStep > 4 ? orderActivitySteps[4] : orderActivitySteps[currentStep];

  return { orderActivitySteps, currentStep, currentItem };
};
