import { Drawer, Modal } from 'antd';
import { QRCode } from 'react-qrcode-logo';
import React, { type FC, useState } from 'react';
import './FinancesDepositModal.scss';
import { FinancesDepositPageNetworkSelector } from '../FinancesDepositPage/FinancesDepositPageNetworkSelector/FinancesDepositPageNetworkSelector';
import { FinancesDepositPageAddressSection } from '../FinancesDepositPage/FinancesDepositPageAddressSection/FinancesDepositPageAddressSection';
import { FinancesDepositPageWarningSection } from '../FinancesDepositPage/FinancesDepositPageWarningSection/FinancesDepositPageWarningSection';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';
import talentixScanCodeIcon from '../../../../../assets/icons/talentixScanCodeIcon.svg';
import { useMediaSize } from '../../../../../hooks/useMediaSize';

interface IProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  walletAddresses: Record<string, string>;
}

export const FinancesDepositModal: FC<IProps> = ({ isOpen, setIsOpen, walletAddresses }) => {
  const [isMobile] = useMediaSize();
  const [chosenNetwork, setChosenNetwork] = useState('TRC20');

  const checkIfMobile = (): boolean => {
    return window.innerWidth <= 641;
  };

  const checkIfTabletMobile = (): boolean => {
    return window.innerWidth <= 768;
  };

  const { t } = useLanguageContext();

  return isMobile ? (
    <>
      <Drawer
        open={isOpen}
        placement={'bottom'}
        onClose={() => {
          setIsOpen(false);
        }}
        closable={true}
        height={checkIfTabletMobile() ? 620 : 390}
        className="ant-drawer-modal-content-deposit"
      >
        <div className="mobile-modal-deposit__content">
          <div className="mobile-modal-deposit__content__header">
            <span>{t('Deposit')}</span>
            <div className="mobile-modal-deposit__content__qr--title">
              <img src={talentixScanCodeIcon} alt="scan-code-qr-icon-camera" />
              <h4>{t('Scan the code or enter address manually')}</h4>
            </div>
          </div>
          <div className="mobile-modal-deposit__content__wrapper">
            <div className="mobile-modal-deposit__content__info">
              <FinancesDepositPageNetworkSelector
                networkName={chosenNetwork}
                changeNetwork={(value) => {
                  setChosenNetwork(value);
                }}
              />

              <FinancesDepositPageAddressSection address={walletAddresses.trc20} />

              <FinancesDepositPageWarningSection />
            </div>
            <div className="mobile-modal-deposit__content__qr">
              <div style={{ position: 'relative', display: 'inline-block', padding: '0px' }}>
                <QRCode
                  value={walletAddresses[chosenNetwork.toLowerCase()]}
                  size={checkIfMobile() ? 180 : 280}
                  ecLevel="M"
                  qrStyle="dots"
                  logoPaddingStyle="circle"
                  eyeRadius={20}
                  bgColor="rgba(248, 248, 248, 1)"
                  style={{ borderRadius: '32px' }}
                />

                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    pointerEvents: 'none',
                    borderRadius: '32px',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      width: '50px',
                      height: '50px',
                      borderLeft: '1px solid rgba(207, 207, 207, 1)',
                      borderTop: '1px solid rgba(207, 207, 207, 1)',
                      borderRadius: '32px 0 0 0',
                      top: 0,
                      left: 0,
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      width: '50px',
                      height: '50px',
                      borderRight: '1px solid rgba(207, 207, 207, 1)',
                      borderTop: '1px solid rgba(207, 207, 207, 1)',
                      borderRadius: '0 32px 0 0',
                      top: 0,
                      right: 0,
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      width: '50px',
                      height: '50px',
                      borderLeft: '1px solid rgba(207, 207, 207, 1)',
                      borderBottom: '1px solid rgba(207, 207, 207, 1)',
                      borderRadius: '0 0 0 32px',
                      bottom: '5px',
                      left: 0,
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      width: '50px',
                      height: '50px',
                      borderRight: '1px solid rgba(207, 207, 207, 1)',
                      borderBottom: '1px solid rgba(207, 207, 207, 1)',
                      borderRadius: '0 0 32px 0',
                      bottom: '5px',
                      right: 0,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  ) : (
    <>
      <Modal
        open={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
        footer={[]}
        centered={!checkIfMobile()}
        style={{
          top: 0.05 * window.innerHeight,
        }}
        width={checkIfMobile() ? '100%' : '730px'}
      >
        <div className="modal-deposit__content">
          <div className="modal-deposit__content__header">
            <span>{t('Deposit')}</span>
            <div className="modal-deposit__content__headerRight">
              <img src={talentixScanCodeIcon} alt="scan-code-qr-icon-camera" />
              <h4>{t('Scan the code or enter address manually')}</h4>
            </div>
          </div>
          <div className="modal-deposit__content__wrapper">
            <div className="modal-deposit__content__info">
              <FinancesDepositPageNetworkSelector
                networkName={chosenNetwork}
                changeNetwork={(value) => {
                  setChosenNetwork(value);
                }}
              />

              <FinancesDepositPageAddressSection address={walletAddresses.trc20} />

              <FinancesDepositPageWarningSection />
            </div>
            <div className="modal-deposit__content__qr">
              <div style={{ position: 'relative', display: 'inline-block', padding: '0px' }}>
                <QRCode
                  value={walletAddresses[chosenNetwork.toLowerCase()]}
                  size={checkIfMobile() ? 180 : 280}
                  ecLevel="M"
                  qrStyle="dots"
                  logoPaddingStyle="circle"
                  eyeRadius={20}
                  bgColor="rgba(248, 248, 248, 1)"
                  style={{ borderRadius: '32px' }}
                />

                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    pointerEvents: 'none',
                    borderRadius: '32px',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      width: '50px',
                      height: '50px',
                      borderLeft: '1px solid rgba(207, 207, 207, 1)',
                      borderTop: '1px solid rgba(207, 207, 207, 1)',
                      borderRadius: '32px 0 0 0',
                      top: 0,
                      left: 0,
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      width: '50px',
                      height: '50px',
                      borderRight: '1px solid rgba(207, 207, 207, 1)',
                      borderTop: '1px solid rgba(207, 207, 207, 1)',
                      borderRadius: '0 32px 0 0',
                      top: 0,
                      right: 0,
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      width: '50px',
                      height: '50px',
                      borderLeft: '1px solid rgba(207, 207, 207, 1)',
                      borderBottom: '1px solid rgba(207, 207, 207, 1)',
                      borderRadius: '0 0 0 32px',
                      bottom: '5px',
                      left: 0,
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      width: '50px',
                      height: '50px',
                      borderRight: '1px solid rgba(207, 207, 207, 1)',
                      borderBottom: '1px solid rgba(207, 207, 207, 1)',
                      borderRadius: '0 0 32px 0',
                      bottom: '5px',
                      right: 0,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
