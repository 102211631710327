import React from 'react';

interface SVGProps {
  width?: string;
  height?: string;
  viewBox?: string;
  fill?: string;
}

const ArrowDownIconSelect: React.FC<SVGProps> = ({
  width = '12',
  height = '12',
  viewBox = '0 0 12 12',
  fill = '#414141',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99877 8.37526C5.80677 8.37526 5.61477 8.30226 5.46877 8.15526L2.21877 4.90526C1.92577 4.61226 1.92577 4.13726 2.21877 3.84426C2.51177 3.55126 2.98677 3.55126 3.27977 3.84426L5.99977 6.56426L8.71978 3.84426C9.01278 3.55126 9.48777 3.55126 9.78078 3.84426C10.0738 4.13726 10.0738 4.61226 9.78078 4.90526L6.53078 8.15526C6.38478 8.30126 6.19277 8.37526 6.00077 8.37526H5.99877Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowDownIconSelect;
