import React, { type FC } from 'react';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';
import SearchIcon from '../../../../../components/SvgIcons/SearchIcon';

interface ChatSearchProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
}

export const ChatsSearch: FC<ChatSearchProps> = ({ searchValue, setSearchValue }) => {
  const { t } = useLanguageContext();
  return (
    <div className="chats__search">
      <input
        type="text"
        value={searchValue}
        className="chats__search-input"
        placeholder={t('Search')}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
        aria-label="Search chats"
      />
      <SearchIcon className={'chats__search-icon'} />
    </div>
  );
};
