import { type FC } from 'react';
import { TagInput } from '../../../TagInput/TagInput';

interface IProps {
  tags: string[];
  setTags: (tags: string[]) => void;
}

export const ModalFilterTags: FC<IProps> = ({ tags, setTags }) => {
  return (
    <div className="modal-filter__content__options__tags">
      <TagInput
        label={'Tags (maximum 10)'}
        maxTags={10}
        onTagsChange={(newTags) => {
          setTags(newTags);
        }}
        placeholder={'Tag'}
      />
    </div>
  );
};
