import React, { type FC } from 'react';
import { type TypesSvgIcon } from './TypesSvgIcon';

const FileIcon: FC<TypesSvgIcon> = ({ color, className }) => {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" className={className || ''}>
      <path
        d="M2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H14C14.5304 20 15.0391 19.7893 15.4142 19.4142C15.7893 19.0391 16 18.5304 16 18V6L10 0H2ZM10 7H9V2L14 7H10Z"
        fill={color || 'white'}
      />
    </svg>
  );
};

export default FileIcon;
