import type { FC } from 'react';
import type { TypesSvgIcon } from './TypesSvgIcon';

const ThunderIcon: FC<TypesSvgIcon> = ({ color, className }) => {
  return (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" className={className}>
      <path
        d="M9.3766 4.96245C9.25429 4.72566 9.0118 4.57774 8.74513 4.57774H5.76842L6.13607 0.934011C6.18229 0.608322 6.00167 0.295433 5.69518 0.173122C5.39011 0.0508105 5.04238 0.153922 4.8518 0.422011L0.675445 6.29934C0.521134 6.51765 0.501934 6.80068 0.624245 7.03748C0.746556 7.27428 0.989045 7.42219 1.25571 7.42219H4.23242L3.86478 11.0659C3.81856 11.3916 3.99918 11.7045 4.30567 11.8268C4.39171 11.8609 4.48131 11.878 4.5702 11.878C4.79491 11.878 5.01251 11.7706 5.14904 11.5779L9.32611 5.70059C9.48042 5.48228 9.49891 5.19925 9.3766 4.96245Z"
        fill={color || '#A0A0A0'}
      />
    </svg>
  );
};

export default ThunderIcon;
