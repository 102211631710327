import React, { type FC, useEffect, useState } from 'react';
import BrowseSearchActionButton from './BrowseSearchActionButton/BrowseSearchActionButton';
import { type IProfile } from '../../../../../interfaces/api/profile';
import { apiProfile } from '../../../../../services/api/legacy/profile';
import { apiReviews } from '../../../../../services/api/legacy/reviews';
import SearchInputIcon from '../../../../../assets/icons/browse_search_input_icon.png';
import SearchFilterIcon from '../../../../../assets/icons/filter-icon.png';
import { Select } from 'antd';

import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';
import { useParams } from 'react-router-dom';

interface IProps {
  setFoundProfiles: (profiles: IProfile[] | null) => void;
  setLoadingState: (state: boolean) => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  setFilterIsOpen: () => void;
  sortBy: string;
  setSortBy: (value: string) => void;
}

export const BrowseSearch: FC<IProps> = ({
  setFoundProfiles,
  setLoadingState,
  searchValue,
  setSearchValue,
  setFilterIsOpen,
  sortBy,
  setSortBy,
}) => {
  // hooks
  const { t } = useLanguageContext();
  const { category } = useParams();
  const [sortItems, setSortItems] = useState([
    {
      value: 'recommended',
      label: t('Recommended'),
    },
    {
      value: 'rated',
      label: t('Highly rated'),
    },
  ]);

  useEffect(() => {
    if (category === 'services') {
      sortItems.push();
      sortItems.push();
      setSortItems([
        ...sortItems,
        {
          value: 'cheaper',
          label: t('Cheaper'),
        },
        {
          value: 'expensive',
          label: t('More expensive'),
        },
      ]);
    } else {
      setSortItems([
        {
          value: 'recommended',
          label: t('Recommended'),
        },
        {
          value: 'rated',
          label: t('Highly rated'),
        },
      ]);
    }
  }, [category]);

  // Fetch data when search is changes
  useEffect(() => {
    const controller = new AbortController();

    if (category === 'people') {
      !searchValue
        ? setLoadingState(false)
        : apiProfile.searchProfileByName(searchValue, controller.signal).then((r) => {
            setLoadingState(true);
            if (r) {
              const addReviewsInfo = new Promise((resolve) => {
                if (r.profiles.length > 0) {
                  r.profiles.forEach((profile, index) => {
                    apiReviews.getRating(profile.user_id).then((reviews) => {
                      if (reviews) {
                        profile.rank = reviews.rank;
                        profile.reviewsCount = reviews.count;
                        index === r.profiles.length - 1 && resolve(r.profiles);
                      }
                    });
                  });
                } else {
                  setFoundProfiles([]);
                  setLoadingState(false);
                }
              });
              addReviewsInfo.then(() => {
                setFoundProfiles(r.profiles);
                setLoadingState(false);
              });
            } else {
              setFoundProfiles([]);
              setLoadingState(false);
              throw new Error('Error searching profiles');
            }
          });
    }

    return () => {
      controller.abort();
    };
  }, [searchValue]);

  return (
    <section className="browse-search__container">
      <div className="browse-search__action-group">
        <div className="browse-search__search-wrapper">
          <div className="browse-search__search-content">
            <input
              type="text"
              className="browse-search__search-text"
              placeholder={t(`Search ${category.toLowerCase()}`)}
              aria-label="Search"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
            <div className="browse-search__icon-wrapper">
              <img loading="lazy" src={SearchInputIcon} alt="" className="browse-search__icon" />
            </div>
          </div>
        </div>
        <BrowseSearchActionButton
          onClick={setFilterIsOpen}
          icon={SearchFilterIcon as string}
          text="Filters"
        />
        <Select
          placeholder="Borderless"
          variant="borderless"
          options={sortItems}
          style={{ height: 'unset', width: 150 }}
          defaultValue={sortBy}
          onChange={(value) => {
            setSortBy(value);
          }}
        ></Select>
      </div>
    </section>
  );
};
