import { ApiServiceBase } from '../../apiServiceBase';
import { type IGetOrdersResponse, type IJob } from '../../../interfaces/api/packageProcess';

class ApiPackageProcess extends ApiServiceBase {
  protected baseUrl = 'package_process/';

  public buyPackage = async (name: string, id: string): Promise<boolean> => {
    try {
      const response = await this.post('buy_package_service', {
        executor_id: id,
        package_name: name,
      });
      return response.status === 200;
    } catch (err) {
      err && console.log(err);
      return false;
    }
  };

  public getOrder = async (id: string): Promise<IJob> => {
    try {
      const response = await this.get<IJob>('get_order', {
        query: {
          chat_id: id,
        },
      });

      return response ?? null;
    } catch (err) {
      err && console.log(err);
      return null;
    }
  };

  public getOrders = async (): Promise<IGetOrdersResponse> => {
    try {
      const response = await this.get<IGetOrdersResponse>('get_orders');

      return response ?? null;
    } catch (err) {
      err && console.log(err);
      return null;
    }
  };

  public updateOrderStatus = async (order: Record<string, string>): Promise<boolean> => {
    try {
      const response = await this.post('update_order_status', order);
      return response.status === 200;
    } catch (err) {
      err && console.log(err);
      return false;
    }
  };
}
export const apiPackageProcess = new ApiPackageProcess();
