import React, { type FC } from 'react';
import MessageIcon from '../../../../../../assets/icons/messages_icon.png';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';
import { Popconfirm, message } from 'antd';
import { apiPackageProcess } from '../../../../../../services/api/legacy/packageProcess';
import { type IJob } from '../../../../../../interfaces/api/packageProcess';

interface IProps {
  buttonText: string;
  allowChangeStatus: boolean;
  order: IJob;
  setOrder: (order: any) => void;
}

// All job statuses
const statuses = [
  'DiscussionTP',
  'WaitingClientTPConfirm',
  'WaitingExecutorTPConfirm',
  'WaitingExecutorTPComplet',
  'WaitingClientOrderConfirm',
  'ClientAppeal',
  'Completed',
];

export const MessengerHeaderActionButton: FC<IProps> = ({
  buttonText,
  allowChangeStatus,
  order,
  setOrder,
}) => {
  // hooks
  const { t } = useLanguageContext();

  return (
    <Popconfirm
      title={t('Status change')}
      description={t('Are you sure you want to change order status?')}
      onConfirm={() => {
        const currentStatusIndex = statuses.indexOf(order.status);
        const newStatus = currentStatusIndex === 4 ? 'Completed' : statuses[currentStatusIndex + 1];
        const updatedOrder = {
          client_id: order.client_id,
          executor_id: order.executor_id,
          package_name: order.package_name,
          new_status: newStatus,
        };
        apiPackageProcess.updateOrderStatus(updatedOrder).then((r) => {
          r &&
            setOrder((prev: IJob) => {
              return { ...prev, status: newStatus };
            });
          r && message.success(t('Success'));
          !r && message.error(t('Something went wrong. Please try again'));
        });
      }}
    >
      <button
        className="messenger__header__action-button"
        disabled={!allowChangeStatus}
        style={{
          cursor: !allowChangeStatus && 'not-allowed',
          opacity: !allowChangeStatus && '0.5',
        }}
      >
        <img src={MessageIcon as string} alt="" className="messenger__header__action-icon" />
        <span className="messenger__header__action-text">{t(buttonText)}</span>
      </button>
    </Popconfirm>
  );
};
