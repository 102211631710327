import React from 'react';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';

interface ActionButtonProps {
  icon: string;
  text: string;
  onClick?: () => void;
}

const ActionButton: React.FC<ActionButtonProps> = ({ icon, text, onClick }) => {
  // hooks
  const { t } = useLanguageContext();

  return (
    <div className="browse-search__action-button" onClick={onClick}>
      <div className="browse-search__action-button-content">
        <div className="browse-search__action-button-text">{t(text)}</div>
        <div className="browse-search__icon-wrapper">
          <img loading="lazy" src={icon} alt="" className="browse-search__icon" />
        </div>
      </div>
    </div>
  );
};

export default ActionButton;
