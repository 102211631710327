import { type FC } from 'react';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';

export const SettingsTitle: FC = () => {
  // hooks
  const { t } = useLanguageContext();

  return (
    <div className="settings__title">
      <h1>{t('Website settings')}</h1>
    </div>
  );
};
