import './Profile.scss';
import React, { type FC, useEffect, useState } from 'react';
import { useSelector } from '../../../../redux/rootReducer';
import Modal from 'react-modal';
import { apiProfile } from '../../../../services/api/legacy/profile';
import { apiPackage } from '../../../../services/api/legacy/packages';
import { useDispatch } from 'react-redux';
import { setProfile } from '../../../../redux/profile/reducer';
import { useParams } from 'react-router-dom';
import { apiHashTag } from '../../../../services/api/legacy/hashtag';
import { apiReviews } from '../../../../services/api/legacy/reviews';
import { LoadingScreen } from '../../../../components/LoadingScreen/LoadingScreen';
import { apiAuth } from '../../../../services/api/auth';
import { PageInfoHeader } from '../../../../components/PageInfoHeader/PageInfoHeader';
import { ProfileMainInfo } from './ProfileMainInfo/ProfileMainInfo';
import { ProfileServicesInfo } from './ProfileServicesInfo/ProfileServicesInfo';
import { ProfileReviewsInfo } from './ProfileReviewsInfo/ProfileReviewsInfo';
import { ProfilePortfolioInfo } from './ProfilePortfolioInfo/ProfilePortfolioInfo';
import { ProfileReviewsPage } from './ProfileReviewsPage/ProfileReviewsPage';
import { ProfileServicesPage } from './ProfileServicesPage/ProfileServicesPage';
import { ModalOrderRequest } from '../../../../components/Modals/ModalOrderRequest/ModalOrderRequest';
import { type IJob } from '../../../../interfaces/api/packageProcess';
import { getRandomDate } from '../../../../services/dateServices/getRandomDate';

// Styles for modal windows
// const customStyles = {
//   editProfile: {
//     content: {
//       top: '50%',
//       left: '50%',
//       right: 'auto',
//       bottom: 'auto',
//       marginRight: '-50%',
//       transform: 'translate(-50%, -50%)',
//       minWidth: '20vw',
//       minHeight: '65vh',
//     },
//   },
//   addService: {
//     content: {
//       top: '50%',
//       left: '50%',
//       right: 'auto',
//       bottom: 'auto',
//       marginRight: '-50%',
//       transform: 'translate(-50%, -50%)',
//       minWidth: '25vw',
//       minHeight: '450px',
//     },
//   },
//   placeOrder: {
//     content: {
//       top: '50%',
//       left: '50%',
//       right: 'auto',
//       bottom: 'auto',
//       marginRight: '-50%',
//       transform: 'translate(-50%, -50%)',
//       minWidth: '350px',
//       maxWidth: '90vw',
//       minHeight: '250px',
//     },
//   },
//   editText: {
//     content: {
//       top: '50%',
//       left: '50%',
//       right: 'auto',
//       bottom: 'auto',
//       marginRight: '-50%',
//       transform: 'translate(-50%, -50%)',
//       minWidth: '5vw',
//       maxWidth: '65vw',
//       minHeight: '30vh',
//     },
//   },
//   addHashTag: {
//     content: {
//       top: '50%',
//       left: '50%',
//       right: 'auto',
//       bottom: 'auto',
//       marginRight: '-50%',
//       transform: 'translate(-50%, -50%)',
//       minWidth: '20vw',
//       maxWidth: '80vw',
//       minHeight: '30vh',
//     },
//   },
//   aboutMe: {
//     content: {
//       top: '50%',
//       left: '50%',
//       right: 'auto',
//       bottom: 'auto',
//       marginRight: '-50%',
//       transform: 'translate(-50%, -50%)',
//       minWidth: '10vw',
//       maxWidth: '60vw',
//       minHeight: '30vh',
//     },
//   },
// };

// const modalsOpenInitialState = {
//   editProfile: false,
//   addService: false,
//   placeOrder: false,
//   editText: false,
//   addHashTag: false,
//   aboutMe: false,
//   editService: false,
// };

// Reducer for modal windows 'opened' status
// const modalsOpenReducer = (
//   state: Record<string, boolean>,
//   action: PayloadAction<boolean>,
// ): Record<string, unknown> => {
//   switch (action?.type) {
//     case 'setEditProfileOpen':
//       return { ...state, editProfile: action.payload };
//     case 'setAddServiceOpen':
//       return { ...state, addService: action.payload };
//     case 'setPlaceOrderOpen':
//       return { ...state, placeOrder: action.payload };
//     case 'setEditTextOpen':
//       return { ...state, editText: action.payload };
//     case 'setAddHashTagOpen':
//       return { ...state, addHashTag: action.payload };
//     case 'setAboutMeOpen':
//       return { ...state, aboutMe: action.payload };
//     case 'setEditServiceOpen':
//       return { ...state, editService: action.payload };
//     default:
//       return state;
//   }
// };

interface ProfileProps {
  isMyProfile: boolean;
  headerPath?: string[];
  headerIcon?: string;
  headerPage?: string;
}

export const Profile: FC<ProfileProps> = ({ isMyProfile, headerPath, headerIcon, headerPage }) => {
  // hooks
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const { t } = useLanguageContext();
  const { login, page } = useParams();

  // redux
  const profileState = useSelector((state) => state.profile);

  // reducers
  // const [modalOpenState, dispatchModalOpen] = useReducer(modalsOpenReducer, modalsOpenInitialState);

  // states
  // const [modalEditText, setModalEditText] = useState('');
  const [, setAvailableHashTags] = useState([]);
  const [, setMyHashTags] = useState([]);
  const [myProfileInfo, setMyProfileInfo] = useState(profileState.myProfile);
  const [packages, setPackages] = useState([]);
  const [, setProf] = useState(profileState.profile);
  // const [allowPurchase, setAllowPurchase] = useState(false);
  const [, setRank] = useState('');
  const [, setReviewsCount] = useState('');
  const [allowRender, setAllowRender] = useState(false);
  const [id, setId] = useState('');
  const [modalOrderIsOpen, setModalOrderIsOpen] = useState(false);
  const [, setChosenPackage] = useState<IJob>(null);

  // My user id from store
  const myUserId = useSelector((state) => state.auth.user.user_id);

  // important variables
  if (!isMyProfile) {
    if (!id) {
      apiAuth.getUserId(login).then((r) => {
        setId(r?.[0]);
      });
    }
  } else {
    !id && setId(myUserId);
  }

  // Enable modals
  Modal.setAppElement('#root');

  // Css variable for mobile devices
  document.documentElement.style.setProperty('--dialog-opened', 'none');

  // Fetch profile, packages and rating
  useEffect(() => {
    if (id) {
      if (!isMyProfile) {
        const getUserProfile = (): void => {
          apiProfile.getProfile(login).then((profile) => {
            if (profile) {
              dispatch(setProfile(profile));
              setProf(profile);
              apiPackage.getPackages(id).then((r) => {
                r && setPackages(r.packages);
                setAllowRender(true);
              });
            } else {
              setTimeout(getUserProfile, 1000);
            }
          });
        };
        getUserProfile();
      }
      apiReviews.getRating(id).then((r) => {
        setRank(r?.rank.toString());
        setReviewsCount(r?.count.toString());
      });
    }
  }, [id]);

  // Handle open chat with user
  // const handleMessageUser = (): void => {
  //   apiChats.get_chats().then((arr) => {
  //     if (arr) {
  //       const chat = arr.find((c) => c.user_id === id);
  //       if (chat) {
  //         document.documentElement.style.setProperty('--dialog-opened', 'none');
  //         navigate(`/chats/?id=${chat.chat_id}`);
  //       } else {
  //         apiChats.createChat(prof.user_id, prof.name).then((r) => {
  //           if (r) {
  //             apiChats.get_chats().then(() => {
  //               navigate(`/chats?id=${r.chat_id}`);
  //               document.documentElement.style.setProperty('--dialog-opened', 'none');
  //             });
  //           }
  //         });
  //       }
  //     }
  //   });
  // };

  // Fetch users profile info
  useEffect(() => {
    if (isMyProfile && profileState.myProfile) {
      setMyHashTags(profileState.myProfile.hashtags ?? []);
      apiHashTag.getHashTags().then((r) => {
        setAvailableHashTags(r.hashtags);
      });
      apiPackage.getPackages(id).then((r) => {
        r && setPackages(r.packages);
        setAllowRender(true);
      });
    }
    setMyProfileInfo(profileState.myProfile);
  }, [profileState.myProfile, id]);

  if (
    !allowRender ||
    (!isMyProfile && !profileState.profile) ||
    (isMyProfile && !profileState.myProfile) ||
    !myProfileInfo
  ) {
    return (
      <>
        <LoadingScreen />
      </>
    );
  }

  return (
    <>
      <ModalOrderRequest
        modalType={'Create'}
        isOpen={modalOrderIsOpen}
        setIsOpen={(value) => {
          setModalOrderIsOpen(value);
        }}
      />

      <PageInfoHeader
        page={isMyProfile ? 'Profile' : headerPage}
        additionalText={!isMyProfile ? headerPath : null}
      />

      <div className="user-profile">
        {/* Main profile info */}
        <ProfileMainInfo
          profile={isMyProfile ? profileState.myProfile : profileState.profile}
          isMyProfile={isMyProfile}
        />

        {/* Container for services, portfolio etc */}
        <div className="user-profile__content-section">
          {!page ? (
            <>
              <ProfileServicesInfo
                packages={packages}
                openOrderRequestModal={(service) => {
                  setModalOrderIsOpen(true);
                  setChosenPackage(service);
                }}
              />
              <ProfileReviewsInfo
                reviews={[
                  {
                    id: '0',
                    stars:
                      'https://cdn.builder.io/api/v1/image/assets/TEMP/1cccf7a270051fdc530f673c1807c675d2408d63626b90869c08c9f147d6ca1e?placeholderIfAbsent=true&apiKey=4806e4dcc8d34e9a84cfbb788e512e8d',
                    name: 'John',
                    date: getRandomDate(),
                    content:
                      'Above all, his quality of work is so good. He developed a pixel perfect version of the design. On top of that, he was super responsive, attentive, and hit all of the milestones!',
                  },
                  {
                    id: '1',
                    stars:
                      'https://cdn.builder.io/api/v1/image/assets/TEMP/1cccf7a270051fdc530f673c1807c675d2408d63626b90869c08c9f147d6ca1e?placeholderIfAbsent=true&apiKey=4806e4dcc8d34e9a84cfbb788e512e8d',
                    name: 'Will',
                    date: getRandomDate(),
                    content:
                      "10/10 recommend working with him. - Great communicator: I always knew where things stood, timelines, etc. - Talented Framer dev: I made a bunch of technical animation requests, he nailed them all. - High sense of ownership: He volunteered to clean up some of my Figma designs before we got started, just to make sure everything was looking great. - Super flexible: I made a few design changes late in the process and he rolled with it. TL;DR: Hire Jithin, you won't be sorry!",
                  },
                  {
                    id: '2',
                    stars:
                      'https://cdn.builder.io/api/v1/image/assets/TEMP/1cccf7a270051fdc530f673c1807c675d2408d63626b90869c08c9f147d6ca1e?placeholderIfAbsent=true&apiKey=4806e4dcc8d34e9a84cfbb788e512e8d',
                    name: 'John',
                    date: getRandomDate(),
                    content:
                      'Above all, his quality of work is so good. He developed a pixel perfect version of the design. On top of that, he was super responsive, attentive, and hit all of the milestones!',
                  },
                  {
                    id: '3',
                    stars:
                      'https://cdn.builder.io/api/v1/image/assets/TEMP/1cccf7a270051fdc530f673c1807c675d2408d63626b90869c08c9f147d6ca1e?placeholderIfAbsent=true&apiKey=4806e4dcc8d34e9a84cfbb788e512e8d',
                    name: 'Will',
                    date: getRandomDate(),
                    content:
                      "10/10 recommend working with him. - Great communicator: I always knew where things stood, timelines, etc. - Talented Framer dev: I made a bunch of technical animation requests, he nailed them all. - High sense of ownership: He volunteered to clean up some of my Figma designs before we got started, just to make sure everything was looking great. - Super flexible: I made a few design changes late in the process and he rolled with it. TL;DR: Hire Jithin, you won't be sorry!",
                  },
                ]}
                rating={4.2}
              />
              <ProfilePortfolioInfo />
            </>
          ) : page === 'reviews' ? (
            <ProfileReviewsPage />
          ) : (
            page === 'services' && <ProfileServicesPage services={packages} />
          )}
        </div>
      </div>
    </>
  );
};
