import React, { type FC, useState } from 'react';
import './ModalOrderRequest.scss';
import { Modal } from 'antd';
import { useSelector } from '../../../redux/rootReducer';

interface AmountButtonProps {
  value: string;
  onClick: () => void;
}

// Amount option button
const AmountButton: FC<AmountButtonProps> = ({ value, onClick }) => {
  return (
    <button className="modal-withdraw__content__amount__button" onClick={onClick}>
      ${value}
    </button>
  );
};

interface IProps {
  modalType: 'Create' | 'Edit';
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const ModalOrderRequest: FC<IProps> = ({ isOpen, setIsOpen, modalType }) => {
  // states
  const [requestTitle, setRequestTitle] = useState('');
  const [cutoffDate, setCutoffDate] = useState('');
  const [offeredAmount, setOfferedAmount] = useState('');

  // Options of amounts to offer
  const myBalance = (useSelector((state) => state.profile.myProfile.balance) / 100).toString();
  const withdrawAmounts = [myBalance, '100', '500', '1000'];

  // check if screen width is bigger than 641px
  const checkIfMobile = (): boolean => {
    return window.innerWidth <= 641;
  };

  // Footer confirm button
  const footerButton = (): React.ReactElement => (
    <button
      disabled={
        !requestTitle ||
        !cutoffDate ||
        !offeredAmount ||
        parseFloat(offeredAmount) > parseFloat(myBalance)
      }
      className="modal-order__footer-btn"
      key={'confirm button'}
    >
      {modalType === 'Create' ? 'Send request' : 'Edit request'}
    </button>
  );

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
        footer={[footerButton()]}
        centered
        width={checkIfMobile() ? '100%' : '400px'}
      >
        <div className="modal-order__content">
          <div className="modal-order__content__header">
            <span>{modalType === 'Create' ? 'Order request' : 'Edit request'}</span>
          </div>
          <div className="modal-order__content__wrapper">
            <div className="modal-order__input">
              <span className="modal-order__input__label">Title</span>
              <input
                className="modal-order__input__field"
                type="text"
                placeholder="Title of your request"
                value={requestTitle}
                onChange={(e) => {
                  setRequestTitle(e.target.value);
                }}
              />
            </div>

            <div className="modal-order__input">
              <span className="modal-order__input__label">Cutoff date</span>
              <input
                className="modal-order__input__field"
                type="date"
                placeholder="DD/MM/YYYY"
                value={cutoffDate}
                onChange={(e) => {
                  setCutoffDate(e.target.value);
                }}
              />
            </div>

            <div className="modal-order__content__amount">
              <div className="modal-order__content__amount__input-wrapper">
                $
                <input
                  type="number"
                  className="modal-order__content__amount__input"
                  placeholder="Amount"
                  value={offeredAmount}
                  onChange={(e) => {
                    setOfferedAmount(e.target.value);
                  }}
                />
              </div>
              <div className="modal-order__content__amount__button-container">
                {withdrawAmounts.map((amount: string, index) => (
                  <AmountButton
                    value={amount}
                    key={index}
                    onClick={() => {
                      setOfferedAmount(amount.toString());
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
