import { type FC } from 'react';
import { ModalFilterRatingButton } from './ModalFilterRatingButton/ModalFilterRatingButton';

interface IProps {
  chosenRating: number;
  setChosenRating: (value: number) => void;
}

const ratingOptions = [4.9, 4.5, 4.0, 3.5, 3.0];

export const ModalFilterRating: FC<IProps> = ({ chosenRating, setChosenRating }) => {
  return (
    <div className="modal-filter__content__options__rating">
      <h4>Rating</h4>
      <div className="modal-filter__content__options__rating__container">
        {ratingOptions.map((value, index) => (
          <ModalFilterRatingButton
            onClick={() => {
              setChosenRating(index);
            }}
            key={index}
            rating={value}
            isSelected={chosenRating === index}
          />
        ))}
      </div>
    </div>
  );
};
