import { type FC } from 'react';
import { Select } from 'antd';
import { setLanguage } from '../../../../../redux/settings/reducer';
import { useDispatch } from 'react-redux';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';

interface IProps {
  chosenLanguage: string;
}

export const SettingsLanguageSelect: FC<IProps> = ({ chosenLanguage }: IProps) => {
  // hooks
  const dispatch = useDispatch();
  const { t } = useLanguageContext();

  return (
    <div className="settings__language-select">
      <h3>{t('Select a language')}</h3>
      <div className="settings__language-select__els">
        <Select
          value={chosenLanguage}
          onChange={(value) => {
            localStorage.setItem('language', value);
            dispatch(setLanguage(value));
          }}
          options={[
            { value: 'en', label: 'English' },
            { value: 'ru', label: 'Русский' },
          ]}
        />
      </div>
    </div>
  );
};
