import React, { type FC, useState } from 'react';
import { Components, type IAuthFormProps } from '../../Auth';
import WebsiteLogo from '../../../../assets/icons/00x-logo.png';
import { AuthInput } from '../AuthInput/AuthInput';
import { AuthButton } from '../AuthButton/AuthButton';
import { AuthDivider } from '../AuthDivider/AuthDivider';

export const AuthResetPassword: FC<IAuthFormProps> = ({ setComponent }) => {
  // states
  const [email, setEmail] = useState('');
  const [emailCode, setEmailCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [processStep, setProcessStep] = useState(0);
  const [showEmailInputError, setShowEmailInputError] = useState(false);
  const [showCodeInputError, setShowCodeInputError] = useState(false);

  // Handle login check
  const handleLoginCheck = (login: string): void => {
    if (Math.random() > 0.7) {
      setShowEmailInputError(true);
    } else {
      console.log(login);
      setProcessStep(1);
    }
  };

  // Handle code check
  const handleCodeCheck = (code: string): void => {
    if (Math.random() > 0.7) {
      setShowCodeInputError(true);
    } else {
      console.log(code);
      setProcessStep(2);
    }
  };

  // Handle submit
  const handleSubmit = (): void => {
    alert('Submit');
  };

  return (
    <main className="auth-reset">
      <div className="auth-reset__logo">
        <img src={WebsiteLogo} alt="" />
        Club
      </div>
      <div className="auth-reset__title">
        <h1>Reset password</h1>
        <h4>
          {processStep === 2 ? 'Set new password' : 'We will send verification code to your inbox'}
        </h4>
      </div>
      <form
        action="#"
        method="POST"
        className="auth-reset__form"
        onSubmit={(event) => {
          event.preventDefault();

          handleSubmit();
        }}
      >
        {processStep === 0 && (
          <>
            <AuthInput
              type="email"
              placeholder="Enter email"
              aria-label="email"
              value={email}
              onChange={(e) => {
                showEmailInputError && setShowEmailInputError(false);
                setEmail(e.target.value);
              }}
              className="auth-reset__input"
              style={{
                border: showEmailInputError && '1px solid rgba(255, 59, 48, 1)',
              }}
            />
            <span
              className="auth-reset__input__error-msg"
              style={{
                display: !showEmailInputError && 'none',
              }}
            >
              Account is not found
            </span>
          </>
        )}

        {processStep === 1 && (
          <>
            <AuthInput
              type="text"
              placeholder="Enter verification code"
              aria-label="code"
              value={emailCode}
              onChange={(e) => {
                showCodeInputError && setShowCodeInputError(false);
                setEmailCode(e.target.value);
              }}
              className="auth-reset__input"
              style={{
                border: showCodeInputError && '1px solid rgba(255, 59, 48, 1)',
              }}
            />
            <span
              className="auth-reset__input__error-msg"
              style={{
                display: !showCodeInputError && 'none',
              }}
            >
              Incorrect code
            </span>
          </>
        )}

        {processStep === 2 && (
          <>
            <AuthInput
              type="password"
              placeholder="Password"
              autoComplete="off"
              aria-label="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            ></AuthInput>

            <AuthInput
              style={{
                marginTop: '16px',
              }}
              disabled={!password}
              type="password"
              placeholder="Confirm password"
              autoComplete="off"
              aria-label="confirm password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            ></AuthInput>
          </>
        )}

        <div className="auth-reset__button-container">
          <AuthButton
            type={processStep === 2 ? 'submit' : 'button'}
            onClick={() => {
              processStep === 0 && handleLoginCheck(email);
              processStep === 1 && handleCodeCheck(emailCode);
            }}
            disabled={
              processStep === 0
                ? !email
                : processStep === 1
                  ? !emailCode
                  : processStep === 2 &&
                    (!password || !confirmPassword || password !== confirmPassword)
            }
          >
            Continue
          </AuthButton>
          {processStep === 1 && (
            <>
              <AuthButton
                style={{
                  marginTop: '16px',
                }}
                variant="secondary"
                onClick={() => {}}
              >
                Resend
              </AuthButton>
            </>
          )}
          <AuthDivider />
          <AuthButton
            variant="secondary"
            onClick={() => {
              setComponent(Components.LOGIN);
            }}
          >
            Back to log in
          </AuthButton>
        </div>
      </form>
    </main>
  );
};
