import React, { type FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../redux/rootReducer';
import {
  setCurrentActivityStep,
  setCurrentOpenedChatPopup,
} from '../../../../../../../redux/messenger/reducer';
import Popup from '../../../../../../../components/Popup/Popup';
import InfoIcon from '../../../../../../../components/SvgIcons/InfoIcon';
import PriceInput from '../../../../../../../components/Inputs/PriceInput/PriceInput';
import { DatePicker } from 'antd';
import CalendarIcon from '../../../../../../../components/SvgIcons/CalendarIcon';
import { useLanguageContext } from '../../../../../../../components/LanguageContext/LanguageContext';

const MessengerOrderInfoEditRequestPopup: FC = () => {
  // hooks
  const dispatch = useDispatch();
  const { t } = useLanguageContext();

  // redux
  const currentOpenedChatPopup = useSelector((state) => state.messenger.currentOpenedChatPopup);
  const orderInfo = useSelector((state) => state.messenger.orderInfo);

  // state
  const [price, setPrice] = useState('');
  const [date, setDate] = useState('');

  const changePrice = useCallback((value: string) => {
    setPrice(value);
  }, []);

  const onClose = (): void => {
    dispatch(setCurrentOpenedChatPopup(null));
  };

  const onSubmit = (): void => {
    dispatch(setCurrentActivityStep(3));
    const data = {
      date,
      price,
    };
    console.log(data);
    onClose();
    // TODO: прописать логику onSubmit
  };

  return (
    <Popup
      isOpen={currentOpenedChatPopup === 'Edit request'}
      onClose={onClose}
      btns={{
        right: { onClick: onSubmit, label: 'Save changes' },
      }}
      centered
      title={{
        label: 'Edit request',
        style: { fontFamily: 'Inter-Bold, sans-serif', fontSize: 27 },
      }}
      isDrawerMobile
    >
      <div className="modal-messenger">
        <div className="modal-messenger__available">
          <InfoIcon />
          <div className="modal-messenger__subtitle">{t('Available balance')}: $230,03</div>
        </div>
        <div className="modal-messenger__input_wrap">
          <div className="modal-messenger__subtitle">{t('Title')}</div>
          <div className="modal-messenger__input_wrap__input">{orderInfo.name}</div>
        </div>
        <div className="modal-messenger__input_wrap">
          <div className="modal-messenger__subtitle">{t('Cutoff date')}</div>
          <DatePicker
            style={{ width: '100%' }}
            onChange={setDate}
            rootClassName="modal-messenger__input_wrap__input-datePicker"
            suffixIcon={<CalendarIcon color={'#000'} />}
            placeholder={'YYYY-MM-DD'}
          />
        </div>
        <div className="modal-messenger__input_wrap">
          <PriceInput setValue={changePrice} value={price} />
        </div>
      </div>
    </Popup>
  );
};

export default MessengerOrderInfoEditRequestPopup;
