import { type FC, useEffect, useState } from 'react';
import './Settings.scss';
import { useSelector } from '../../../../redux/rootReducer';
import { SettingsLanguageSelect } from './SettingsLanguageSelect/SettingsLanguageSelect';
import { SettingsTitle } from './SettingsTitle/SettingsTitle';

export const Settings: FC = () => {
  // redux
  const settingsState = useSelector((state) => state.settings);

  // states
  const [chosenLanguage, setChosenLanguage] = useState<string>('');

  // Check chosen language
  useEffect(() => {
    setChosenLanguage(settingsState.language);
  }, [settingsState.language]);

  return (
    <div className="settings">
      {/* Page title */}
      <SettingsTitle />

      {/* Language selector */}
      {chosenLanguage && <SettingsLanguageSelect chosenLanguage={chosenLanguage} />}
    </div>
  );
};
