import React, { type FC } from 'react';
import { Drawer, Modal } from 'antd';
// @ts-expect-error
import s from './Popup.module.scss';
import PopupContent from './PopupContent/PopupContent';
import { type IPopup } from './types';
import { useWindowWidth } from '../../hooks/useWindowWidth';

const Popup: FC<IPopup> = ({
  isOpen,
  onClose,
  children,
  btns,
  centered,
  width,
  title,
  description,
  classNames,
  isDrawerMobile,
}) => {
  // hooks
  const { isMobile, width: windowWidth } = useWindowWidth();

  if (isDrawerMobile && isMobile) {
    return (
      <Drawer
        placement={'bottom'}
        closable={false}
        onClose={onClose}
        open={isOpen}
        key={'bottom'}
        classNames={{ content: classNames?.content || s.modal, body: s.drawerBody }}
        width={windowWidth}
        height={'461px'}
      >
        <PopupContent title={title} description={description} btns={btns}>
          {children}
        </PopupContent>
      </Drawer>
    );
  }

  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        onClose();
      }}
      footer={null}
      centered={centered}
      classNames={{ content: classNames?.content || s.modal }}
      style={{ borderRadius: 16, overflow: 'hidden' }}
      width={width || 376}
      closeIcon={null}
      height={'max-content'}
    >
      <PopupContent title={title} description={description} btns={btns}>
        {children}
      </PopupContent>
    </Modal>
  );
};

export default Popup;
