import React, { type FC } from 'react';
import MenuIcon from '../../../../../../assets/icons/menu-icon.png';

export const MessengerHeaderMessageButton: FC = () => {
  return (
    <button className="messenger__header__message-button" aria-label="Send message">
      <img src={MenuIcon as string} alt="" className="messenger__header__message-icon" />
    </button>
  );
};
