import React, { type FC, useState } from 'react';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';
import { apiMessenger } from '../../../../../services/api/messenger';
import AttachIcon from '../../../../../assets/icons/messenger-footer-attach-icon.png';
import { Loader } from '../../../../../components/Loader/Loader';
import { onImgValidation } from '../../../../../services/onImgValidation/onImgValidation';
import CloseIcon from '../../../../../components/SvgIcons/CloseIcon';
import FileIcon from '../../../../../components/SvgIcons/FileIcon';

interface MessengerFooterProps {
  chatId: string;
}

export const MessengerFooter: FC<MessengerFooterProps> = ({ chatId }) => {
  // hooks
  const { t } = useLanguageContext();

  // states
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [imgs, setImgs] = useState<File[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const handleSendMessage = (e?: React.FormEvent<HTMLFormElement>): void => {
    e?.preventDefault();
    if (loading) {
      return null;
    }
    if (message.trim() || imgs.length > 0 || files.length > 0) {
      setLoading(true);
      apiMessenger
        .sendMessage(chatId, message, imgs, files)
        .then((res) => {
          if (res) {
            setMessage('');
            setImgs([]);
            setFiles([]);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      setMessage((prevMessage) => prevMessage + '\n'); // Добавляем перенос строки
    } else if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const pickFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files[0]; // Берем первый файл из input

    if (file) {
      const isImg = onImgValidation(file);

      if (isImg) {
        setImgs([file]);
      } else {
        setFiles([file]);
      }
    }
  };

  const deleteFile = (index: number): void => {
    setImgs(null);
    setFiles(null);
  };

  return (
    <section className="messenger__footer">
      <form onSubmit={handleSendMessage}>
        {imgs?.map((img, index) => (
          <div className="messenger__footer__img-wrap" key={index}>
            <div
              className="messenger__footer__img-wrap__closeIcon"
              onClick={() => {
                deleteFile(index);
              }}
            >
              <CloseIcon color={'#3a3a3a'} />
            </div>
            <img src={URL.createObjectURL(img)} alt="img" className="messenger__footer__img" />
          </div>
        ))}
        {files?.map((file, index) => (
          <div className="messenger__footer__file-wrap" key={index}>
            <div className="messenger__footer__file-wrap__file">
              <div
                className="messenger__footer__file-wrap__file__closeIcon"
                onClick={() => {
                  deleteFile(index);
                }}
              >
                <CloseIcon color={'#3a3a3a'} />
              </div>
              <FileIcon color={'#000'} />
            </div>
            <div className="messenger__footer__file-wrap__description">{file.name}</div>
          </div>
        ))}
        <textarea
          className="messenger__footer__message"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          placeholder={t('Message')}
          onKeyDown={handleKeyDown}
          rows={1}
        />
        <div className="messenger__footer__actions">
          <div className="messenger__footer__images">
            <label>
              <input
                type="file"
                onChange={pickFile}
                hidden
                disabled={imgs?.length > 0 || files?.length > 0}
              />
              <img
                src={AttachIcon as string}
                alt="A"
                style={{
                  cursor: imgs?.length > 0 || files?.length > 0 ? 'not-allowed' : 'pointer',
                }}
              />
            </label>
          </div>
          <button className="messenger__footer__send-button">
            {loading ? <Loader size={15} color={'#fff'} width={2} /> : t('Send')}
          </button>
        </div>
      </form>
    </section>
  );
};
