import React, { type FC } from 'react';
import StarIcon from '../../../../../../assets/icons/profile-reviews-star-icon.png';

interface IProps {
  rating: string;
  amount: string;
  chosenFilterButton: number;
  setChosenFilterButton: (number: number) => void;
}

const filterButtons = ['All', 'Positive', 'Negative'];

export const ProfileReviewsPageHeader: FC<IProps> = ({
  rating,
  amount,
  chosenFilterButton,
  setChosenFilterButton,
}) => {
  return (
    <div className="user-profile__reviews__header">
      <div className="user-profile__reviews__header__title">
        <img src={StarIcon} alt="" />
        <span>{rating}</span>
        <div></div>
        <h3>{amount}</h3>
      </div>
      <div className="user-profile__reviews__header__buttons">
        {filterButtons.map((text, index) => (
          <button
            key={index}
            style={{
              color: chosenFilterButton === index && '#fff',
              backgroundColor: chosenFilterButton === index && 'rgba(34, 34, 34, 1)',
            }}
            onClick={() => {
              setChosenFilterButton(index);
            }}
          >
            {text}
          </button>
        ))}
      </div>
    </div>
  );
};
