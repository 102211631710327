import React, { type FC } from 'react';
import { Select, ConfigProvider } from 'antd';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';
import tronIcon from '../../../../../../assets/icons/tronIcon.svg';
import ArrowDownIconSelect from '../../../../../../components/SvgIcons/ArrowDownIconSelect';
import { useMediaSize } from '../../../../../../hooks/useMediaSize';

interface NetworkSelectorProps {
  networkName: string;
  changeNetwork: (network: string) => void;
}

export const FinancesDepositPageNetworkSelector: FC<NetworkSelectorProps> = ({
  networkName,
  changeNetwork,
}) => {
  // hooks
  const { t } = useLanguageContext();
  const [isMobile] = useMediaSize();
  return (
    <div className="finances__deposit-network">
      <p className="finances__deposit-network-label">{t('Network')}</p>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              selectorBg: 'rgba(241, 241, 241, 1)',
            },
          },
        }}
      >
        <Select
          defaultValue={networkName}
          style={{ width: isMobile ? '100%' : '90%' }}
          onChange={(value: string) => {
            changeNetwork(value);
          }}
          disabled={true}
          suffixIcon={<ArrowDownIconSelect />}
          menuItemSelectedIcon={<ArrowDownIconSelect />}
        >
          <Select.Option value="TRC20">
            <div className="select-dropdown">
              <img src={tronIcon} alt="tron-dropdown-icon" />
              <span>Tron</span>
            </div>
          </Select.Option>
        </Select>
      </ConfigProvider>
    </div>
  );
};
