import React, { type FC } from 'react';

interface IProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ProfileEditPageFormInput: FC<IProps> = ({ label, value, onChange }) => (
  <div className="user-profile__edit-page__form__input">
    <label className="user-profile__edit-page__form__input__label">{label}</label>
    <input
      className="user-profile__edit-page__form__input__field"
      type="text"
      value={value}
      onChange={(e) => {
        onChange(e);
      }}
    />
  </div>
);
