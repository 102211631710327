import React, { type FC } from 'react';

interface ServiceCardProps {
  imageSrc: string;
  title: string;
  price: number;
  onClick?: () => void;
}

export const ProfileServicesInfoCard: FC<ServiceCardProps> = ({
  imageSrc,
  title,
  price,
  onClick,
}) => {
  return (
    <article
      className="user-profile__content-section__services-n-portfolio__card"
      onClick={onClick}
    >
      <img
        loading="lazy"
        src={imageSrc}
        alt={title}
        className="user-profile__content-section__services-n-portfolio__card__image"
      />
      <div className="user-profile__content-section__services-n-portfolio__card__content">
        <h3 className="user-profile__content-section__services-n-portfolio__card__title">
          {title}
        </h3>
        <p className="user-profile__content-section__services-n-portfolio__card__price">${price}</p>
      </div>
    </article>
  );
};
