import type { FC } from 'react';
import type { TypesSvgIcon } from './TypesSvgIcon';

const CalendarIcon: FC<TypesSvgIcon> = ({ color, className }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" className={className || ''}>
      <path
        d="M4.1832 3.09998C3.8244 3.09998 3.5332 2.80878 3.5332 2.44998V0.71665C3.5332 0.35785 3.8244 0.0666504 4.1832 0.0666504C4.542 0.0666504 4.8332 0.35785 4.8332 0.71665V2.44998C4.8332 2.80878 4.542 3.09998 4.1832 3.09998Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M9.8165 3.09998C9.4577 3.09998 9.1665 2.80878 9.1665 2.44998V0.71665C9.1665 0.35785 9.4577 0.0666504 9.8165 0.0666504C10.1753 0.0666504 10.4665 0.35785 10.4665 0.71665V2.44998C10.4665 2.80878 10.1753 3.09998 9.8165 3.09998Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M11.1167 1.80005H2.88333C1.5686 1.80005 0.5 2.86865 0.5 4.18338V11.55C0.5 12.8648 1.5686 13.9334 2.88333 13.9334H11.1167C12.4314 13.9334 13.5 12.8648 13.5 11.55V4.18338C13.5 2.86865 12.4314 1.80005 11.1167 1.80005ZM11.1167 12.6334H2.88333C2.2862 12.6334 1.8 12.1472 1.8 11.55V6.13338H12.2V11.55C12.2 12.1472 11.7138 12.6334 11.1167 12.6334Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M6.99997 7.21667C6.52243 7.21667 6.1333 7.60581 6.1333 8.08334C6.1333 8.56087 6.52243 8.95001 6.99997 8.95001C7.4775 8.95001 7.86663 8.56087 7.86663 8.08334C7.86663 7.60581 7.4775 7.21667 6.99997 7.21667Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M10.0332 8.95001C10.5107 8.95001 10.8998 8.56087 10.8998 8.08334C10.8998 7.60581 10.5107 7.21667 10.0332 7.21667C9.55564 7.21667 9.1665 7.60581 9.1665 8.08334C9.1665 8.56087 9.55564 8.95001 10.0332 8.95001Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M6.99997 9.81665C6.52243 9.81665 6.1333 10.2058 6.1333 10.6833C6.1333 11.1609 6.52243 11.55 6.99997 11.55C7.4775 11.55 7.86663 11.1609 7.86663 10.6833C7.86663 10.2058 7.4775 9.81665 6.99997 9.81665Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M3.96676 9.81665C3.48923 9.81665 3.1001 10.2058 3.1001 10.6833C3.1001 11.1609 3.48923 11.55 3.96676 11.55C4.4443 11.55 4.83343 11.1609 4.83343 10.6833C4.83343 10.2058 4.4443 9.81665 3.96676 9.81665Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M10.0332 9.81665C9.55564 9.81665 9.1665 10.2058 9.1665 10.6833C9.1665 11.1609 9.55564 11.55 10.0332 11.55C10.5107 11.55 10.8998 11.1609 10.8998 10.6833C10.8998 10.2058 10.5107 9.81665 10.0332 9.81665Z"
        fill={color || '#A0A0A0'}
      />
    </svg>
  );
};

export default CalendarIcon;
