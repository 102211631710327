import React, { type FC } from 'react';
import { FinancesAccountsBalanceCard } from './FinancesAccountsBalanceCard/FinancesAccountsBalanceCard';
import { FinancesHistory } from '../FinancesHistory/FinancesHistory';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';

interface IProps {
  openDepositModal: () => void;
  openWithdrawModal: () => void;
  availableAmount: number;
  lockedAmount: number;
}

export const FinancesAccounts: FC<IProps> = ({
  openDepositModal,
  openWithdrawModal,
  availableAmount,
  lockedAmount,
}) => {
  // hooks
  const { t } = useLanguageContext();

  return (
    <div className="finances__content__accounts">
      <h1 className="finances__content__accounts__title">{t('Accounts')}</h1>
      <section className="finances__content__accounts__balance-section">
        <FinancesAccountsBalanceCard
          title={`${String(t('Total balance'))}, $`}
          amount={availableAmount.toString()}
          percentageChange={13}
          isLocked={false}
          openDepositModal={openDepositModal}
          openWithdrawModal={openWithdrawModal}
        />
        <FinancesAccountsBalanceCard
          title={`${String(t('Locked'))}, $`}
          amount={lockedAmount.toString()}
          isLocked={true}
          unlockDate={new Date()}
          openDepositModal={openDepositModal}
          openWithdrawModal={openWithdrawModal}
        />
      </section>
      <FinancesHistory />
    </div>
  );
};
