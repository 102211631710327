import React, { type FC, useRef, useState } from 'react';
import { Components, type IAuthFormProps } from '../../Auth';
import WebsiteLogo from '../../../../assets/icons/00x-logo.png';
import { AuthInput } from '../AuthInput/AuthInput';
import { AuthButton } from '../AuthButton/AuthButton';
import { AuthDivider } from '../AuthDivider/AuthDivider';
import type { IRegisterRequest } from '../../../../interfaces/api/auth';
import { apiAuth } from '../../../../services/api/auth';
import { setUser } from '../../../../redux/auth/reducer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';

const recaptchaKey = '6LeffiwqAAAAAFZNmZss05iud1BAvGNDJbc58YTf';

export const AuthSignup: FC<IAuthFormProps> = ({ setComponent }) => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const recaptchaToken = useRef(null);

  // states
  const [signupProcessStep, setSignupProcessStep] = useState(1);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Submit signup
  const handleSubmit = (): void => {
    const data: IRegisterRequest = {
      login,
      password,
      recaptcha_token: recaptchaToken.current,
    };

    try {
      apiAuth.register(data).then((user) => {
        if (user) {
          dispatch(setUser(user));
          navigate('/browse/people');
        }
      });
    } catch (e) {
      message.error('Something went wrong. Please try again');
    }
  };

  return (
    <main className="auth-signup">
      <div className="auth-signup__logo">
        <img src={WebsiteLogo} alt="" />
        Club
      </div>
      <div className="auth-signup__title">
        <h1>{signupProcessStep === 1 ? 'Welcome' : 'Create password'}</h1>
        <h4>
          {signupProcessStep === 1
            ? 'Create your 00x account'
            : 'Create strong password with at least one number and uppercase letter'}
        </h4>
      </div>
      <form
        className="auth-signup__form"
        action="#"
        method="POST"
        onSubmit={(event) => {
          event.preventDefault();

          handleSubmit();
        }}
      >
        <div
          style={{
            display: signupProcessStep === 2 && 'none',
          }}
        >
          <AuthInput
            type="text"
            placeholder="Enter email or username"
            autoComplete="off"
            value={login}
            onChange={(e) => {
              setLogin(e.target.value);
            }}
            className="auth-signup__input"
          ></AuthInput>
        </div>

        <div
          style={{
            display: signupProcessStep === 1 && 'none',
          }}
        >
          <AuthInput
            type="password"
            placeholder="Password"
            autoComplete="off"
            aria-label="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            className={`auth-signup__input${!((password.match(/[A-Z]/g) || []).length >= 1 && password.match(/\d/g)?.length > 0 && password.length >= 8) ? '__error' : ''}`}
          ></AuthInput>

          <AuthInput
            disabled={!password}
            type="password"
            placeholder="Confirm password"
            autoComplete="off"
            aria-label="confirm password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            className={`auth-signup__input${password !== confirmPassword ? '__error' : ''}`}
          ></AuthInput>

          <div className="auth-signup__captcha-container">
            <ReCAPTCHA
              sitekey={recaptchaKey}
              ref={recaptchaToken}
              onChange={(token: string) => {
                recaptchaToken.current = token;
              }}
              onExpire={() => {
                recaptchaToken.current = undefined;
              }}
            />
          </div>
        </div>

        <div className="auth-signup__button-container">
          <AuthButton
            type={signupProcessStep === 2 ? 'submit' : 'button'}
            variant="primary"
            className="auth-signup__button"
            disabled={signupProcessStep === 1 ? !login : password !== confirmPassword || !password}
            onClick={() => {
              signupProcessStep === 1 && setSignupProcessStep(2);
            }}
          >
            Continue
          </AuthButton>

          <AuthDivider />

          <AuthButton
            className="auth-signup__button"
            variant="secondary"
            onClick={() => {
              setComponent(Components.LOGIN);
            }}
          >
            Log in
          </AuthButton>
        </div>
      </form>
    </main>
  );
};
