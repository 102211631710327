import Axios, { type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from 'axios';
import { stringify } from 'qs';

type TAnyObject = Record<string, any>;

interface IApiRouterParams<Params extends string = string, Query extends TAnyObject = TAnyObject> {
  params?: Record<Params, string>;
  query?: Query;
}

const getApiRouter = (route: string, { query, params = {} }: IApiRouterParams = {}): string => {
  let formattedRoute = route;

  for (const paramKey of Object.keys(params)) {
    formattedRoute = formattedRoute.replace(`:${paramKey}`, params[paramKey]);
  }

  if (query) {
    const queryString = stringify(query, { arrayFormat: 'repeat' });
    formattedRoute = `${formattedRoute}?${queryString}`;
  }

  return formattedRoute;
};

export interface IAxiosRequestConfig extends Omit<AxiosRequestConfig, 'params'>, IApiRouterParams {}

export const apiAxiosInstance = Axios.create({
  timeout: 60000 * 2,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export abstract class ApiServiceBase {
  protected baseUrl = '';
  private readonly axios: AxiosInstance = apiAxiosInstance;
  private readonly domain = 'https://00x.club/api/';

  private async request<Response>(
    url = '',
    { params, query, method, data, ...config }: IAxiosRequestConfig = {},
  ): Promise<Response> {
    let userToken: string;
    try {
      userToken = JSON.parse(localStorage.getItem('user') ?? '{}')?.token;
    } catch (e) {
      e && console.log(e);
    }

    try {
      return (
        (
          await this.axios.request({
            url: getApiRouter(this.domain + this.baseUrl + url, { params, query }),
            method,
            data,
            ...config,
            headers: {
              ...(config.headers ?? {}),
              Authorization: `Bearer ${userToken}`,
            },
          })
        ).data?.data ?? null
      );
    } catch (err) {
      // toast.error(
      //   err.response?.data?.message ??
      //     (typeof err.response?.data === 'object'
      //       ? JSON.stringify(err.response?.data)
      //       : 'Unknown error'),
      // );
      return null;
    }
  }

  protected get<Response>(
    url = '',
    { params, query, ...config }: IAxiosRequestConfig = {},
  ): Promise<Response> {
    return this.request(url, { method: 'GET', params, query, ...config });
  }

  protected post<Request extends TAnyObject, Response extends TAnyObject = TAnyObject>(
    url = '',
    data?: Request,
    { params, query, ...config }: IAxiosRequestConfig = {},
  ): Promise<Response> {
    return this.request(url, { method: 'POST', data, params, query, ...config });
  }

  protected delete<Request extends TAnyObject, Response extends TAnyObject = TAnyObject>(
    url = '',
    data?: Request,
    { params, query, ...config }: IAxiosRequestConfig = {},
  ): Promise<Response> {
    return this.request(url, { method: 'DELETE', data, params, query, ...config });
  }

  protected rawPost<Request extends TAnyObject, Response extends TAnyObject = TAnyObject>(
    url = '',
    data?: Request,
    { params, query, ...config }: IAxiosRequestConfig = {},
  ): Promise<AxiosResponse<Response>> {
    let userToken: string;
    try {
      userToken = JSON.parse(localStorage.getItem('user') ?? '{}')?.token;
    } catch (e) {
      e && console.log(e);
    }
    return this.axios.post(getApiRouter(this.domain + url, { params, query }), data, {
      ...config,
      headers: {
        Authorization: `Bearer ${userToken}`,
        ...(config.headers ?? {}),
      },
    });
  }
}
