import React, { type FC, memo } from 'react';
import './MessageBubble.scss';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';
import { type IFile } from '../../../../../interfaces/api/messenger';
import DownloadIcon from '../../../../../components/SvgIcons/DownloadIcon';

interface MessageBubbleProps {
  content: string;
  time: string;
  toMe?: boolean;
  isRead?: boolean;
  files?: IFile[];
  images?: IFile[];
}

const MessageBubble: FC<MessageBubbleProps> = ({ content, time, toMe, isRead, files, images }) => {
  const { t } = useLanguageContext();

  const downloadFile = (url: string, filename: string): void => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    setTimeout(() => {
      link.click();
      document.body.removeChild(link);
    }, 500);
  };

  if (toMe) {
    return (
      <article className="message-bubble__to-me">
        {images?.map((img, index) => (
          <div className="message-bubble__img" key={index}>
            <img src={img.url} alt="" />
          </div>
        ))}
        {files?.map((file, index) => (
          <div className="message-bubble__file" key={index}>
            <div
              className="message-bubble__file__downloadIcon"
              onClick={() => {
                downloadFile(file.url, file.file);
              }}
            >
              <DownloadIcon />
            </div>
            <div className="message-bubble__file__fileName">{file.file}</div>
          </div>
        ))}
        <p className="message-bubble__to-me__content">{content}</p>
        <div>
          <time className="message-bubble__to-me__time">{time}</time>
        </div>
      </article>
    );
  } else {
    return (
      <article className="message-bubble__from-me">
        {images?.map((img, index) => (
          <div className="message-bubble__img" key={index}>
            <img src={img.url} alt="" />
          </div>
        ))}
        {files?.map((file, index) => (
          <div className="message-bubble__file" key={index}>
            <div
              className="message-bubble__file__downloadIcon"
              onClick={() => {
                downloadFile(file.url, file.file);
              }}
            >
              <DownloadIcon />
            </div>
            <div className="message-bubble__file__fileName">{file.file}</div>
          </div>
        ))}
        <p className="message-bubble__from-me__content">{content}</p>
        <div className="message-bubble__from-me__time-wrapper">
          {isRead && (
            <>
              <p>{t('Seen')}</p>
              <div></div>
            </>
          )}
          <span className="message-bubble__from-me__time">{time}</span>
        </div>
      </article>
    );
  }
};

export default memo(MessageBubble);
