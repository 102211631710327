import type { FC, SyntheticEvent } from 'react';
import cn from 'classnames';
import './GButton.scss';

export enum ButtonTypes {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}

export enum ButtonColors {
  BLUE = 'blue',
  RED = 'red',
  GREEN = 'green',
  WHITE = 'white',
  VIOLET = 'violet',
  GRAY = 'gray',
  BLACK = 'black',
}

interface IGButtonProps {
  type: ButtonTypes;
  title: string;
  color: ButtonColors;
  bordered: boolean;
  filled: boolean;
  tiny?: boolean;
  disabled?: boolean;
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  onSubmit?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  height?: number;
  backgroundGray?: boolean;
  hidden?: boolean;
}

const GButton: FC<IGButtonProps> = (props) => {
  return (
    <button
      className={cn(
        'g-button',
        props.tiny && 'g-button_tiny',
        props.filled && `${props.color}-bg ${props.color}-bg_hover`,
        props.bordered && `${props.color}-border ${props.color}-border_hover`,
      )}
      style={{
        display: props.hidden && 'none',
        color: props.filled ? 'white' : 'black',
        fontWeight: props.filled ? 700 : 500,
        height: props.height ? `${props.height}px` : null,
        maxHeight: props.height ? `${props.height}px` : null,
        minHeight: props.height ? `${props.height}px` : null,
        background: props.backgroundGray && 'rgba(0, 0, 1, 0.25)',
      }}
      type={props.type}
      disabled={props.disabled}
      onClick={props.onClick}
      onSubmit={props.onSubmit}
    >
      <span>{props.title}</span>
    </button>
  );
};

export default GButton;
