import { type FC } from 'react';
import { ProfileEditPageFormPublic } from './ProfileEditPageFormPublic/ProfileEditPageFormPublic';
import { type ProfileInfo } from '../ProfileEditPage';
import { ProfileEditPageFormAbout } from './ProfileEditPageFormAbout/ProfileEditPageFormAbout';

interface IProps {
  profileInfo: ProfileInfo;
  setProfileInfo: (info: any) => void;
}

export const ProfileEditPageForm: FC<IProps> = ({ profileInfo, setProfileInfo }) => {
  return (
    <main className="user-profile__edit-page__container">
      <ProfileEditPageFormPublic profileInfo={profileInfo} setProfileInfo={setProfileInfo} />
      <ProfileEditPageFormAbout profileInfo={profileInfo} setProfileInfo={setProfileInfo} />
    </main>
  );
};
