import React, { type FC } from 'react';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';

interface ProfileDetailsProps {
  location: string;
  memberSince: string;
  languages: string[];
}

export const ProfileMainInfoDetails: FC<ProfileDetailsProps> = ({
  location,
  memberSince,
  languages,
}) => {
  // hooks
  const { t } = useLanguageContext();

  return (
    <section className="user-profile__details">
      <div className="user-profile__details-item">
        <h3 className="user-profile__details-label">{t('Languages')}</h3>
        {languages.length > 0 ? (
          <p className="user-profile__details-value">{languages.join(', ')}</p>
        ) : (
          <p className="user-profile__details-value">{t('No info yet')}</p>
        )}
      </div>
      <div className="user-profile__details-item">
        <h3 className="user-profile__details-label">{t('From')}</h3>
        {location ? (
          <p className="user-profile__details-value">{location}</p>
        ) : (
          <p className="user-profile__details-value">{t('No info yet')}</p>
        )}
      </div>
      <div className="user-profile__details-item">
        <h3 className="user-profile__details-label">{t('Member since')}</h3>
        <p className="user-profile__details-value">{memberSince}</p>
      </div>
    </section>
  );
};
