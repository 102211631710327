import { type FC } from 'react';
import { TagInput } from '../../../TagInput/TagInput';

interface IProps {
  languages: string[];
  setLanguages: (tags: string[]) => void;
}

export const ModalFilterLanguages: FC<IProps> = ({ setLanguages }) => {
  return (
    <div className="modal-filter__content__options__languages">
      <TagInput
        label={'Languages'}
        onTagsChange={(newTags) => {
          setLanguages(newTags);
        }}
        maxTags={5}
        placeholder={'Language'}
      />
    </div>
  );
};
