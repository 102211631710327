import React, { type FC, useEffect, useRef, useState } from 'react';
import { parseDate } from '../../../../../../services/dateServices/parseDate';

interface ReviewProps {
  logoSrc: string;
  reviewerName: string;
  reviewDate: Date;
  reviewContent: string;
}

export const ProfileReviewsInfoCard: FC<ReviewProps> = ({
  logoSrc,
  reviewerName,
  reviewDate,
  reviewContent,
}) => {
  // states
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  // ref fot content section
  const contentRef = useRef(null);

  // Check if text is overflowing
  useEffect(() => {
    if (contentRef.current) {
      const lineHeight = parseFloat(getComputedStyle(contentRef.current).lineHeight);
      const maxHeight = lineHeight * 4;
      if (contentRef.current.scrollHeight > maxHeight) {
        setIsOverflowing(true);
      }
    }
  }, []);

  // Toggle expand content
  const toggleExpand = (): void => {
    setIsExpanded(!isExpanded);
  };

  return (
    <article className="user-profile__content-section__reviews__card">
      <header className="user-profile__content-section__reviews__card__header">
        <img
          loading="lazy"
          src={logoSrc}
          alt=""
          className="user-profile__content-section__reviews__card__logo"
        />
        <h2 className="user-profile__content-section__reviews__card__reviewer-name">
          {reviewerName}
        </h2>
        <time className="user-profile__content-section__reviews__card__date">
          {parseDate.toTimeAgo(reviewDate)}
        </time>
      </header>
      <p
        className="user-profile__content-section__reviews__card__content"
        ref={contentRef}
        style={{
          display: isExpanded ? 'flex' : '-webkit-box',
          overflow: 'hidden',
          WebkitLineClamp: isExpanded && 'none',
        }}
      >
        {reviewContent}
      </p>
      {isOverflowing && (
        <button
          className="user-profile__content-section__reviews__card__show-more-btn"
          onClick={toggleExpand}
        >
          {isExpanded ? 'Show less' : 'Show more'}
        </button>
      )}
    </article>
  );
};
