import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { State } from './types';
import { type IUserInfo } from '../../interfaces/user';

const initialState: State = {
  user: JSON.parse(localStorage.getItem('user')) ?? null,
  userName: '',
};

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Record<string, any>>) => {
      if (!action.payload) {
        localStorage.removeItem('user');
        return;
      }
      const existedUser = (JSON.parse(localStorage.getItem('user')) ?? {}) as IUserInfo;
      const data = { ...existedUser, ...action.payload };
      localStorage.setItem('user', JSON.stringify(data));
      state.user = data;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
  },
});

export const { setUser, setUserName } = authReducer.actions;

export default authReducer.reducer;
