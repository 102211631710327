import React, { useState } from 'react';
import { FinancesHistoryFilter } from './FinancesHistoryFilter/FinancesHistoryFilter';
import { FinancesHistoryTable } from './FinancesHistoryTable/FinancesHistoryTable';
import { FinancesHistoryTableMobile } from './FinancesHistoryTable/FinancesHistoryTableMobile/FinancesHistoryTableMobile';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';
import { getRandomDate } from '../../../../../services/dateServices/getRandomDate';

export interface FinancesTransaction {
  id: string;
  type: string;
  date: Date;
  method: string;
  status: 'Completed' | 'Cancelled';
  amount: string;
}

// Mock array of transactions
const transactions: FinancesTransaction[] = [
  {
    id: '0',
    type: 'Withdraw',
    date: getRandomDate(),
    method: 'TRC20, nvk2t09f22g82ygt32t0ty97348y2020t3t30t3',
    status: 'Completed',
    amount: '$1200',
  },
  {
    id: '1',
    type: 'Deposit',
    date: getRandomDate(),
    method: 'Solana, ieroijbcIiSVBJKVB',
    status: 'Completed',
    amount: '$1200',
  },
  {
    id: '2',
    type: 'Transfer',
    date: getRandomDate(),
    method: 'TRC20, nvk2t09f22g82ygt32t0ty97348y2020t3t30t3',
    status: 'Completed',
    amount: '$1200',
  },
  {
    id: '3',
    type: 'Payment',
    date: getRandomDate(),
    method: 'Solana, ieroijbcIiSVBJKVB',
    status: 'Cancelled',
    amount: '$1200',
  },
  {
    id: '4',
    type: 'Withdraw',
    date: getRandomDate(),
    method: 'TRC20, nvk2t09f22g82ygt32t0ty97348y2020t3t30t3',
    status: 'Completed',
    amount: '$1200',
  },
  {
    id: '5',
    type: 'Withdraw',
    date: getRandomDate(),
    method: 'TRC20, nvk2t09f22g82ygt32t0ty97348y2020t3t30t3',
    status: 'Completed',
    amount: '$1200',
  },
  {
    id: '6',
    type: 'Withdraw',
    date: getRandomDate(),
    method: 'TRC20, nvk2t09f22g82ygt32t0ty97348y2020t3t30t3',
    status: 'Completed',
    amount: '$1200',
  },
  {
    id: '7',
    type: 'Withdraw',
    date: getRandomDate(),
    method: 'TRC20, nvk2t09f22g82ygt32t0ty97348y2020t3t30t3',
    status: 'Completed',
    amount: '$1200',
  },
];

export const FinancesHistory: React.FC = () => {
  // states
  const [sortBy, setSortBy] = useState('month');
  const [filterBy, setFilterBy] = useState(0);

  // filter options
  const filterOptions = ['All', 'Withdraw', 'Deposit', 'Income', 'Purchase'];

  // hooks
  const { t } = useLanguageContext();

  return (
    <section className="finances__content__history">
      <h2 className="finances__content__history__title">{t('History')}</h2>
      <FinancesHistoryFilter
        filters={filterOptions}
        sortBy={sortBy}
        setSortBy={(value) => {
          setSortBy(value);
        }}
        filterBy={filterBy}
        setFilterBy={(value) => {
          setFilterBy(value);
        }}
      />
      <FinancesHistoryTable
        transactions={
          filterBy
            ? transactions.filter((transaction) => transaction.type === filterOptions[filterBy])
            : transactions
        }
      />
      <FinancesHistoryTableMobile
        transactions={
          filterBy
            ? transactions.filter((transaction) => transaction.type === filterOptions[filterBy])
            : transactions
        }
      />
    </section>
  );
};
