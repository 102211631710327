import React, { type FC } from 'react';
import { type FinancesTransaction } from '../FinancesHistory';
import { Table } from 'antd';
import type { TableProps } from 'antd';
import DepositIcon from '../../../../../../assets/icons/finances-history-deposit-icon.png';
import WithdrawIcon from '../../../../../../assets/icons/finances-history-withdraw-icon.png';
import TransferIcon from '../../../../../../assets/icons/finances-history-transfer-icon.png';
import PaymentIcon from '../../../../../../assets/icons/finances-history-payment-icon.png';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';
import { financesHistoryTypeConfig } from '../../../../../../configs/translation/financesConfigs/financesHistoryTypeConfig';
import { financesHistoryStatusConfig } from '../../../../../../configs/translation/financesConfigs/financesHistoryStatusConfig';
import { parseDate } from '../../../../../../services/dateServices/parseDate';
import TalentixTypeTableIcon from '../../../../../../assets/icons/talentixTypeTableIcon.svg';
import TalentixDateTableIcon from '../../../../../../assets/icons/talentixDateTableIcon.svg';
import TalentixWalletTableIcon from '../../../../../../assets/icons/talentixWalletTableIcon.svg';
import TalentixStatusTableIcon from '../../../../../../assets/icons/talentixStatusTableIcon.svg';
import TalentixAmountTableIcon from '../../../../../../assets/icons/talentixAmountTableIcon.svg';

interface IProps {
  transactions: FinancesTransaction[];
}

const setTypeImage = (text: string): string => {
  switch (text) {
    case 'Withdraw':
      return WithdrawIcon as string;
    case 'Deposit':
      return DepositIcon as string;
    case 'Transfer':
      return TransferIcon as string;
    case 'Payment':
      return PaymentIcon as string;
    default:
      return '';
  }
};

export const FinancesHistoryTable: FC<IProps> = ({ transactions }) => {
  // hooks
  const { t } = useLanguageContext();

  const columns: TableProps['columns'] = [
    {
      title: (
        <div style={{ display: 'flex', gap: '4.62px', alignItems: 'center' }}>
          <img src={TalentixTypeTableIcon} alt="type-icon" />
          <span style={{ color: '#A0A0A0' }}>{t('Type')}</span>
        </div>
      ),
      dataIndex: 'type',
      key: 'type',
      width: '15%',
      render: (text) => {
        const randomIndex = Math.floor(Math.random() * 4);
        const item = financesHistoryTypeConfig[randomIndex];
        return (
          <div className="finances__content__history__type-wrapper">
            <div>
              <img src={setTypeImage(text)} alt="" />
            </div>
            <span>{t(item?.label)}</span>
          </div>
        );
      },
    },
    {
      title: (
        <div style={{ display: 'flex', gap: '4.62px', alignItems: 'center' }}>
          <img src={TalentixDateTableIcon} alt="talentix-date-table-icon" />
          <span style={{ color: '#A0A0A0' }}>{t('Date')}</span>
        </div>
      ),
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      render: (date: Date) => <span>{parseDate.toDesiredDate(date, 'MMShort DD, HH:mm')}</span>,
    },
    {
      title: (
        <div style={{ display: 'flex', gap: '4.62px', alignItems: 'center' }}>
          <img src={TalentixWalletTableIcon} alt="talentix-date-wallet-table-icon" />
          <span style={{ color: '#A0A0A0' }}>{t('Method')}</span>
        </div>
      ),
      dataIndex: 'method',
      key: 'method',
      width: '15%',
      render: (text: string) => <span>{text.slice(0, 13) + '...'}</span>,
    },
    {
      title: (
        <div style={{ display: 'flex', gap: '4.62px', alignItems: 'center' }}>
          <img src={TalentixStatusTableIcon} alt="talentix-status-wallet-table-icon" />
          <span style={{ color: '#A0A0A0' }}>{t('Status')}</span>
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      render: (text: string) => {
        const randomIndex = Math.floor(Math.random() * 2);
        const item = financesHistoryStatusConfig[randomIndex];
        return (
          <span
            className={`finances__content__history__status-chip--${text.toLowerCase()}`}
            style={{ color: item?.color, backgroundColor: item?.bgColor }}
          >
            {t(item?.label)}
          </span>
        );
      },
    },
    {
      title: (
        <div style={{ display: 'flex', gap: '4.62px', alignItems: 'center' }}>
          <img src={TalentixAmountTableIcon} alt="talentix-date-wallet-table-icon" />
          <span style={{ color: '#A0A0A0' }}>{t('Amount')}</span>
        </div>
      ),
      dataIndex: 'amount',
      width: '1%',
      key: 'amount',
    },
  ];

  return (
    <div className="finances__content__history__table">
      <Table
        columns={columns}
        dataSource={transactions}
        className="orders__list__custom_table"
        pagination={{ rootClassName: 'table__paginator' }}
      />
    </div>
  );
};
