import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import English from '../assets/translation/eng.json';
import Russian from '../assets/translation/rus.json';

const resources = {
  en: {
    translation: English,
  },
  ru: {
    translation: Russian,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: 'en',
});

export default i18next;
