import React, { type FC } from 'react';
import MessengerOrderInfoActivity from './MessengerOrderInfoActivity/MessengerOrderInfoActivity';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';
import MessengerOrderInfoDescription from './MessengerOrderInfoDescription/MessengerOrderInfoDescription';

const MessengerOrderInfo: FC = () => {
  // hooks
  const { t } = useLanguageContext();
  return (
    <div className="messenger__order__info">
      <div className="messenger__order__info__wrap">
        <div className="messenger__order__info__wrap__title">{t('Activity')}</div>
        <div className="messenger__order__info__wrap__activity_block">
          <MessengerOrderInfoActivity />
        </div>
        <div className="messenger__order__info__wrap__title">{t('Order details')}</div>
        <MessengerOrderInfoDescription />
      </div>
    </div>
  );
};

export default MessengerOrderInfo;
