import type { FC } from 'react';
import type { TypesSvgIcon } from './TypesSvgIcon';

const SearchIcon: FC<TypesSvgIcon> = ({ color, className }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" className={className || ''}>
      <path
        d="M12.3999 12.7136L12.3988 12.7125L8.87435 9.18807C8.78747 9.10119 8.78747 8.96037 8.87435 8.87349C8.96123 8.78662 9.10204 8.78662 9.18892 8.87349L12.7134 12.3979C12.8002 12.4848 12.8002 12.6256 12.7134 12.7125C12.6696 12.7562 12.6132 12.7779 12.5565 12.7779H12.5556C12.4976 12.7779 12.4422 12.7562 12.3999 12.7136Z"
        fill={color || '#414141'}
        stroke={color || '#414141'}
        strokeWidth="0.888889"
      />
      <path
        d="M5.88894 11.0001C3.07117 11.0001 0.777832 8.70672 0.777832 5.88894C0.777832 3.07117 3.07117 0.777832 5.88894 0.777832C8.70672 0.777832 11.0001 3.07117 11.0001 5.88894C11.0001 8.70672 8.70672 11.0001 5.88894 11.0001ZM5.88894 2.11117C3.80628 2.11117 2.11117 3.80628 2.11117 5.88894C2.11117 7.97161 3.80628 9.66672 5.88894 9.66672C7.97161 9.66672 9.66672 7.97161 9.66672 5.88894C9.66672 3.80628 7.97161 2.11117 5.88894 2.11117Z"
        fill={color || '#414141'}
      />
    </svg>
  );
};

export default SearchIcon;
