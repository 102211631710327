import '../../../../../components/Modals/Modal.scss';
import React, { type FC, useState } from 'react';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';
import Modal from 'react-modal';
import { Rate } from 'antd';
import GButton, { ButtonColors, ButtonTypes } from '../../../../../components/GButton/GButton';
import { apiReviews } from '../../../../../services/api/legacy/reviews';

const customStyles = {
  review: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '25vw',
      maxWidth: '80vw',
      minHeight: '15vh',
    },
  },
};

interface IProps {
  onSuccess: () => void;
  onFailure: () => void;
  onClose: () => void;
  isOpened: boolean;
  package_name: string;
  executor: string;
  executor_id: string;
}

interface IReview {
  target_id: string;
  package_name: string;
  description?: string;
  rank: number;
}

export const MessengerReviewModal: FC<IProps> = (props: IProps) => {
  const { t } = useLanguageContext();

  const [rating, setRating] = useState(null);
  const [reviewComment, setReviewComment] = useState('');

  Modal.setAppElement('#root');

  return (
    <div className="modal">
      <Modal
        isOpen={props.isOpened}
        onRequestClose={() => {
          props.onClose();
        }}
        style={customStyles.review}
        contentLabel="Write a review"
      >
        <div className="modal__close-btn">
          <button
            onClick={() => {
              props.onClose();
            }}
          >
            {t('Close')}
          </button>
        </div>
        <div className="modal__content">
          <h2
            style={{
              marginBottom: '3vh',
            }}
          >
            {t('Share your experience')}
          </h2>
          <div
            style={{
              width: '95%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '1vh',
              alignItems: 'center',
            }}
          >
            <h4
              style={{
                fontWeight: '400',
                marginRight: '2vw',
              }}
            >
              {t('Service')}:
            </h4>
            <h4>{props.package_name}</h4>
          </div>
          <div
            style={{
              width: '95%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '2.5vh',
              alignItems: 'center',
            }}
          >
            <h4
              style={{
                fontWeight: '400',
                marginRight: '2vw',
              }}
            >
              {t('Executor')}
            </h4>
            <h4>*Executor name*</h4>
          </div>
          <Rate
            allowHalf
            defaultValue={1}
            onChange={(value) => {
              setRating(value);
            }}
          />
          <textarea
            rows={4}
            placeholder={t('Comment (Optional)')}
            style={{
              marginTop: '2vh',
              fontSize: '16px',
              resize: 'none',
              marginBottom: '1vh',
              padding: '5px',
            }}
            value={reviewComment}
            onChange={(e) => {
              setReviewComment(e.target.value);
            }}
          />
          <GButton
            type={ButtonTypes.BUTTON}
            title={t('Submit')}
            color={ButtonColors.GREEN}
            bordered={false}
            filled={true}
            disabled={!rating}
            onClick={() => {
              const newReview: IReview = {
                target_id: props.executor_id,
                package_name: props.package_name,
                rank: rating,
              };
              if (reviewComment) {
                newReview.description = reviewComment;
              }
              apiReviews.addReview(newReview).then((r) => {
                r ? props.onSuccess() : props.onFailure();
              });
            }}
          />
        </div>
      </Modal>
    </div>
  );
};
