import React, { type FC } from 'react';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';

interface IMessengerOrderInfoActivityDescriptionPattern {
  description?: string;
  nextBtn?: {
    label: string;
    styles?: React.CSSProperties;
    onClick: () => void;
  };
  cancelBtn?: {
    label: string;
    styles?: React.CSSProperties;
    onClick: () => void;
  };
  descriptionStyle?: React.CSSProperties;
}

const MessengerOrderInfoActivityDescriptionPattern: FC<
  IMessengerOrderInfoActivityDescriptionPattern
> = ({ description, nextBtn, cancelBtn, descriptionStyle }) => {
  // hooks
  const { t } = useLanguageContext();

  if (!description && !nextBtn) return;

  return (
    <div className="messenger__order__info__wrap__activitysteps__description">
      {description && (
        <div
          className="messenger__order__info__wrap__activitysteps__description__text"
          style={descriptionStyle || {}}
        >
          {t(description)}
        </div>
      )}
      {nextBtn && (
        <div className="messenger__order__info__wrap__activitysteps__description__btns">
          <div
            className="messenger__order__info__wrap__activitysteps__description__btns__nextBtn"
            onClick={nextBtn.onClick}
            style={nextBtn.styles || {}}
          >
            {t(nextBtn.label)}
          </div>
          {cancelBtn && (
            <div
              className="messenger__order__info__wrap__activitysteps__description__btns__cancelBtn"
              onClick={cancelBtn.onClick}
              style={cancelBtn.styles || {}}
            >
              {t(cancelBtn.label)}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MessengerOrderInfoActivityDescriptionPattern;
