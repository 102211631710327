import React, { type FC, useState } from 'react';
import MessengerOrderInfoActivity from '../../MessengerOrderInfo/MessengerOrderInfoActivity/MessengerOrderInfoActivity';
import { useOrderActivitySteps } from '../../../../../../configs/orderInfo/orderInfoActivity';
import ArrowIcon from '../../../../../../components/SvgIcons/ArrowIcon';
import StepsIcon from '../../../../../../components/StepsComponents/StepsIcon';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';

const MessengerHeaderMobileActivity: FC = () => {
  // state
  const [isOpen, setIsOpen] = useState(false);

  // hooks
  const { currentStep, currentItem } = useOrderActivitySteps();
  const { t } = useLanguageContext();

  return (
    <div className="messenger__header__activity-mobile">
      <div
        className="messenger__header__activity-mobile__currentStep"
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        <div className="messenger__header__activity-mobile__currentStep__block">
          <span>{currentStep + 1 > 5 ? <StepsIcon /> : currentStep + 1}</span>
        </div>
        <div className="messenger__header__activity-mobile__currentStep__title">
          {t(currentItem?.title)}
        </div>
        {!isOpen && (
          <ArrowIcon className="messenger__header__activity-mobile__currentStep__openIcon" />
        )}
      </div>
      <div
        className="messenger__header__activity-mobile__allSteps"
        style={{
          maxHeight: isOpen ? '500px' : '0',
          overflow: 'hidden',
          padding: !isOpen ? '0 25px' : '12px 25px',
        }}
      >
        <MessengerOrderInfoActivity />
      </div>
    </div>
  );
};

export default MessengerHeaderMobileActivity;
