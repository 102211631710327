import React, { type FC } from 'react';
import MessengerOrderInfoConfirmPopup from './popups/MessengerOrderInfoConfirmPopup';
import MessengerOrderInfoEditRequestPopup from './popups/MessengerOrderInfoEditRequestPopup';
import './MessangerOrderInfoPopups.scss';
import MessengerOrderInfoCloseDealPopup from './popups/MessengerOrderInfoCloseDealPopup';
import MessengerOrderInfoOrderCompPopup from './popups/MessengerOrderInfoOrderCompPopup';
import MessengerOrderInfoRatePopup from './popups/MessengerOrderInfoRatePopup';

const MessengerOrderInfoPopups: FC = () => {
  return (
    <>
      <MessengerOrderInfoConfirmPopup />
      <MessengerOrderInfoEditRequestPopup />
      <MessengerOrderInfoCloseDealPopup />
      <MessengerOrderInfoOrderCompPopup />
      <MessengerOrderInfoRatePopup />
    </>
  );
};

export default MessengerOrderInfoPopups;
