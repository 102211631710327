import { useEffect, useState } from 'react';
import { useWindowSize } from './useWindowSize';

export const useMediaSize = (): boolean[] => {
  const { width } = useWindowSize();
  const [mediaPoint, setMediaPoint] = useState({
    isMediaTablet: true,
    isMediaDesktop: true,
  });
  const { isMediaTablet, isMediaDesktop } = mediaPoint;
  useEffect(() => {
    if (width < 1276) {
      setMediaPoint({
        isMediaTablet: true,
        isMediaDesktop: false,
      });
    } else {
      setMediaPoint({
        isMediaTablet: false,
        isMediaDesktop: true,
      });
    }
  }, [width]);
  return [isMediaTablet, isMediaDesktop];
};
