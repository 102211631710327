import React, { type FC } from 'react';
import { type TypesSvgIcon } from './TypesSvgIcon';

const InfoIcon: FC<TypesSvgIcon> = ({ color, className }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" className={className || ''}>
      <path
        d="M6 0C2.69175 0 0 2.69175 0 6C0 9.30825 2.69175 12 6 12C9.30825 12 12 9.30825 12 6C12 2.69175 9.30825 0 6 0ZM6.75 9C6.75 9.414 6.41475 9.75 6 9.75C5.58525 9.75 5.25 9.414 5.25 9V5.625C5.25 5.211 5.58525 4.875 6 4.875C6.41475 4.875 6.75 5.211 6.75 5.625V9ZM6 4.125C5.48325 4.125 5.0625 3.70425 5.0625 3.1875C5.0625 2.67075 5.48325 2.25 6 2.25C6.51675 2.25 6.9375 2.67075 6.9375 3.1875C6.9375 3.70425 6.51675 4.125 6 4.125Z"
        fill={color || '#888888'}
      />
    </svg>
  );
};

export default InfoIcon;
