import { type FC, useEffect, useState } from 'react';
import './Finances.scss';
import { apiBalance } from '../../../../services/api/legacy/balance';
import { LoadingScreen } from '../../../../components/LoadingScreen/LoadingScreen';
import { PageInfoHeader } from '../../../../components/PageInfoHeader/PageInfoHeader';
import { FinancesAccounts } from './FinancesAccounts/FinancesAccounts';
import { FinancesDepositModal } from './FinancesDepositModal/FinancesDepositModal';
import { FinancesWithdrawModal } from './FinancesWithdrawModal/FinancesWithdrawModal';

export const Finances: FC = () => {
  // states
  const [userMoney, setUserMoney] = useState(null);
  const [allowRender, setAllowRender] = useState(false);
  const [depositModalIsOpen, setDepositModalIsOpen] = useState(false);
  const [withdrawModalIsOpen, setWithdrawModalIsOpen] = useState(false);

  // action from the params
  // const { action } = useParams();

  // Mock wallet addresses
  const walletAddresses = {
    trc20: 'TCzRoAn8RKld5v44vNSYy7kXKp1zdiXpuS',
  };

  // Fetch user balance
  useEffect(() => {
    apiBalance.getBalance().then((r) => {
      if (r) {
        setUserMoney({
          total: r.amount / 100,
          available: r.amount / 100 - r.locked / 100,
          locked: r.locked / 100,
        });
        console.log(userMoney);
      }
      setAllowRender(true);
    });
  }, []);

  if (!allowRender) {
    return (
      <>
        <LoadingScreen />
      </>
    );
  }

  return (
    <>
      <PageInfoHeader page={'Finances'} additionalText={null} />

      <div className="finances">
        <FinancesDepositModal
          isOpen={depositModalIsOpen}
          setIsOpen={(value) => {
            setDepositModalIsOpen(value);
          }}
          walletAddresses={walletAddresses}
        />

        <FinancesWithdrawModal
          isOpen={withdrawModalIsOpen}
          setIsOpen={(value) => {
            setWithdrawModalIsOpen(value);
          }}
          availableAmount={userMoney.available}
        />

        <div className="finances__content">
          <FinancesAccounts
            openDepositModal={() => {
              setDepositModalIsOpen(true);
            }}
            openWithdrawModal={() => {
              setWithdrawModalIsOpen(true);
            }}
            availableAmount={userMoney.available}
            lockedAmount={userMoney.locked}
          />
        </div>
      </div>
    </>
  );
};
