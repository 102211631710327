import React, { type FC } from 'react';
import type { TypesSvgIcon } from './TypesSvgIcon';

const ArrowIcon: FC<TypesSvgIcon> = ({ color, className }) => {
  return (
    <svg width="6" height="8" viewBox="0 0 6 8" fill="none" className={className || ''}>
      <path
        d="M1.37475 8.00077C1.18275 8.00077 0.99075 7.92777 0.84475 7.78077C0.55175 7.48777 0.55175 7.01277 0.84475 6.71977L3.56475 3.99977L0.84475 1.27977C0.55175 0.986774 0.55175 0.511774 0.84475 0.218773C1.13775 -0.0742266 1.61275 -0.0742266 1.90575 0.218773L5.15575 3.46877C5.44875 3.76177 5.44875 4.23677 5.15575 4.52977L1.90575 7.77977C1.75975 7.92577 1.56775 7.99977 1.37575 7.99977L1.37475 8.00077Z"
        fill={color || '#222222'}
      />
    </svg>
  );
};

export default ArrowIcon;
