import { type FC } from 'react';
import { Select } from 'antd';

interface IProps {
  location: string;
  setLocation: (tags: string) => void;
}

export const ModalFilterLocation: FC<IProps> = ({ setLocation }) => {
  const { Option, OptGroup } = Select;

  const locations = [
    {
      country: 'Poland',
      cities: [
        'Warsaw',
        'Krakow',
        'Gdansk',
        'Poznan',
        'Wroclaw',
        'Lodz',
        'Szczecin',
        'Bydgoszcz',
        'Lublin',
        'Katowice',
      ],
    },
    {
      country: 'Russia',
      cities: [
        'Moscow',
        'Saint Petersburg',
        'Novosibirsk',
        'Yekaterinburg',
        'Nizhny Novgorod',
        'Kazan',
        'Chelyabinsk',
        'Omsk',
        'Samara',
        'Rostov-on-Don',
      ],
    },
    {
      country: 'France',
      cities: [
        'Paris',
        'Lyon',
        'Marseille',
        'Toulouse',
        'Nice',
        'Nantes',
        'Strasbourg',
        'Montpellier',
        'Bordeaux',
        'Lille',
      ],
    },
    {
      country: 'Germany',
      cities: [
        'Berlin',
        'Hamburg',
        'Munich',
        'Cologne',
        'Frankfurt',
        'Stuttgart',
        'Düsseldorf',
        'Dortmund',
        'Essen',
        'Leipzig',
      ],
    },
    {
      country: 'United States',
      cities: [
        'New York',
        'Los Angeles',
        'Chicago',
        'Houston',
        'Phoenix',
        'Philadelphia',
        'San Antonio',
        'San Diego',
        'Dallas',
        'San Jose',
      ],
    },
    {
      country: 'Canada',
      cities: [
        'Toronto',
        'Vancouver',
        'Montreal',
        'Calgary',
        'Ottawa',
        'Edmonton',
        'Winnipeg',
        'Quebec City',
        'Hamilton',
        'Kitchener',
      ],
    },
    {
      country: 'United Kingdom',
      cities: [
        'London',
        'Birmingham',
        'Manchester',
        'Glasgow',
        'Liverpool',
        'Bristol',
        'Leeds',
        'Sheffield',
        'Edinburgh',
        'Leicester',
      ],
    },
    {
      country: 'Italy',
      cities: [
        'Rome',
        'Milan',
        'Naples',
        'Turin',
        'Palermo',
        'Genoa',
        'Bologna',
        'Florence',
        'Catania',
        'Venice',
      ],
    },
    {
      country: 'Australia',
      cities: [
        'Sydney',
        'Melbourne',
        'Brisbane',
        'Perth',
        'Adelaide',
        'Gold Coast',
        'Canberra',
        'Newcastle',
        'Wollongong',
        'Hobart',
      ],
    },
    {
      country: 'Japan',
      cities: [
        'Tokyo',
        'Yokohama',
        'Osaka',
        'Nagoya',
        'Sapporo',
        'Kobe',
        'Kyoto',
        'Fukuoka',
        'Kawasaki',
        'Hiroshima',
      ],
    },
  ];

  return (
    <div className="modal-filter__content__options__location">
      <h4>Location</h4>
      <Select
        style={{ width: '100%', height: 36 }}
        placeholder="Select a location"
        allowClear
        onChange={(value) => {
          setLocation(value);
        }}
        variant="filled"
        showSearch
      >
        {locations.map((location) => (
          <OptGroup key={location.country} label={location.country}>
            {location.cities.map((city) => (
              <Option key={`${location.country}, ${city}`} value={`${location.country}, ${city}`}>
                {`${location.country}, ${city}`}
              </Option>
            ))}
          </OptGroup>
        ))}
      </Select>
    </div>
  );
};
