import React, { type FC } from 'react';

interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const AuthInput: FC<InputFieldProps> = ({ label, className, ...props }) => {
  return (
    <>
      {label && (
        <label htmlFor={props.id} className="auth-login__label auth-login__label--visually-hidden">
          {label}
        </label>
      )}
      <input required className={`auth-login__input ${className || ''}`} {...props} />
    </>
  );
};
