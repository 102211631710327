export const enum HistoryTypes {
  Completed,
  Cancelled,
}

export const financesHistoryStatusConfig: Record<
  HistoryTypes,
  { label: string; color: string; bgColor: string }
> = {
  [HistoryTypes.Completed]: {
    label: 'Completed',
    color: 'rgba(52, 199, 89, 1)',
    bgColor: 'rgba(52, 199, 89, 0.12)',
  },
  [HistoryTypes.Cancelled]: {
    label: 'Cancelled',
    color: 'rgba(255, 59, 48, 1)',
    bgColor: 'rgba(255, 59, 48, 0.12)',
  },
};
