import { ApiServiceBase } from '../apiServiceBase';
import {
  type IAuthResponse,
  type ILoginRequest,
  type IRegisterRequest,
} from '../../interfaces/api/auth';

class ApiAuth extends ApiServiceBase {
  protected baseUrl = 'v2/auth/';

  public login = (data: ILoginRequest): Promise<IAuthResponse | null> => {
    return this.post<ILoginRequest, IAuthResponse>('login', data);
  };

  public register = (data: IRegisterRequest): Promise<IAuthResponse> => {
    return this.post<IRegisterRequest, IAuthResponse>('register', data);
  };

  public getUser = (): Promise<Omit<IAuthResponse, 'token'>> => {
    return this.get<Omit<IAuthResponse, 'token'>>('user');
  };

  public getUserLogin = (id: string): Promise<string> => {
    return this.get<string>(`get_login_by_id/${id}`);
  };

  public getUserId = (login: string): Promise<[user_id: string, created_at: Date]> => {
    return this.get(`get_id_by_login/${login}`);
  };
}

export const apiAuth = new ApiAuth();
