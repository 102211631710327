import { Drawer, Modal } from 'antd';
import React, { type FC, useState } from 'react';
import './FinancesWithdrawModal.scss';
import { FinancesDepositPageNetworkSelector } from '../FinancesDepositPage/FinancesDepositPageNetworkSelector/FinancesDepositPageNetworkSelector';
import InfoIcon from '../../../../../assets/icons/financesInfoIcon.svg';
import PasteIcon from '../../../../../assets/icons/paste-icon.png';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';
import { useMediaSize } from '../../../../../hooks/useMediaSize';

interface AmountButtonProps {
  value: number;
  onClick: () => void;
}

const amountRegexp = /^[0-9]+(\.([0-9]+)?)?$/;

// Amount option button
const AmountButton: FC<AmountButtonProps> = ({ value, onClick }) => {
  const [isMobile] = useMediaSize();
  return (
    <button
      className={
        isMobile
          ? 'mobile-modal-withdraw__content__amount__button'
          : 'modal-withdraw__content__amount__button'
      }
      onClick={onClick}
    >
      ${value}
    </button>
  );
};

interface IProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  availableAmount: number;
}

export const FinancesWithdrawModal: FC<IProps> = ({ isOpen, setIsOpen, availableAmount }) => {
  // states
  const [chosenNetwork, setChosenNetwork] = useState('TRC20');
  const [withdrawAddress, setWithdrawAddress] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState(availableAmount);
  const [isMobile] = useMediaSize();

  // hooks
  const { t } = useLanguageContext();

  // Available withdraw amounts
  const withdrawAmounts = [availableAmount, 100, 500, 1000];

  // check if screen width is bigger than 641px
  const checkIfMobile = (): boolean => {
    return window.innerWidth <= 641;
  };

  const checkIfTabletMobile = (): boolean => {
    return window.innerWidth <= 768;
  };

  const fallbackPaste = (): void => {
    const textArea = document.createElement('textarea');
    document.body.appendChild(textArea);
    textArea.focus();
    document.execCommand('paste');
    const pastedText = textArea.value;
    document.body.removeChild(textArea);
    setWithdrawAddress(pastedText);
  };

  const setAmount = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = parseFloat(e.target.value);

    if (!amountRegexp.test(e.target.value) || isNaN(value)) {
      e.preventDefault();
      return;
    }

    setWithdrawAmount(value);
  };

  // Footer confirm button
  const confirmButton = (
    <button
      className={isMobile ? 'mobile-modal-withdraw__footer-btn' : 'modal-withdraw__footer-btn'}
      disabled={
        !chosenNetwork || !withdrawAddress || !withdrawAmount || withdrawAmount > availableAmount
      }
      key={'footer'}
    >
      {t('Confirm')}
    </button>
  );

  return isMobile ? (
    <>
      <Drawer
        open={isOpen}
        placement={'bottom'}
        onClose={() => {
          setIsOpen(false);
        }}
        closable={true}
        rootClassName="mobile-drawer-withdraw"
        // footer={[confirmButton]}
        height={checkIfTabletMobile() ? 500 : 390}
      >
        <div className="mobile-modal-withdraw__content">
          <div className="mobile-modal-withdraw__content__header">
            <span>{t('Withdraw')}</span>
            <div>
              <img src={InfoIcon} alt="" />
              <p>
                {t('Available for withdraw')}: ${availableAmount}
              </p>
            </div>
          </div>
          <div className="mobile-modal-withdraw__content__wrapper">
            <div className="mobile-modal-withdraw__content__info">
              <FinancesDepositPageNetworkSelector
                networkName={chosenNetwork}
                changeNetwork={(value) => {
                  setChosenNetwork(value);
                }}
              />

              <div className="mobile-modal-withdraw__content__info__address-input">
                <h4>{t('Wallet address')}</h4>
                <div>
                  <input
                    type="text"
                    value={withdrawAddress}
                    onChange={(e) => {
                      setWithdrawAddress(e.target.value);
                    }}
                  />
                  <button
                    onClick={() => {
                      if (navigator.clipboard) {
                        navigator.clipboard
                          .readText()
                          .then((data) => {
                            setWithdrawAddress(data);
                          })
                          .catch((err) => {
                            console.error('Не удалось прочитать содержимое буфера обмена: ', err);
                            fallbackPaste();
                          });
                      } else {
                        fallbackPaste();
                      }
                    }}
                  >
                    <img src={PasteIcon} alt="" />
                    <span>{t('Paste')}</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="mobile-modal-withdraw__content__amount">
              <div className="mobile-modal-withdraw__content__amount__input-wrapper">
                $
                <input
                  type="number"
                  className="mobile-modal-withdraw__content__amount__input"
                  placeholder={t('Amount')}
                  value={withdrawAmount}
                  onChange={setAmount}
                />
              </div>
              <div className="mobile-modal-withdraw__content__amount__button-container">
                {withdrawAmounts.map((amount, index) => (
                  <AmountButton
                    value={amount}
                    key={index}
                    onClick={() => {
                      setWithdrawAmount(amount);
                    }}
                  />
                ))}
              </div>
            </div>
            <button
              className={
                isMobile ? 'mobile-modal-withdraw__footer-btn' : 'modal-withdraw__footer-btn'
              }
              disabled={
                !chosenNetwork ||
                !withdrawAddress ||
                !withdrawAmount ||
                withdrawAmount > availableAmount
              }
              key={'footer'}
            >
              {t('Confirm')}
            </button>
          </div>
        </div>
      </Drawer>
    </>
  ) : (
    <>
      <Modal
        open={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
        footer={[confirmButton]}
        centered={!checkIfMobile()}
        style={{
          top: 0.05 * window.innerHeight,
        }}
        rootClassName={'mobile-modal-withdraw'}
        width={checkIfMobile() ? '100%' : '380px'}
      >
        <div className="modal-withdraw__content">
          <div className="modal-withdraw__content__header">
            <span>{t('Withdraw')}</span>
            <div>
              <div>
                <img src={InfoIcon} alt="" />
              </div>
              <p>
                {t('Available for withdraw')}: ${availableAmount}
              </p>
            </div>
          </div>
          <div className="modal-withdraw__content__wrapper">
            <div className="modal-withdraw__content__info">
              <FinancesDepositPageNetworkSelector
                networkName={chosenNetwork}
                changeNetwork={(value) => {
                  setChosenNetwork(value);
                }}
              />

              <div className="modal-withdraw__content__info__address-input">
                <h4>{t('Wallet address')}</h4>
                <div>
                  <input
                    type="text"
                    value={withdrawAddress}
                    onChange={(e) => {
                      setWithdrawAddress(e.target.value);
                    }}
                  />
                  <button
                    onClick={() => {
                      window.navigator.clipboard.readText().then((data) => {
                        setWithdrawAddress(data);
                      });
                    }}
                  >
                    <img src={PasteIcon} alt="" />
                    <span>{t('Paste')}</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-withdraw__content__amount">
              <div className="modal-withdraw__content__amount__input-wrapper">
                $
                <input
                  type="number"
                  className="modal-withdraw__content__amount__input"
                  placeholder={t('Amount')}
                  value={withdrawAmount}
                  onChange={setAmount}
                />
              </div>
              <div className="modal-withdraw__content__amount__button-container">
                {withdrawAmounts.map((amount, index) => (
                  <AmountButton
                    value={amount}
                    key={index}
                    onClick={() => {
                      setWithdrawAmount(amount);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
