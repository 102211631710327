import React, { type FC } from 'react';
import InfoIcon from '../../../../../../assets/icons/finances-info-icon.png';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';

export const FinancesDepositPageWarningSection: FC = () => {
  // hooks
  const { t } = useLanguageContext();

  return (
    <section className="finances__deposit-warning">
      <img
        loading="lazy"
        src={InfoIcon as string}
        alt=""
        className="finances__deposit-warning-icon"
      />
      <p className="finances__deposit-warning-text">
        {t(
          'Please make sure that only USDT deposit is made via this address. Otherwise, your deposited funds will not be added to your available balance — nor will it be refunded',
        )}
      </p>
    </section>
  );
};
