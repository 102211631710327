import { type FC } from 'react';
import './Loader.scss';

interface ILoader {
  size?: number;
  color?: string;
  width?: number;
}

export const Loader: FC<ILoader> = ({ size = 48, color = 'rgb(0, 0, 0, 0.7)', width = 5 }) => {
  return (
    <div className="loading">
      <span
        className="loader"
        style={{
          width: size,
          height: size,
          borderColor: color,
          borderBottomColor: 'transparent',
          borderWidth: width,
        }}
      ></span>
    </div>
  );
};
