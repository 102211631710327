import React, { type FC } from 'react';
import MessengerOrderInfoField from '../MessengerOrderInfoField/MessengerOrderInfoField';
import { useSelector } from '../../../../../../redux/rootReducer';
import ArrowIcon from '../../../../../../components/SvgIcons/ArrowIcon';
import { useNavigate } from 'react-router-dom';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';
import { parseDate } from '../../../../../../services/dateServices/parseDate';

const MessengerOrderInfoDescription: FC = () => {
  const orderInfo = useSelector((state) => state.messenger.orderInfo);

  // hooks
  const navigate = useNavigate();
  const { t } = useLanguageContext();

  const goToOrderPage = (): void => {
    navigate('#');
  };

  return (
    <div className="messenger__order__info__wrap__description">
      <MessengerOrderInfoField
        title={'Timeline'}
        value={
          parseDate.toDesiredDate(orderInfo.time_line.from, 'MMShort DD') +
          ' - ' +
          parseDate.toDesiredDate(orderInfo.time_line.to, 'MMShort DD')
        }
      />
      <MessengerOrderInfoField
        title={'Project fee'}
        value={String(orderInfo.price) + String(orderInfo.currency)}
      />
      {/* <MessengerOrderInfoField */}
      {/*  title={'Unfront payment'} */}
      {/*  value={orderInfo.currency + orderInfo.unFront_payment.toString()} */}
      {/* /> */}
      <div className="messenger__order__info__wrap__description__btn" onClick={goToOrderPage}>
        {t('Go to order')}
        <ArrowIcon />
      </div>
    </div>
  );
};

export default MessengerOrderInfoDescription;
