import React, { type FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentOpenedChatPopup } from '../../../../../../../redux/messenger/reducer';
import Popup from '../../../../../../../components/Popup/Popup';
import { Rate } from 'antd';
import { useSelector } from '../../../../../../../redux/rootReducer';
import { useLanguageContext } from '../../../../../../../components/LanguageContext/LanguageContext';

const MessengerOrderInfoRatePopup: FC = () => {
  // hooks
  const dispatch = useDispatch();
  const { t } = useLanguageContext();

  // redux
  const currentOpenedChatPopup = useSelector((state) => state.messenger.currentOpenedChatPopup);

  // state
  const [rate, setRate] = useState(1);
  const [description, setDescription] = useState('');

  const onClose = (): void => {
    dispatch(setCurrentOpenedChatPopup(null));
  };

  const onSubmit = (): void => {
    onClose();
    // TODO: прописать логику onSubmit
  };

  return (
    <Popup
      isOpen={currentOpenedChatPopup === 'Selects stars to rate'}
      onClose={onClose}
      btns={{
        right: { onClick: onSubmit, label: 'Submit' },
      }}
      centered
      title={{
        label: 'Selects stars to rate',
        style: { fontFamily: 'Inter-Bold, sans-serif', fontSize: 27 },
      }}
    >
      <div className="modal-messenger">
        <div className="modal-messenger__rate">
          <div className="modal-messenger__rate__select-stars">
            <Rate
              value={rate}
              onChange={setRate}
              rootClassName="modal-messenger__rate__select-stars__root-stars"
            />
          </div>
          <div className="modal-messenger__rate__textArea">
            <textarea
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              placeholder={t('Describe your experience and work quality')}
            />
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default MessengerOrderInfoRatePopup;
