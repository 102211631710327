import { Modal } from 'antd';
import { type FC } from 'react';
import './ModalFilter.scss';
import { ModalFilterRating } from './ModalFilterRating/ModalFilterRating';
import { type IFilterOptions } from '../../../pages/Home/components/Browse/Browse';
import { ModalFilterTags } from './ModalFilterTags/ModalFilterTags';
import { ModalFilterLocation } from './ModalFilterLocation/ModalFilterLocation';
import { ModalFilterLanguages } from './ModalFilterLanguages/ModalFilterLanguages';

interface IProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  filterBy: IFilterOptions;
  setFilterBy: (filter: IFilterOptions) => void;
  onConfirm: () => void;
}

export const ModalFilter: FC<IProps> = ({
  isOpen,
  setIsOpen,
  filterBy,
  setFilterBy,
  onConfirm,
}) => {
  // footer button
  const footerButton = (
    <button
      className="modal-filter__footer-btn"
      onClick={() => {
        onConfirm();
      }}
      key={'filter'}
    >
      Show ? results
    </button>
  );

  // check if screen width is bigger than 641px
  const checkIfMobile = (): boolean => {
    return window.innerWidth <= 641;
  };

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
        footer={[footerButton]}
        centered={!checkIfMobile()}
        style={{
          top: 0.05 * window.innerHeight,
        }}
        width={checkIfMobile() ? '100%' : '25vw'}
      >
        <div className="modal-filter__content">
          <div className="modal-filter__content__header">
            <span>Filters</span>
          </div>
          <div className="modal-filter__content__options">
            <ModalFilterRating
              chosenRating={filterBy.rating}
              setChosenRating={(value) => {
                setFilterBy({
                  ...filterBy,
                  rating: value,
                });
              }}
            />

            <ModalFilterTags
              tags={filterBy.tags}
              setTags={(value) => {
                setFilterBy({
                  ...filterBy,
                  tags: value,
                });
              }}
            />

            <ModalFilterLocation
              location={filterBy.location}
              setLocation={(value) => {
                setFilterBy({
                  ...filterBy,
                  location: value,
                });
              }}
            />

            <ModalFilterLanguages
              languages={filterBy.languages}
              setLanguages={(value) => {
                setFilterBy({
                  ...filterBy,
                  languages: value,
                });
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
