import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { State } from './types';

const initialState: State = {
  language: null,
};

const settingsReducer = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
  },
});

export const { setLanguage } = settingsReducer.actions;

export default settingsReducer.reducer;
