import React, { type FC } from 'react';
import { inputPriceAmounts } from '../../../configs/inputPriceAmounts/inputPriceAmounts';
import PriceInputAmountBtn from './PriceInputAmountBtn';
import './PriceInput.scss';

interface IPriceInput {
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
}

const amountRegexp = /^([0-9]+(\.([0-9]+)?)?)?$/;

const PriceInput: FC<IPriceInput> = ({ value, setValue, placeholder }) => {
  const setAmount = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = e.target.value;

    if (inputValue === '' || (amountRegexp.test(inputValue) && !isNaN(parseFloat(inputValue)))) {
      setValue(inputValue);
    }
  };

  return (
    <div className="input__price__amount">
      <div className="input__price__amount__input-wrapper">
        $
        <input
          type="text"
          className="input__price__amount__input"
          placeholder={placeholder}
          value={value}
          onChange={setAmount}
        />
      </div>
      <div className="input__price__amount__button-container">
        {inputPriceAmounts.map((amount, index) => (
          <PriceInputAmountBtn value={amount} key={index} setValue={setValue} />
        ))}
      </div>
    </div>
  );
};

export default PriceInput;
