import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const LanguageContext = createContext(undefined);

export const LanguageContextProvider = ({ children }): any => {
  const languages = {
    en: { nativeName: 'English' },
    ru: { nativeName: 'Russian' },
  };
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang: string): void => {
    i18n.changeLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ t, i18n, changeLanguage, languages }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = (): any => useContext(LanguageContext);
