export const enum EnumOrderStatus {
  WAITING_FOR_CONFIRM,
  PROPOSAL_SENT,
  IN_PROGRESS,
  APPEAL,
  COMPLETED,
}

export const orderStatusConfig: Record<
  EnumOrderStatus,
  { label: string; color: string; bgColor: string }
> = {
  [EnumOrderStatus.WAITING_FOR_CONFIRM]: {
    label: 'Waiting for confirm',
    color: 'rgba(255, 149, 0, 1)',
    bgColor: 'rgba(255, 149, 0, 0.12)',
  },
  [EnumOrderStatus.PROPOSAL_SENT]: {
    label: 'Proposal sent',
    color: 'rgba(175, 82, 222, 1)',
    bgColor: 'rgba(175, 82, 222, 0.12)',
  },
  [EnumOrderStatus.IN_PROGRESS]: {
    label: 'In progress',
    color: 'rgba(0, 122, 255, 1)',
    bgColor: 'rgba(0, 122, 255, 0.12)',
  },
  [EnumOrderStatus.APPEAL]: {
    label: 'Appeal',
    color: 'rgba(255, 59, 48, 1)',
    bgColor: 'rgba(255, 59, 48, 0.12)',
  },
  [EnumOrderStatus.COMPLETED]: {
    label: 'Completed',
    color: 'rgba(136, 136, 136, 1)',
    bgColor: 'rgba(241, 241, 241, 1)',
  },
};
