import { useEffect, useState } from 'react';

export const useWindowWidth = (): { width: number; isMobile: boolean } => {
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(0);

  const handleResize = (): void => {
    setWidth(window.innerWidth);
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { width, isMobile };
};
