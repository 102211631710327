import { ApiServiceBase } from '../../apiServiceBase';
import { type IGetRatingResponse } from '../../../interfaces/api/reviews';

export interface IReview {
  login?: string;
  user_id?: string;
  target_id: string;
  package_name: string;
  description?: string;
  rank: number;
}

class ApiReviews extends ApiServiceBase {
  protected baseUrl = 'review/';

  public getRating = async (id: string): Promise<IGetRatingResponse> => {
    try {
      const response = await this.get<IGetRatingResponse>('get_review', {
        query: {
          user_id: id,
        },
      });

      return response ?? null;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  public addReview = async (review: IReview): Promise<boolean> => {
    try {
      const response = await this.post('add_review', review);
      return response.status === 200;
    } catch (e) {
      e && console.log(e);
      return false;
    }
  };

  public getReviews = async (id: string): Promise<IReview[]> => {
    try {
      const response = await this.get<{ reviews: IReview[] }>('get_reviews', {
        query: {
          user_id: id,
        },
      });

      return response?.reviews ?? [];
    } catch (e) {
      e && console.log(e);
      return [];
    }
  };
}

export const apiReviews = new ApiReviews();
