import React, { type FC } from 'react';
import { ProfileMainInfoHeader } from './ProfileMainInfoHeader/ProfileMainInfoHeader';
import { ProfileMainInfoBio } from './ProfileMainInfoBio/ProfileMainInfoBio';
import { ProfileMainInfoDetails } from './ProfileMainInfoDetails/ProfileMainInfoDetails';
import { ProfileMainInfoSkills } from './ProfileMainInfoSkills/ProfileMainInfoSkills';
import { type IProfile } from '../../../../../interfaces/api/profile';

interface IProps {
  profile: IProfile;
  isMyProfile: boolean;
}

export const ProfileMainInfo: FC<IProps> = ({ profile, isMyProfile }) => {
  return (
    <main className="user-profile__main-info">
      <ProfileMainInfoHeader
        name={profile.name}
        username={profile.login}
        userId={profile.user_id}
        isMyProfile={isMyProfile}
      />
      <ProfileMainInfoBio description={profile.description} aboutMe={profile.about_me} />
      <ProfileMainInfoSkills skills={profile.hashtags} />
      <ProfileMainInfoDetails
        location={profile.country}
        memberSince="ABC 0000"
        languages={profile.language !== '' && [profile.language]}
      />
    </main>
  );
};
