import React, { type FC } from 'react';
import ArrowRightIcon from '../../../../../assets/icons/arrow-right-icon.png';
import { ProfilePortfolioInfoCard } from './ProfilePortfolioInfoCard/ProfilePortfolioInfoCard';
import MockImage from '../../../../../assets/icons/default-service.jpeg';
import { useLanguageContext } from '../../../../../components/LanguageContext/LanguageContext';

const portfolioExampleData = [
  {
    id: '0',
    description: 'Frontend developer focused on work with saas, crypto, nft projects',
  },
  {
    id: '1',
    description: 'Frontend developer focused on work with saas, crypto, nft projects',
  },
  {
    id: '2',
    description: 'Frontend developer focused on work with saas, crypto, nft projects',
  },
  {
    id: '3',
    description: 'Frontend developer focused on work with saas, crypto, nft projects',
  },
];

export const ProfilePortfolioInfo: FC = () => {
  // hooks
  const { t } = useLanguageContext();

  return (
    <section className="user-profile__content-section__services-n-portfolio">
      <div className="user-profile__content-section__services-n-portfolio__heading">
        <div className="user-profile__content-section__services-n-portfolio__heading__title-wrapper">
          <span>{t('Portfolio')}</span>
          <div></div>
          <h3>{portfolioExampleData.length}</h3>
        </div>
        <button>
          {t('See all')}
          <img src={ArrowRightIcon as string} alt="" />
        </button>
      </div>
      <div className="user-profile__content-section__services-n-portfolio__card-container">
        {portfolioExampleData.slice(0, 3).map((project) => (
          <ProfilePortfolioInfoCard
            key={project.id}
            imageSrc={MockImage as string}
            title={project.description}
          />
        ))}
      </div>
    </section>
  );
};
