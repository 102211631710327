import { type FC } from 'react';
import WhiteStarIcon from '../../../../../assets/icons/white-star-icon.png';
import BlackStarIcon from '../../../../../assets/icons/black-star-icon.png';

interface IProps {
  rating: number;
  isSelected?: boolean;
  onClick: () => void;
}

export const ModalFilterRatingButton: FC<IProps> = ({ rating, isSelected, onClick }) => {
  return (
    <button
      className="modal-filter__content__options__rating__button"
      style={{
        backgroundColor: isSelected && 'rgba(34, 34, 34, 1)',
        color: isSelected && '#fff',
      }}
      onClick={onClick}
    >
      <span>from</span>
      <img src={isSelected ? WhiteStarIcon : BlackStarIcon} alt="" />
      <span>{rating}</span>
    </button>
  );
};
