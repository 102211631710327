export const enum HistoryTypes {
  Withdraw,
  Deposit,
  Transfer,
  Payment,
}

export const financesHistoryTypeConfig: Record<HistoryTypes, { label: string }> = {
  [HistoryTypes.Withdraw]: {
    label: 'Withdraw',
  },
  [HistoryTypes.Deposit]: {
    label: 'Deposit',
  },
  [HistoryTypes.Transfer]: {
    label: 'Transfer',
  },
  [HistoryTypes.Payment]: {
    label: 'Payment',
  },
};
