import React, { type FC, useRef } from 'react';

interface IProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  maxCharacters: number;
}

export const ProfileEditPageFormTextarea: FC<IProps> = ({
  label,
  value,
  onChange,
  maxCharacters,
}) => {
  // ref for textarea
  const text = useRef(null);

  return (
    <div className="user-profile__edit-page__form__textarea">
      <div className="user-profile__edit-page__form__textarea__label">
        <span>{label}</span>
        <span>
          {text.current?.value.length ?? 0}/{maxCharacters}
        </span>
      </div>
      <textarea
        ref={text}
        className="user-profile__edit-page__form__textarea__field"
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        maxLength={maxCharacters}
      />
    </div>
  );
};
