import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '../../redux/rootReducer';
import './auth.scss';
import { AuthLogin } from './components/AuthLogin/AuthLogin';
import { AuthSignup } from './components/AuthSignup/AuthSignup';
import { AuthResetPassword } from './components/AuthResetPassword/AuthResetPassword';

export enum Components {
  LOGIN = 'login',
  REGISTRATION = 'registration',
  RESET = 'reset',
}

export interface IAuthFormProps {
  setComponent: (component: any) => void;

  goNext: () => void;

  goBack: () => void;
}

// interface IComponentData {
//   type: string;
//   image?: string;
//   form: FC<IAuthFormProps>;
// }

const authForms: {
  [Components.REGISTRATION]: { form: React.FunctionComponent<any>; type: string }[];
  [Components.LOGIN]: { form: React.FunctionComponent<IAuthFormProps>; type: string }[];
  [Components.RESET]: { form: React.FunctionComponent<IAuthFormProps>; type: string }[];
} = {
  [Components.LOGIN]: [{ type: 'blue', form: AuthLogin }],
  [Components.REGISTRATION]: [{ type: 'purple', form: AuthSignup }],
  [Components.RESET]: [{ type: 'purple', form: AuthResetPassword }],
};

export const Auth: FC = () => {
  const [component, setComponent] = useState<Components>(Components.LOGIN);
  const [formKey, setFormKey] = useState<number>(0);

  const navigate = useNavigate();

  const authStore = useSelector((state) => state.auth);

  useEffect(() => {
    if (authStore.user) {
      navigate('/browse/people');
    }
  }, []);

  useEffect(() => {
    setFormKey(0);
  }, [component]);

  const goNext = (): void => {
    setFormKey((prev) => {
      if (authForms[component][prev + 1]) {
        return prev + 1;
      }

      return prev;
    });
  };

  const goBack = (): void => {
    setFormKey((prev) => {
      if (authForms[component][prev - 1]) {
        return prev - 1;
      }

      return prev;
    });
  };

  const componentData = authForms[component][formKey];

  const AuthForm = componentData.form;

  return (
    <section className="auth">
      <div className="auth-main">
        <AuthForm setComponent={setComponent} goNext={goNext} goBack={goBack} />
      </div>
    </section>
  );
};
