import { ApiServiceBase } from '../apiServiceBase';

class ApiWebsocket extends ApiServiceBase {
  protected baseUrl = 'v2/websocket/';

  public getToken = (): Promise<string | null> => {
    return this.get('access');
  };
}

export const apiWebsocket = new ApiWebsocket();
