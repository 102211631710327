import React, { type FC } from 'react';
import { useLanguageContext } from '../../../../../../components/LanguageContext/LanguageContext';

interface IMessengerOrderInfoField {
  title: string;
  value: string | number;
}

const MessengerOrderInfoField: FC<IMessengerOrderInfoField> = ({ title, value }) => {
  // hooks
  const { t } = useLanguageContext();

  return (
    <div className="messenger__order__info__wrap__field">
      <div className="messenger__order__info__wrap__field__title">{t(title)}</div>
      <div className="messenger__order__info__wrap__field__value">{t(value)}</div>
    </div>
  );
};

export default MessengerOrderInfoField;
