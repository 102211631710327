import React, { type FC } from 'react';
import { avatarUrl } from '../../components/Chats/Chats';
import DefaultAvatar from '../../../../assets/icons/user-default-avatar.png';
import { useSelector } from '../../../../redux/rootReducer';
import { useLanguageContext } from '../../../../components/LanguageContext/LanguageContext';

export interface NavItem {
  icon: string;
  label: string;
  count?: string | number;
  isSelected?: boolean;
  element?: any;
  onClick?: () => void;
}

export const HomeNavBarItem: FC<NavItem> = ({
  icon,
  label,
  count,
  isSelected,
  onClick,
}: NavItem) => {
  // hooks
  const { t } = useLanguageContext();

  // redux
  const myId = useSelector((state) => state.auth.user.user_id);

  return (
    <div className={!isSelected ? 'home__nav__item' : 'home__nav__item selected'} onClick={onClick}>
      <object
        className="home__nav__item__icon"
        data={label !== 'Profile' ? icon : avatarUrl + myId}
        type="image/png"
      >
        <img className="home__nav__item__icon" src={DefaultAvatar} alt="" />
      </object>
      <span className="home__nav__item__label">{t(label)}</span>
      {count !== undefined && <span className="home__nav__item__count">{count}</span>}
    </div>
  );
};
