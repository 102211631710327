import React, { type FC } from 'react';
import { MessengerHeaderActionButton } from './MessengerHeaderActionButton/MessengerHeaderActionButton';
import { MessengerHeaderMessageButton } from './MessengerHeaderMessageButton/MessengerHeaderMessageButton';
import DefaultAvatar from '../../../../../assets/icons/user-default-avatar.png';
import { avatarUrl } from '../../Chats/Chats';
import { type IJob } from '../../../../../interfaces/api/packageProcess';
import { useWindowWidth } from '../../../../../hooks/useWindowWidth';
import BackIcon from '../../../../../components/SvgIcons/BackIcon';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setOpenedChat } from '../../../../../redux/chats/reducer';
import MessengerHeaderMobileActivity from './MessengerHeaderMobileActivity/MessengerHeaderMobileActivity';

interface MessengerHeaderProps {
  userName: string;
  userOnline: boolean;
  userId: string;
  showChangeStatusButton?: boolean;
  buttonText: string;
  buttonAllow: boolean;
  order: IJob;
  setOrder: (order: any) => void;
}

export const MessengerHeader: FC<MessengerHeaderProps> = ({
  userName,
  userOnline,
  userId,
  showChangeStatusButton,
  buttonText,
  buttonAllow,
  order,
  setOrder,
}) => {
  const { isMobile } = useWindowWidth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goBack = (): void => {
    navigate(-1);
    dispatch(setOpenedChat(null));
  };

  return (
    <section className="messenger__header">
      <div className="messenger__header__user-info">
        {isMobile && (
          <span
            onClick={goBack}
            style={{
              width: 20,
              height: 20,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <BackIcon />
          </span>
        )}
        <div className="messenger__header__avatar__wrap">
          <object className="messenger__header__avatar" data={avatarUrl + userId} type="image/png">
            <img className="messenger__header__avatar" src={DefaultAvatar as string} alt="" />
          </object>
          <div
            className="messenger__header__status-indicator"
            aria-hidden="true"
            style={{
              backgroundColor: userOnline ? '#34c759' : 'gray',
              outline: '2px solid #fff',
            }}
          />
        </div>
        <div className="messenger__header__details">
          <h2 className="messenger__header__name">{userName}</h2>
        </div>
        {showChangeStatusButton && buttonAllow && (
          <MessengerHeaderActionButton
            buttonText={buttonText}
            allowChangeStatus={buttonAllow}
            order={order}
            setOrder={setOrder}
          />
        )}
        <MessengerHeaderMessageButton />
      </div>
      {isMobile && order && <MessengerHeaderMobileActivity />}
    </section>
  );
};
