import React, { type FC } from 'react';

interface IPriceInputAmountBtn {
  value: string;
  setValue: (value: string) => void;
}

const PriceInputAmountBtn: FC<IPriceInputAmountBtn> = ({ value, setValue }) => {
  return (
    <button
      className="input__price__amount__button-container__amount"
      onClick={() => {
        setValue(value);
      }}
    >
      ${value}
    </button>
  );
};

export default PriceInputAmountBtn;
