import type { FC } from 'react';
import type { TypesSvgIcon } from './TypesSvgIcon';

const ServicesIcon: FC<TypesSvgIcon> = ({ color }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.88306 0.420032L11.5801 2.1171C11.9961 2.5331 11.9961 3.20937 11.5801 3.62537L9.88306 5.32243C9.67506 5.53043 9.40199 5.63443 9.12892 5.63443C8.85586 5.63443 8.58279 5.53043 8.37479 5.32243L6.67772 3.62537C6.26172 3.20937 6.26172 2.5331 6.67772 2.1171L8.37479 0.420032C8.79026 0.00403222 9.46759 0.00403222 9.88306 0.420032Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M1.79567 0.604565H4.46234C5.05144 0.604565 5.529 1.08213 5.529 1.67123V4.3379C5.529 4.927 5.05144 5.40457 4.46234 5.40457H1.79567C1.20657 5.40457 0.729004 4.927 0.729004 4.3379V1.67123C0.729004 1.08213 1.20657 0.604565 1.79567 0.604565Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M10.3291 6.47124H7.66247C7.07336 6.47124 6.5958 6.9488 6.5958 7.53791V10.2046C6.5958 10.7937 7.07336 11.2712 7.66247 11.2712H10.3291C10.9182 11.2712 11.3958 10.7937 11.3958 10.2046V7.53791C11.3958 6.9488 10.9182 6.47124 10.3291 6.47124Z"
        fill={color || '#A0A0A0'}
      />
      <path
        d="M1.79567 6.47124H4.46234C5.05144 6.47124 5.529 6.9488 5.529 7.53791V10.2046C5.529 10.7937 5.05144 11.2712 4.46234 11.2712H1.79567C1.20657 11.2712 0.729004 10.7937 0.729004 10.2046V7.53791C0.729004 6.9488 1.20657 6.47124 1.79567 6.47124Z"
        fill={color || '#A0A0A0'}
      />
    </svg>
  );
};

export default ServicesIcon;
