import React, { type FC, useCallback, useEffect, useState } from 'react';
import './Messenger.scss';
import { type IOpenedChat } from '../../../../interfaces/api/chats';
import { MessengerFooter } from './MessengerFooter/MessengerFooter';
import { useDispatch } from 'react-redux';
import { type IMessage } from '../../../../interfaces/api/messenger';
import { apiMessenger } from '../../../../services/api/messenger';
import { useSelector } from '../../../../redux/rootReducer';
import { setMessagePopupPosition } from '../../../../redux/messenger/reducer';
import { useSearchParams } from 'react-router-dom';
import { useLanguageContext } from '../../../../components/LanguageContext/LanguageContext';
import { MessengerReviewModal } from './MessengerReviewModal/MessengerReviewModal';
import { message } from 'antd';
import { apiReviews } from '../../../../services/api/legacy/reviews';
import { LoadingScreen } from '../../../../components/LoadingScreen/LoadingScreen';
import { MessengerList } from './MessengerList/MessengerList';
import { MessengerHeader } from './MessengerHeader/MessengerHeader';
import { type IJob } from '../../../../interfaces/api/packageProcess';
import { setMessagesRead } from '../../../../redux/chats/reducer';
import MessengerOrderInfo from './MessengerOrderInfo/MessengerOrderInfo';
import MessengerOrderInfoPopups from './MessengerOrderInfo/MessengerOrderInfoPopups/MessengerOrderInfoPopups';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';

export const Messenger: FC = () => {
  // hooks
  const dispatch = useDispatch();
  const { t } = useLanguageContext();
  const [searchParams] = useSearchParams();
  const { isMobile } = useWindowWidth();

  // redux
  const usersId = useSelector((state) => state.auth.user.user_id);
  const chatsState = useSelector((state) => state.chats);
  const lastMessage = useSelector((state) => state.chats.lastMessage);

  // states
  const [chat, setChat] = useState<IOpenedChat | null>(null);
  const [order, setOrder] = useState<IJob>(null);
  const [messagesList, setMessagesList] = useState<IMessage[]>([]);
  const [orderButtonText, setOrderButtonText] = useState('');
  const [orderButtonAllow, setOrderButtonAllow] = useState(false);
  const [modalAddReviewIsOpen, setModalAddReviewIsOpen] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  // const [chosenAction, setChosenAction] = useState('');

  // chat id
  let id = searchParams.get('id');

  // get username from params
  const userName = searchParams.get('name');

  // Get order for chat
  useEffect(() => {
    if (chatsState.openedChat) {
      if (!id && userName) id = chatsState.openedChat.id;
      setChat(chatsState.openedChat);
      // const getUserOrder = (): void => {
      //   apiPackageProcess.getOrder(id).then((order) => {
      //     order && setOrder(order);
      //     !order && setTimeout(getUserOrder, 1000);
      //   });
      // };
      // getUserOrder()
      // dispatch(
      //   setOrderInfo({
      //     name: 'Order name',
      //     price: 56,
      //     price_for: 'hour',
      //     currency: '$',
      //     unFront_payment: 10,
      //     is_executor: false,
      //     time_line: { from: new Date(), to: new Date() },
      //   }),
      // );
    }
  }, [chatsState.openedChat]);

  // 'Change Status' button
  useEffect(() => {
    if (chat) {
      switch (order?.status) {
        case 'DiscussionTP':
          setOrderButtonText('End discussion');
          order.executor_id !== chat.user_id
            ? setOrderButtonAllow(true)
            : setOrderButtonAllow(false);
          break;
        case 'WaitingClientTPConfirm':
          setOrderButtonText('Confirm');
          order.executor_id === chat.user_id
            ? setOrderButtonAllow(true)
            : setOrderButtonAllow(false);
          break;
        case 'WaitingExecutorTPConfirm':
          setOrderButtonText('Confirm');
          order.executor_id !== chat.user_id
            ? setOrderButtonAllow(true)
            : setOrderButtonAllow(false);
          break;
        case 'WaitingExecutorTPComplet':
          setOrderButtonText('Complete order');
          order.executor_id !== chat.user_id
            ? setOrderButtonAllow(true)
            : setOrderButtonAllow(false);
          break;
        case 'WaitingClientOrderConfirm':
          setOrderButtonText('Confirm order');
          order.executor_id === chat.user_id
            ? setOrderButtonAllow(true)
            : setOrderButtonAllow(false);
          break;
        case 'ClientAppeal':
          setOrderButtonText('');
          setOrderButtonAllow(false);
          break;
        case 'Completed':
          apiReviews.getReviews(chat.user_id).then((reviews) => {
            const myReview = reviews?.filter(
              (review) => review.user_id === usersId && review.package_name === order.package_name,
            );
            if (myReview.length > 0) {
              setOrderButtonAllow(false);
            } else {
              setOrderButtonText('Leave a review');
              order.executor_id === chat.user_id
                ? setOrderButtonAllow(true)
                : setOrderButtonAllow(false);
            }
          });
          break;

        default:
          setOrderButtonText('');
          setOrderButtonAllow(false);
          break;
      }
    }
  }, [order]);
  let isEndMessages = false;
  // Fetch messages
  const getUserMessages = useCallback(
    async (offset?: number): Promise<void> => {
      const chatId = chatsState.openedChat?.id || id;

      if (isEndMessages) return null;

      if (offset) {
        setIsLoadingMore(true);
      } else {
        setIsDataLoading(true);
      }
      await apiMessenger
        .getMessages(chatId, offset)
        .then((r: IMessage[] | null) => {
          if (r) {
            if (r.length === 0) {
              isEndMessages = true;
            }
            if (offset) {
              setMessagesList((prev) => [...r, ...prev]);
            } else {
              setMessagesList(r);
            }
          }
        })
        .finally(() => {
          setIsDataLoading(false);
          setIsLoadingMore(false);
        });
    },
    [chatsState.openedChat?.id, id],
  );

  useEffect(() => {
    getUserMessages(null);
  }, [chatsState.openedChat]);

  useEffect(() => {
    if (lastMessage) {
      setMessagesList((prev) => [...prev, lastMessage]);
    }
  }, [lastMessage]);

  // Read messages
  useEffect(() => {
    const isNeedToRead = !!messagesList.find((m: IMessage) => {
      return !m.is_read && m.author_id !== usersId;
    });

    if (isNeedToRead) {
      apiMessenger.readMessages(id).then((r) => {
        r &&
          dispatch(
            setMessagesRead({
              chat_type: chatsState.openedChat.chat_type,
              index: chatsState.chats[chatsState.openedChat.chat_type].findIndex(
                (c) => c.id === id,
              ),
            }),
          );
      });
    }
  }, [messagesList]);

  // if (!chatsState.openedChat || !chat || (!id && !userName) || !order) {
  if (!chatsState.openedChat || !chat || isDataLoading) {
    return (
      <div className="messenger">
        <LoadingScreen />
      </div>
    );
  }

  return (
    <div
      className="messenger"
      style={!isMobile ? { flex: 1 } : {}}
      onClick={() => {
        dispatch(setMessagePopupPosition(null));
      }}
    >
      {/* Modal for leaving a review */}
      {order && (
        <MessengerReviewModal
          onSuccess={() => {
            message.success(t('Success'));
            setModalAddReviewIsOpen(false);
            setOrderButtonAllow(false);
          }}
          onFailure={() => {
            message.error(t('Something went wrong. Please try again'));
            setModalAddReviewIsOpen(false);
          }}
          onClose={() => {
            setModalAddReviewIsOpen(false);
          }}
          isOpened={modalAddReviewIsOpen}
          package_name={order.package_name}
          executor={order.executor_id}
          executor_id={order.executor_id}
        />
      )}

      {/* All popups */}
      {order && <MessengerOrderInfoPopups />}

      <div className="messenger-wrap">
        {/* Header */}
        <MessengerHeader
          buttonText={orderButtonText}
          buttonAllow={orderButtonAllow}
          userName={chat.name}
          userOnline={chat.is_online}
          userId={chat.id}
          showChangeStatusButton={order?.status && true}
          order={order}
          setOrder={(job) => {
            setOrder(job);
          }}
        />

        {/* Messages list */}
        <MessengerList
          messages={messagesList}
          getUserMessages={getUserMessages}
          isLoadingMore={isLoadingMore}
        />

        {/* Messenger footer */}
        <MessengerFooter chatId={!id ? chatsState.openedChat.id : id} />
      </div>
      {!isMobile && chat.order_id && <MessengerOrderInfo />}
    </div>
  );
};
