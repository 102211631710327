import React, { type FC, memo } from 'react';
import DefaultAvatar from '../../../../../../assets/icons/user-default-avatar.png';
import { setOpenedChat } from '../../../../../../redux/chats/reducer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { parseDate } from '../../../../../../services/dateServices/parseDate';
import { type IChat } from '../../../../../../interfaces/api/chats';
import { avatarUrl } from '../../Chats';

interface ChatItemProps {
  resetSearchValue: () => void;
  chat: IChat;
  chatType: string;
  isActive: boolean;
}

const ChatsListItem: FC<ChatItemProps> = ({ resetSearchValue, chat, chatType, isActive }) => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const avatarSrc = avatarUrl + chat.user_id;
  const unreadCount = chat.unread_count > 0 && chat.unread_count;

  const clickHandler = (): void => {
    resetSearchValue();
    dispatch(setOpenedChat({ ...chat, chat_type: chatType }));
    document.documentElement.style.setProperty('--dialog-opened', 'none');
    navigate(`/messages?id=${chat.id}`);
  };

  return (
    <div className={`chats__item ${isActive && 'chats__item-active'}`} onClick={clickHandler}>
      <div className="chats__item__avatar-wrap">
        {chat.is_online && <div className="chats__item__online" />}
        <object className="chats__avatar" data={avatarSrc} type="image/png">
          <img className="chats__avatar" src={DefaultAvatar as string} alt="" />
        </object>
      </div>
      <div className="chats__info">
        <div className="chats__header">
          <h3 className="chats__name">{chat.name}</h3>
          {unreadCount && (
            <div className="chats__unread-badge">
              <span>{unreadCount}</span>
            </div>
          )}
        </div>
        <div className="chats__content">
          <p className="chats__description">{chat.last_message}</p>
          <span className="chats__time">{parseDate.timeForChat(new Date(chat.updated_at))}</span>
        </div>
      </div>
    </div>
  );
};

export default memo(ChatsListItem);
